import * as React from 'react';
import { X } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import {
	Command,
	CommandGroup,
	CommandItem,
	CommandList,
} from '@/components/ui/command';
import { Command as CommandPrimitive } from 'cmdk';
import { cn } from '@/lib/utils';

type Option = Record<'value' | 'label', string>;

interface PortableMultiSelectProps {
	options: Option[];
	value: Option[];
	onSelectedValuesChange: (selectedValues: Option[]) => void;
	placeholder?: string;
}

export const PortableMultiSelect: React.FC<PortableMultiSelectProps> = ({
	options,
	value,
	onSelectedValuesChange,
	placeholder,
}) => {
	const inputRef = React.useRef<HTMLInputElement>(null);
	const [open, setOpen] = React.useState(false);
	const [selected, setSelected] = React.useState<Option[]>(value);
	const [inputValue, setInputValue] = React.useState('');

	React.useEffect(() => {
		// Ensure selected options are unique and prevent re-adding default values
		setSelected((prevSelected) => {
			const uniqueSelected = value.filter(
				(option) => !prevSelected.some((s) => s.value === option.value)
			);
			return [...prevSelected, ...uniqueSelected];
		});
	}, [value]);

	const handleUnselect = React.useCallback(
		(option: Option) => {
			const newSelected = selected.filter((s) => s.value !== option.value);
			setSelected(newSelected);
			onSelectedValuesChange(newSelected);
		},
		[selected, onSelectedValuesChange]
	);

	const handleKeyDown = React.useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>) => {
			const input = inputRef.current;
			if (input) {
				if (e.key === 'Enter') {
					e.preventDefault();
					input.blur();
				}
				if (e.key === 'Delete' || e.key === 'Backspace') {
					if (input.value === '') {
						const newSelected = [...selected];
						newSelected.pop();
						setSelected(newSelected);
						onSelectedValuesChange(newSelected);
					}
				}
				if (e.key === 'Escape') {
					input.blur();
				}
			}
		},
		[selected, onSelectedValuesChange]
	);

	const selectables = options.filter(
		(option) =>
			!selected?.some((s) => s.value === option.value) &&
			option.label.toLowerCase().includes(inputValue.toLowerCase())
	);

	return (
		<Command
			onKeyDown={handleKeyDown}
			className="h-auto overflow-visible bg-transparent "
		>
			<div className="p-4 text-sm border rounded-xl group">
				<div className="flex flex-wrap gap-2">
					{selected?.map((option) => {
						return (
							<Badge
								key={option.value}
								className="px-3 py-1 font-medium uppercase bg-white border-primary text-primary dynamic-badge"
							>
								{option.label}
								<button
									className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											handleUnselect(option);
										}
									}}
									onMouseDown={(e) => {
										e.preventDefault();
										e.stopPropagation();
									}}
									onClick={() => handleUnselect(option)}
								>
									<X className="w-3 h-3 font-bold text-primary" />
								</button>
							</Badge>
						);
					})}
					<CommandPrimitive.Input
						ref={inputRef}
						value={inputValue}
						onValueChange={setInputValue}
						onBlur={() => setOpen(false)}
						onFocus={() => setOpen(true)}
						placeholder={placeholder || 'Type to search...'}
						className={cn(
							'flex-1 bg-transparent outline-none dynamic-small focus-visible:outline-none',
							selected?.length > 0 ? 'ml-2' : ''
						)}
					/>
				</div>
			</div>
			<div className="relative">
				<CommandList>
					{open ? (
						<div className="absolute bg-white h-[200px] overflow-y-scroll top-0 z-10 w-full border rounded-md shadow-md outline-none bg-popover text-black animate-in mt-2">
							<CommandGroup>
								{selectables.map((option) => {
									return (
										<CommandItem
											key={option.value}
											onMouseDown={(e) => {
												e.preventDefault();
												e.stopPropagation();
											}}
											onSelect={() => {
												setInputValue('');
												const newSelected = [...selected, option];
												setSelected(newSelected);
												onSelectedValuesChange(newSelected);
											}}
											className={'cursor-pointer py-2 hover:bg-[#EAF1FC]'}
										>
											{option.label}
										</CommandItem>
									);
								})}
							</CommandGroup>
							{selectables.length === 0 && (
								<p className="p-3 text-center text-black">No results found</p>
							)}
						</div>
					) : null}
				</CommandList>
			</div>
		</Command>
	);
};
