import { FC, useState } from 'react';
import styles from './EssentialDocumentsCard.module.css';
import { Separator } from '../ui/separator';
import EssentialDocumentListitem from '../EssentialDocumentListItem/EssentialDocumentListitem';
import { cn } from '@/lib/utils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog } from '@/redux/slices/pageSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import EmptyEssential from '../ui/icons/new/EmptyEssential';
import { FileTextIcon, FileUp, Search, X } from 'lucide-react';
import PlanCardHeader from '../PlanCardHeader/PlanCardHeader';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import Ai from '../ui/icons/Ai';
import { Input } from '../ui/input';

interface EssentialDocumentsCardProps {}

const EssentialDocumentsCard: FC<EssentialDocumentsCardProps> = () => {
	const dispatch = useAppDispatch();
	const proposal = useSelector((root: RootState) => root.proposal.proposal);

	const [searchValue, setSearchValue] = useState<string>('');

	return (
		<div className={styles.card}>
			<div className="flex flex-col justify-between gap-3 xl:gap-0 xl:items-center xl:flex-row proposal-documents">
				<PlanCardHeader
					title={'Proposal documents'}
					shortDescription={
						'This is a proposal documents list related to this solicitation.'
					}
					info={'Info'}
					icon={
						<FileTextIcon
							strokeWidth={1}
							size={32}
							className="min-h-8 min-w-8 text-secondary"
						/>
					}
				/>
				<div className="flex flex-col justify-between gap-3 xl:items-center xl:flex-row">
					<div className="relative flex-1 w-full xl:w-[350px]">
						<Input
							value={searchValue}
							onChange={(event) => {
								setSearchValue(event.target.value);
							}}
							placeholder="Search"
							className="h-12 p-2 pr-8 bg-white rounded-lg border-blackSecondary hover:bg-lightBackground disabled:bg-background"
						/>
						<div className="absolute right-4 top-4">
							{searchValue !== '' ? (
								<X
									onClick={() => {
										setSearchValue('');
									}}
									className="cursor-pointer"
									color="#5D6F79"
									size={16}
								/>
							) : (
								<Search color="#5D9BFD" size={16} />
							)}
						</div>
					</div>
					<div className="flex flex-wrap items-center gap-3">
						<TextButton
							onClick={() =>
								dispatch(
									openDialog({
										type: 'addDocuments',
										addDocumentType: 'attachFormativeDocument',
									})
								)
							}
						>
							<Ai className="w-5" />
							Search in your library
						</TextButton>
						<div className="border-r border-[#D3DDE2] rounded-lg w-1 h-7"></div>
						<TextButton
							onClick={() =>
								dispatch(
									openDialog({
										type: 'uploadDocument',
										uploadDocumentType: 'attachFormativeDocument',
									})
								)
							}
						>
							<FileUp size={20} />
							Upload a new document
						</TextButton>
					</div>
				</div>
			</div>
			<Separator
				className={cn(
					'bg-[#C6DBFB]',
					proposal?.formativeDocuments?.length === 0 ? 'mt-6' : 'my-6'
				)}
			/>
			{proposal?.formativeDocuments?.length === 0 ? (
				<div className="flex flex-col items-center gap-4 justify-center min-h-[300px]">
					<EmptyEssential />
					<p className="flex items-center justify-center font-bold text-black">
						You have not added any essential documents yet.
						<a
							onClick={() =>
								dispatch(
									openDialog({
										type: 'addDocuments',
										addDocumentType: 'attachFormativeDocument',
									})
								)
							}
							className="cursor-pointer text-primary hover:text-secondary normal-case pl-0.5"
						>
							Click to add documents
						</a>
					</p>
				</div>
			) : (
				<div className="h-[300px] overflow-y-scroll pr-3">
					<ul className="group/list">
						{proposal?.formativeDocuments
							?.filter(
								(document) =>
									document.name
										?.toLowerCase()
										?.includes(searchValue.toLowerCase()) ||
									document.alias
										?.toLowerCase()
										?.includes(searchValue.toLowerCase()) ||
									document.summary
										?.toLowerCase()
										?.includes(searchValue.toLowerCase()) ||
									document.category
										?.toLowerCase()
										?.includes(searchValue.toLowerCase())
							)
							?.map((document) => (
								<EssentialDocumentListitem
									key={document.id}
									document={document}
								/>
							))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default EssentialDocumentsCard;
