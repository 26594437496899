import { SVGProps } from 'react';

const ComplianceNotFound = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="350"
			height="188"
			viewBox="0 0 350 188"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clip-path="url(#clip0_18145_17405)">
				<path
					d="M18.0019 184.029H283.412C293.032 184.029 301.882 176.249 303.182 166.659L322.862 20.4091C324.152 10.8091 317.402 3.03906 307.772 3.03906H42.3519C32.7319 3.03906 23.8819 10.8191 22.5819 20.4091L2.91191 166.649C1.62191 176.249 8.37191 184.019 18.0019 184.019V184.029Z"
					fill="#E3EEFF"
					stroke="#AFC1DB"
					strokeWidth="5.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M332 184.3H66.6002C56.9802 184.3 48.1302 176.5 46.8302 166.87L27.1502 20.18C25.8602 10.56 32.6102 2.75 42.2402 2.75H307.65C317.27 2.75 326.12 10.55 327.42 20.18L347.1 166.88C348.39 176.5 341.64 184.31 332.01 184.31L332 184.3Z"
					fill="#E3EEFF"
					stroke="#AFC1DB"
					strokeWidth="5.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M296.496 143H109.611C107.379 143 105.484 141.213 105.156 138.788L93.0491 49.6691C92.6483 46.6848 94.7526 44 97.5037 44H284.389C286.621 44 288.516 45.7866 288.844 48.2119L300.951 137.331C301.352 140.315 299.247 143 296.496 143Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="6.52"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M125 64L289.5 63.5801"
					stroke="#AFC1DB"
					strokeWidth="8"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M120 83.9766H134.487"
					stroke="#AFC1DB"
					strokeWidth="6.52"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M163.453 83.0215H217.378"
					stroke="#AFC1DB"
					strokeWidth="6.52"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M244.336 83.9766H293.158"
					stroke="#AFC1DB"
					strokeWidth="6.52"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M125 103L146 103"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M161.652 102.859L279.373 102.859"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M287.223 102.859L296.09 102.859"
					stroke="#AFC1DB"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M130.008 122.51L134.721 122.51"
					stroke="#AFC1DB"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M144.109 122.51L289.608 122.51"
					stroke="#AFC1DB"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M294.293 122.51L299.006 122.51"
					stroke="#AFC1DB"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M309.028 163.251H85.9584C78.9584 163.251 72.5584 158.371 71.6584 152.361L54.1484 34.6908C53.2484 28.6808 58.2084 23.8008 65.2084 23.8008H288.278C295.278 23.8008 301.678 28.6708 302.578 34.6908L320.088 152.361C320.988 158.371 316.028 163.251 309.028 163.251Z"
					stroke="white"
					strokeWidth="6.52"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M90.6 76.2C97.5588 76.2 103.2 70.5588 103.2 63.6C103.2 56.6412 97.5588 51 90.6 51C83.6412 51 78 56.6412 78 63.6C78 70.5588 83.6412 76.2 90.6 76.2Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M86.8203 63.6001L89.3403 66.1201L94.3803 61.0801"
					fill="white"
				/>
				<path
					d="M86.8203 63.6001L89.3403 66.1201L94.3803 61.0801"
					stroke="#AFC1DB"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M103.6 136.2C110.559 136.2 116.2 130.559 116.2 123.6C116.2 116.641 110.559 111 103.6 111C96.6412 111 91 116.641 91 123.6C91 130.559 96.6412 136.2 103.6 136.2Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path d="M99.8203 123.6L102.34 126.12L107.38 121.08" fill="white" />
				<path
					d="M99.8203 123.6L102.34 126.12L107.38 121.08"
					stroke="#AFC1DB"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M98.6 106.2C105.559 106.2 111.2 100.559 111.2 93.6C111.2 86.6412 105.559 81 98.6 81C91.6412 81 86 86.6412 86 93.6C86 100.559 91.6412 106.2 98.6 106.2Z"
					fill="white"
					stroke="#AFC1DB"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M94.5898 97.6098L102.61 89.5898"
					stroke="#AFC1DB"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M94.5898 89.5898L102.61 97.6098"
					stroke="#AFC1DB"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_18145_17405">
					<rect width="350" height="187.05" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default ComplianceNotFound;
