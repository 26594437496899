import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import AppPane from './containers/AppPane/AppPane';
import AccountPane from './containers/AccountPane/AccountPane';
import ManagePane from './containers/ManagePane/ManagePane';
import DocumentsPane from './containers/DocumentsPane/DocumentsPane';
import Login from './pages/Login/Login';
import SignUp from './pages/Signup/Signup';
import LoginLayout from './layout/LoginLayout/LoginLayout';
import ProposalCreation from './pages/ProposalCreation/ProposalCreation';
import PrivateRoute from './lib/routes/PrivateRoute';
import AdminRoute from './lib/routes/AdminRoute';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from './redux/store/store';
import LoaderFill from './components/Loader/LoaderFill/LoaderFill';
import { MagicProvider } from './lib/context/MagicProvider';
import { injectStore } from './lib/api';
import AppWrapper from './containers/AppWrapper/AppWrapper';
import DashboardTabs from './containers/DashboardTabs/DashboardTabs';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import OrganizationSetup from './pages/OrganizationSetup/OrganizationSetup';
import Dashboard from './pages/Dashboard/Dashboard';
import TemplatePane from './containers/TemplateBuilderPane/TemplatePane';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { IntercomProvider } from 'react-use-intercom';
import { ActiveSectionProvider } from './lib/context/ActiveSectionContext';
import 'intro.js/introjs.css';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import ErrorBound from './lib/error/ErrorBound/ErrorBound';
import TemplateBuilder from './pages/TemplateBuilder/TemplateBuilder';
import TemplateAssembler from './components/TemplateBuilder/TemplateAssembler/TemplateAssembler';
import LogicalFramePane from './containers/LogicalFramePane/LogicalFramePane';

const persistor = persistStore(store);
injectStore(store);

const queryClient = new QueryClient();
const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;
const showSSOlogin = import.meta.env.VITE_SSO_LOGIN;

function App() {
	return (
		<IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
			<DndProvider backend={HTML5Backend}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<ErrorBound>
							<PersistGate loading={<LoaderFill />} persistor={persistor}>
								<ActiveSectionProvider>
									<MagicProvider>
										<AppWrapper>
											<Routes>
												<Route element={<LoginLayout />}>
													<Route path="login" index element={<Login />} />
													<Route path="signup" element={<SignUp />} />
													{showSSOlogin !== 'true' && (
														<Route
															path="reset-password"
															element={<ResetPassword />}
														/>
													)}
													<Route
														path="setup-organization"
														element={<OrganizationSetup />}
													/>
												</Route>
												<Route path="/" element={<PrivateRoute />}>
													<Route path="/" element={<Layout />}>
														<Route index path="/" element={<Dashboard />} />

														<Route
															path="/proposal/:id/logical-frame"
															element={
																<LogicalFramePane defaultTab="logicalFrame" />
															}
														/>
														<Route
															path="/solicitations"
															element={
																<DashboardTabs defaultTab={'dashboard'} />
															}
														/>
														<Route
															path="/proposal/:id"
															element={<AppPane defaultTab={'dashboard'} />}
														/>
														<Route
															path="proposal/:id/section/:nodeId/plan"
															element={<AppPane defaultTab={'plan'} />}
														/>
														<Route
															path="proposal/:id/section/:nodeId/write"
															element={<AppPane defaultTab={'write'} />}
														/>
														<Route
															path="/create"
															element={<ProposalCreation />}
														/>
														{/* <Route path="/discover" element={<DiscoverPane />} /> */}
														<Route
															path="document"
															element={
																<DocumentsPane defaultTab={'documents'} />
															}
														/>
														{/* template builder routes */}
														<Route
															path="/templates"
															element={<TemplateBuilder />}
														/>
														<Route
															path="/templates"
															element={
																<TemplatePane defaultTab={'templateLibrary'} />
															}
														/>

														<Route
															path="/template/new"
															element={
																<TemplatePane defaultTab={'newTemplate'} />
															}
														/>
														<Route
															path="/template/edit/:id"
															element={
																<TemplatePane defaultTab={'newTemplate'} />
															}
														/>
														<Route
															path="/template/design"
															element={
																<TemplateAssembler defaultTab="design" />
															}
														/>

														<Route
															path="account"
															element={<AccountPane defaultTab={'account'} />}
														/>
														<Route element={<AdminRoute />}>
															<Route
																path="manage/organization"
																element={
																	<ManagePane defaultTab={'organization'} />
																}
															/>
															<Route
																path="manage/users"
																element={<ManagePane defaultTab={'users'} />}
															/>
															<Route
																path="manage/toneofvoice"
																element={
																	<ManagePane defaultTab={'toneofvoice'} />
																}
															/>
															<Route
																path="manage/auditlogs"
																element={
																	<ManagePane defaultTab={'auditlogs'} />
																}
															/>
														</Route>
													</Route>
												</Route>
												<Route path="*" element={<NotFoundPage />} />
											</Routes>
										</AppWrapper>
									</MagicProvider>
								</ActiveSectionProvider>
							</PersistGate>
						</ErrorBound>
					</BrowserRouter>
				</QueryClientProvider>
			</DndProvider>
		</IntercomProvider>
	);
}
export default App;
