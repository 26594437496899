import {
	AlertCircleIcon,
	CheckCircle2,
	GripVertical,
	LucideLoader2,
	Pencil,
	Trash2,
} from 'lucide-react';
import { FC, useEffect } from 'react';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import QuestionBuilderInput from '../QuestionBuilderInput/QuestionBuilderInput';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord, Identifier } from 'dnd-core';
import { QuestionBuilderItem } from '@/lib/types/apiTypes';
import { openDialog } from '@/redux/slices/pageSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';

const ItemTypes = {
	CARD: 'card',
};

interface DragItem {
	index: number;
	id: string;
	type: string;
}

interface QuestionBuilderListItemProps {
	index: number;
	question: QuestionBuilderItem;
	isEditing?: boolean;
	editingQuestion: string | null;
	draggingItemId: string | null;
	setDraggingItemId: (id: string | null) => void;
	onEdit: (id: string) => void;
	onSave: (questionText: string, wordCount: number) => void;
	onRetry: (questionText: string, wordCount: number, id: string) => void;
	onDelete: (id: string) => void;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
}

const QuestionBuilderListItem: FC<QuestionBuilderListItemProps> = ({
	index,
	question,
	isEditing,
	editingQuestion,
	draggingItemId,
	setDraggingItemId,
	onEdit,
	onSave,
	onRetry,
	onDelete,
	moveCard,
}) => {
	const dispatch = useAppDispatch();
	const ref = useRef<HTMLDivElement>(null);

	const [{ handlerId }, drop] = useDrop<
		DragItem,
		void,
		{ handlerId: Identifier | null }
	>({
		accept: ItemTypes.CARD,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < 0) {
				return;
			}

			// Dragging upwards
			if (dragIndex >= hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
		drop() {
			setDraggingItemId(null);
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.CARD,
		item: () => {
			return { id: index, index: index };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	drag(drop(ref));

	useEffect(() => {
		if (!isDragging) {
			setDraggingItemId(null);
		} else if (!draggingItemId) {
			setDraggingItemId(question.id);
		}
	}, [isDragging]);

	return (
		<div
			ref={ref}
			data-handler-id={handlerId}
			className={draggingItemId === question.id ? 'opacity-50' : 'opacity-100'}
		>
			{isEditing ? (
				<QuestionBuilderInput
					editingQuestion={question}
					onSave={onSave}
					isEditing={isEditing}
				/>
			) : (
				<div className="flex items-center justify-between bg-white rounded-xl gap-2">
					<div className="flex flex-1 items-center justify-between px-4 py-2">
						<div className="flex items-center gap-2">
							<GripVertical className="text-primary cursor-move" />
							<h1 className="font-bold text-black pr-4">{question.text}</h1>
						</div>
						<div className="flex items-center gap-3">
							<div className="border border-[#D3DDE2] px-4 py-1 border-t-0 border-b-0">
								<p className="font-bold text-primary w-max">
									{question.wordCount} Words
								</p>
							</div>
							<div className="flex items-center gap-2">
								<TextButton
									disabled={question.status === 'processing'}
									onClick={() =>
										editingQuestion
											? dispatch(
													openDialog({
														type: 'notification',
														notificationObj: {
															title: 'Unsaved Changes',
															description:
																'You have unsaved changes to the previous question or section. If you start editing this question or section, your changes will be lost. Do you want to continue without saving?',
															onConfirm: () => {
																onEdit(question.id);
															},
														},
													})
												)
											: onEdit(question.id)
									}
								>
									<Pencil size={16} /> Edit
								</TextButton>
								<TextButton
									className="text-secondary"
									disabled={question.status === 'processing'}
									onClick={() =>
										dispatch(
											openDialog({
												type: 'notification',
												notificationObj: {
													title: 'Delete Question or Section',
													description:
														'Are you sure you want to delete this question or section?',
													onConfirm: () => {
														onDelete(question.id);
													},
												},
											})
										)
									}
								>
									<Trash2 size={16} /> Delete
								</TextButton>
							</div>
						</div>
					</div>
					{question.status === 'error' ? (
						<div
							onClick={() =>
								onRetry(question.text, question.wordCount, question.id)
							}
							className="flex items-center gap-2 text-lightRed w-28 2xl:w-32 animated-hover-effect cursor-pointer"
						>
							<AlertCircleIcon /> Try again
						</div>
					) : question.status === 'processing' ? (
						<div className="flex items-center gap-2 text-primary w-28 2xl:w-32">
							<LucideLoader2 className="processing-animation" /> Processing
						</div>
					) : (
						<div className="flex items-center gap-2 text-lightGreen w-28 2xl:w-32">
							<CheckCircle2 /> Complete
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default QuestionBuilderListItem;
