import QuestionEditor from '@/components/QuestionEditor/QuestionEditor';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { answerSpecificQuestion } from '@/lib/functions/apiCalls';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { useCallback, useEffect, useReducer, useState } from 'react';
import customToast from '@/components/CustomToast/CustomToast';
import { useSelector } from 'react-redux';
import { getToken, getWordCount } from '@/lib/functions/funcUtils';
import { Question } from '@/lib/types/apiTypes';
import { cn } from '@/lib/utils';
import { EditorAction, EditorState } from '@/lib/types/constants';
import useResponseHandler from '@/lib/hooks/useResponseHandler';
import DialogLoader from '../../Loader/DialogLoader/DailogLoader';

const initialState = (questions: Question[]): EditorState[] => {
	return questions.map((question) => ({
		originalQuestionText: question.answer || '',
		editedQuestionText: question.answer || '',
		isEditing: false,
		isWordCountValid: true,
		limitExceeded: false,
	}));
};

const checkWordCountValid = (text: string): boolean => {
	return getWordCount(text) <= 300;
};

const editorReducer = (
	state: EditorState[],
	action: EditorAction
): EditorState[] => {
	switch (action.type) {
		case 'INIT':
			return (
				action.questions?.map((question) => ({
					originalQuestionText: question.answer || '',
					editedQuestionText: question.answer || '',
					isEditing: false,
					isWordCountValid: true,
					limitExceeded: false,
				})) || []
			);
		case 'EDIT':
			return state.map((s, i) => ({
				...s,
				isEditing: i === action.index,
			}));
		case 'SAVE':
			return state.map((s, index) =>
				index === action.index
					? {
							...s,
							originalQuestionText: s.editedQuestionText,
							isEditing: false,
						}
					: s
			);
		case 'CANCEL':
			return state.map((s, index) =>
				index === action.index
					? {
							...s,
							editedQuestionText: s.originalQuestionText,
							isEditing: false,
						}
					: s
			);
		case 'UPDATE_TEXT':
			return state.map((s, index) =>
				index === action.index
					? {
							...s,
							editedQuestionText: action.newText || '',
							isWordCountValid: checkWordCountValid(action.newText || ''),
							limitExceeded: !checkWordCountValid(action.newText || ''),
						}
					: s
			);
		case 'APPENED_TEXT':
			return state.map((s, index) =>
				index === action.index
					? {
							...s,
							editedQuestionText: s.editedQuestionText + action.newText || '',
							isWordCountValid: checkWordCountValid(
								s.editedQuestionText + action.newText || ''
							),
							limitExceeded: !checkWordCountValid(
								s.editedQuestionText + action.newText || ''
							),
						}
					: s
			);
		default:
			return state;
	}
};

const BigPictureDialog = () => {
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector(
		(state: RootState) => state.page.dialog?.type === 'viewBigPicture'
	);

	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const [generatingResponse, setGeneratingResponse] = useState(false);
	const [editorStates, dispatchEditor] = useReducer(
		editorReducer,
		proposal?.questions ? initialState(proposal.questions) : []
	);
	const [streamingQuestionId, setStreamingQuestionId] = useState<number | null>(
		null
	);
	const [streamingIndex, setStreamingIndex] = useState<number | null>(null);
	const token = getToken();

	const [hasShownToast, setHasShownToast] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isSaveDisabled = useCallback(
		(index: number): boolean => {
			return (
				editorStates[index]?.originalQuestionText ===
					editorStates[index]?.editedQuestionText ||
				editorStates[index]?.isWordCountValid === false
			);
		},
		[editorStates]
	);

	useEffect(() => {
		if (proposal?.questions) {
			dispatchEditor({
				type: 'INIT',
				questions: proposal?.questions as Question[],
				index: 0,
			});
		}
	}, [proposal?.questions]);

	const handleInputChange = (index, e: React.FormEvent<HTMLDivElement>) => {
		const updatedText = e.currentTarget.innerText || '';
		const isWithinLimit = checkWordCountValid(updatedText);

		if (
			!isWithinLimit &&
			!editorStates[index]?.limitExceeded &&
			!hasShownToast
		) {
			customToast.error({
				title: 'Content exceeds the permitted word count',
			});
			setHasShownToast(true);
		} else {
			setHasShownToast(false);
		}

		dispatchEditor({ type: 'UPDATE_TEXT', index, newText: updatedText });
	};

	const handlePaste = (
		e: React.ClipboardEvent<HTMLDivElement>,
		index: number
	) => {
		e.preventDefault();
		const updatedText = e.currentTarget?.textContent || '';
		const isWithinLimit = checkWordCountValid(updatedText);

		if (!isWithinLimit && !hasShownToast) {
			customToast.error({
				title: 'Content exceeds the permitted word count',
			});
			setHasShownToast(true);
		}

		dispatchEditor({ type: 'UPDATE_TEXT', index, newText: updatedText });
	};

	const saveChange = async (index) => {
		try {
			setIsLoading(true);
			const response = await answerSpecificQuestion(
				proposal?.id as number,
				proposal?.questions[index].id as number,
				editorStates[index]?.editedQuestionText
			);
			if (response) {
				customToast.success({ title: 'Answer saved successfully' });

				await dispatch(fetchProposalById(proposal?.id as number));
				dispatchEditor({
					type: 'SAVE',
					questions: proposal?.questions as Question[],
					index: index,
				});
				setIsLoading(false);
			}
		} catch (error) {
			customToast.error({ title: 'Failed to save the answer' });
			setIsLoading(false);
		}
	};
	const cancelChange = () => {
		dispatchEditor({
			type: 'CANCEL',
			questions: proposal?.questions as Question[],
			index: 0,
		});
		dispatch(closeDialog());
		setIsLoading(false);
	};
	const onEditEditor = (index) => {
		dispatchEditor({
			type: 'EDIT',
			index: index,
		});
	};
	const onCancelEditor = (index) => {
		dispatchEditor({
			type: 'CANCEL',
			index: index,
		});
	};

	const [handleSuggestResponse] = useResponseHandler({
		token,
		proposalId: proposal?.id,
		questionId: streamingQuestionId,
		onInit: () => {
			setGeneratingResponse(true);
			streamingIndex !== null &&
				dispatchEditor({
					type: 'UPDATE_TEXT',
					index: streamingIndex,
					newText: 'Hold on tight while I write this response',
				});
		},
		onStart: () => {
			streamingIndex !== null &&
				dispatchEditor({
					type: 'UPDATE_TEXT',
					index: streamingIndex,
					newText: '',
				});
		},
		onMessage: (message: string) => {
			streamingIndex !== null &&
				dispatchEditor({
					type: 'APPENED_TEXT',
					index: streamingIndex,
					newText: message,
				});
		},
		onEnd: () => {
			setGeneratingResponse(false);
		},
		onError: () => {
			setGeneratingResponse(false);
		},
	});

	const onHandleSuggestResponse = (index: number, questionId: number) => {
		setStreamingIndex(index);
		setStreamingQuestionId(questionId);
	};

	useEffect(() => {
		if (streamingIndex !== null && streamingQuestionId !== null) {
			handleSuggestResponse();
		}
	}, [streamingIndex, streamingQuestionId]);

	useEffect(() => {
		if (!generatingResponse) {
			setStreamingIndex(null);
			setStreamingQuestionId(null);
		}
	}, [generatingResponse]);

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className="h-5/6 max-w-[1000px] bg-white gap-0"
				setModalOpen={() => cancelChange()}
			>
				<h3 className="text-secondary dynamic-medium font-bold pb-4  border-b border-[#D3DDE2]">
					Refine our approach
				</h3>
				<div className="relative gap-3 py-4 overflow-hidden">
					<div className="relative flex flex-col h-full gap-4 pt-4 pr-3 overflow-y-auto">
						{proposal?.questions.map((question, index) => {
							return (
								<div className="flex flex-col gap-4 xl:gap-6" key={index}>
									<div
										className={cn(
											'hover:bg-[#F6F6F6] rounded-xl grid items-center grid-cols-1 justify-left gap-4 p-4 xl:p-6',
											editorStates[index]?.isEditing
												? 'border border-[#E3EEFF]  bg-[#F6F6F6]'
												: 'bg-whie'
										)}
									>
										<h2
											className={cn(
												'font-bold flex items-center gap-3 flex-1 ml-2 animated-container-transition text-lg',
												editorStates[index]?.isEditing
													? 'text-primary'
													: 'text-black'
											)}
										>
											<span
												className={
													'absolute pr-0 border-r-4 border-[#0000] min-w-[40px] xl:min-w-[50px] ' +
													(editorStates[index]?.isEditing
														? 'border-separater-pink'
														: 'border-separater-grey')
												}
											>
												Q{index + 1}
											</span>{' '}
											{question.text}
										</h2>
										<QuestionEditor
											placeholder={
												generatingResponse ? '' : 'Type your response here...'
											}
											onChange={(e) => handleInputChange(index, e)}
											onSave={() => saveChange(index)}
											onCancel={() => onCancelEditor(index)}
											onEditEditor={() => onEditEditor(index)}
											handleSuggestResponse={() =>
												onHandleSuggestResponse(index, question.id)
											}
											generatingResponse={generatingResponse}
											isEditable={editorStates[index]?.isEditing}
											isStreaming={streamingIndex === index}
											onPaste={(e) => handlePaste(e, index)}
											canSuggest={true}
											isSaveDisabled={isSaveDisabled(index)}
											value={editorStates[index]?.editedQuestionText}
											className={
												'relative text-sm xl:text-base text-black rounded-[15px] font-normal min-h-[4rem] whitespace-pre-wrap'
											}
										/>
									</div>

									<div className="flex items-center text-[#b4b4b4] text-base">
										<div
											className={
												'flex-1 h-[1px] mr-8 bg-[#D3DDE2] animated-container-transition '
											}
										>
											<span
												className={
													'relative block rounded-full top-[-1.5px] w-1 h-1 bg-[#D3DDE2] animated-container-transition '
												}
											></span>
										</div>
										<div className={'flex items-center gap-2  shrink-0'}>
											<small
												className={cn(
													'block text-end text-xs xl:text-base',
													!editorStates[index]?.isWordCountValid
														? 'text-[#FF82A0]'
														: 'text-[rgba(135,133,133,0.45)]'
												)}
											>
												{getWordCount(editorStates[index]?.editedQuestionText)}{' '}
												words
											</small>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<DialogLoader isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

export default BigPictureDialog;
