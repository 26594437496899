import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import DeleteCross from '@/components/ui/icons/new/DeleteCross';
import Tick from '@/components/ui/icons/new/Tick';

// selected section pink
export const SelectedSectionNodeProps = ({ node }) => {
	const nodeId = node.systemName;
	const sectionName = node.alias || node.name;

	return (
		<TooltipProvider delayDuration={100}>
			<Tooltip>
				<TooltipTrigger
					type="button"
					className="w-full pr-10 overflow-hidden cursor-move"
				>
					<div
						id={`node-${nodeId}`}
						className="flex flex-row pr-8 generated-nodes text-primary dynamic-medium"
					>
						<p className="truncate">{sectionName}</p>
					</div>
				</TooltipTrigger>
				{sectionName.length > 40 && (
					<TooltipContent
						align="start"
						side="top"
						className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal max-w-[500px] 2xl:max-w-[600px] whitespace-normal"
					>
						{sectionName}
					</TooltipContent>
				)}
			</Tooltip>
		</TooltipProvider>
	);
};

// Second column node cross functionality
export const AssemblerSectionButton = ({
	node,
	path,
	selectedSection,
	removeNodeFromTree,
}) => {
	return (
		<Button
			type="button"
			className={`p-0 pb-1 ml-4 border-0 my-auto bg-transparent group-hover/item:visible cross-btn`}
		>
			<DeleteCross
				onClick={() => {
					removeNodeFromTree(path);
				}}
				color={
					selectedSection &&
					selectedSection.systemName.toLowerCase() ===
						node.systemName.toLowerCase()
						? '#fff'
						: '#5D9BFD'
				}
				className={`cursor-pointer`}
			/>
		</Button>
	);
};

// Need to verify if this is use or not
export const SelectedSectionButton = ({ rowInfo }) => {
	return (
		<div className="flex justify-between">
			<h2 className="font-bold text-primary dynamic-medium">
				{rowInfo.node.name}
			</h2>
		</div>
	);
};

// Proposal Creation Journey dont touch for refactorign
export const RecommendedTemplatesSectionTitle = ({ node }) => {
	return (
		<TooltipProvider delayDuration={100}>
			<Tooltip>
				<TooltipTrigger type="button" className="w-full pr-10 overflow-hidden">
					<div className="flex flex-row text-primary dynamic-medium">
						<p className="truncate">{node.alias || node.name}</p>
					</div>
				</TooltipTrigger>
				<TooltipContent
					align="start"
					side="top"
					className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal"
				>
					{node.alias || node.name}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};
export const RecommendedTemplatesMatchedSectionIcon = ({ sections }) => {
	return (
		<Button
			type="button"
			className={`p-0 pb-1 ml-4 border-0 my-auto bg-transparent`}
		>
			{sections?.isMatched && <Tick color={'#E861C9'} />}
		</Button>
	);
};
