import { Badge } from '@/components/ui/badge';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { ComplianceRule } from '@/lib/types/apiTypes';
import { ColumnDef } from '@tanstack/react-table';
import { Pencil, Trash2, UserCircle } from 'lucide-react';

export function getColumns(
	handleEditMatrix: (id: number) => void,
	handleDeleteMatrix: (id: number) => void
): ColumnDef<ComplianceRule>[] {
	const columns: ColumnDef<ComplianceRule>[] = [
		{
			accessorKey: 'user',
			header: () => '',
			cell: ({ row }) => {
				if (row.original.user) {
					return (
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<UserCircle size={14} color="#E861C9" />
								</TooltipTrigger>
								<TooltipContent
									side="right"
									className="bg-white dynamic-small "
								>
									<p>
										Created By{' '}
										<span className="text-secondary">
											{row.original.user.name}
										</span>
									</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					);
				} else {
					return (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="13"
							height="12"
							viewBox="0 0 13 12"
							fill="none"
						>
							<path
								d="M8.5856 11.9717H7.91261C7.81227 11.9717 7.71605 11.9319 7.6451 11.8609C7.57416 11.79 7.5343 11.6937 7.5343 11.5934C7.5343 11.4931 7.57416 11.3969 7.6451 11.3259C7.71605 11.255 7.81227 11.2151 7.91261 11.2151H8.58618C8.93185 11.2146 9.26323 11.0771 9.50766 10.8327C9.75208 10.5883 9.8896 10.2569 9.89006 9.91122V2.06079C9.88976 1.71502 9.7523 1.38349 9.50786 1.13893C9.26342 0.894382 8.93195 0.756782 8.58618 0.756324H7.91261C7.86172 0.758312 7.81096 0.750006 7.76336 0.731904C7.71576 0.713803 7.67231 0.686278 7.63561 0.65098C7.5989 0.615682 7.5697 0.573337 7.54976 0.526482C7.52981 0.479628 7.51953 0.429229 7.51953 0.378306C7.51953 0.327383 7.52981 0.276984 7.54976 0.23013C7.5697 0.183276 7.5989 0.14093 7.63561 0.105632C7.67231 0.0703339 7.71576 0.0428093 7.76336 0.0247078C7.81096 0.00660625 7.86172 -0.00169947 7.91261 0.000288359H8.58618C9.13247 0.000898911 9.65621 0.218183 10.0425 0.604469C10.4288 0.990755 10.6461 1.5145 10.6467 2.06079V9.91122C10.6461 10.4575 10.4288 10.9812 10.0425 11.3675C9.65621 11.7538 9.13189 11.9711 8.5856 11.9717Z"
								fill="#5D9BFD"
							/>
							<path
								d="M12.6202 11.9988H11.9472C11.4009 11.9982 10.8772 11.7809 10.4909 11.3946C10.1046 11.0083 9.88733 10.4846 9.88672 9.93827V2.08784C9.88733 1.54155 10.1046 1.01781 10.4909 0.631524C10.8772 0.245238 11.4009 0.0279543 11.9472 0.0273438H12.6208C12.7211 0.0273438 12.8173 0.0672009 12.8883 0.138147C12.9592 0.209093 12.9991 0.305317 12.9991 0.40565C12.9991 0.505983 12.9592 0.602207 12.8883 0.673153C12.8173 0.744099 12.7211 0.783956 12.6208 0.783956H11.9472C11.6015 0.784414 11.2702 0.921934 11.0257 1.16636C10.7813 1.41079 10.6438 1.74217 10.6433 2.08784V9.93827C10.6436 10.284 10.7811 10.6156 11.0255 10.8601C11.27 11.1047 11.6014 11.2423 11.9472 11.2427H12.6208C12.7211 11.2427 12.8173 11.2826 12.8883 11.3535C12.9592 11.4245 12.9991 11.5207 12.9991 11.621C12.9991 11.7214 12.9592 11.8176 12.8883 11.8885C12.8173 11.9595 12.7211 11.9993 12.6208 11.9993"
								fill="#5D9BFD"
							/>
							<path
								d="M3.77479 9.80477C3.233 9.80477 2.74744 9.70255 2.3181 9.4981C1.89898 9.28343 1.56676 8.99721 1.32142 8.63942C1.08631 8.28164 0.96875 7.87786 0.96875 7.42807C0.96875 6.87607 1.11186 6.44162 1.39809 6.12472C1.68432 5.79761 2.14943 5.56249 2.79344 5.41938C3.43745 5.27627 4.30124 5.20471 5.38481 5.20471H6.15149V6.30873H5.40014C4.76636 6.30873 4.26035 6.33939 3.88212 6.40073C3.5039 6.46206 3.233 6.5694 3.06945 6.72273C2.91611 6.86584 2.83944 7.07029 2.83944 7.33607C2.83944 7.67341 2.957 7.94941 3.19211 8.16408C3.42723 8.37875 3.75434 8.48609 4.17346 8.48609C4.5108 8.48609 4.80725 8.40942 5.06281 8.25608C5.32859 8.09253 5.53815 7.87275 5.69148 7.59674C5.84482 7.32074 5.92148 7.00385 5.92148 6.64606V4.88271C5.92148 4.37159 5.80904 4.00358 5.58415 3.77869C5.35925 3.5538 4.98103 3.44135 4.44946 3.44135C4.15302 3.44135 3.83101 3.47713 3.48345 3.54869C3.14611 3.62024 2.78833 3.74291 2.4101 3.91669C2.21588 4.00869 2.0421 4.03425 1.88876 3.99336C1.74565 3.95247 1.6332 3.87069 1.55142 3.74802C1.46965 3.61513 1.42876 3.47202 1.42876 3.31868C1.42876 3.16535 1.46965 3.01712 1.55142 2.87401C1.6332 2.72068 1.77121 2.60823 1.96543 2.53667C2.43566 2.34245 2.88544 2.20445 3.31478 2.12267C3.75434 2.04089 4.15302 2 4.5108 2C5.24681 2 5.84993 2.11245 6.32016 2.33734C6.80061 2.56223 7.15839 2.90468 7.3935 3.36468C7.62862 3.81447 7.74617 4.39714 7.74617 5.11271V8.79276C7.74617 9.10965 7.66951 9.35499 7.51617 9.52877C7.36284 9.69233 7.14305 9.77411 6.85683 9.77411C6.5706 9.77411 6.34571 9.69233 6.18215 9.52877C6.02882 9.35499 5.95215 9.10965 5.95215 8.79276V8.05675H6.07482C6.00326 8.41453 5.86015 8.72631 5.64548 8.99209C5.44103 9.24765 5.18036 9.44699 4.86347 9.5901C4.54658 9.73322 4.18368 9.80477 3.77479 9.80477Z"
								fill="#E861C9"
							/>
						</svg>
					);
				}
			},
		},
		{
			accessorKey: 'statement',
			header: () => 'Statement',
		},
		{
			accessorKey: 'solicitationReference',
			header: 'Reference',
		},
		{
			accessorKey: 'sections',
			header: 'Connected Proposal Sections',
			cell: ({ row }) => (
				<div className="flex flex-wrap gap-2 text-nowrap">
					{row.original.sections.map((section) => (
						<Badge
							key={section.id}
							className="font-medium uppercase bg-white text-nowrap border-primary dynamic-badge text-primary rounded-2xl whitespace-normal overflow-hidden max-w-80 2xl:max-w-96"
						>
							{section.name}
						</Badge>
					))}
				</div>
			),
		},

		{
			accessorKey: 'explanation',
			header: 'What is Required',
		},
		{
			accessorKey: 'actions',
			header: 'Actions',
			cell: ({ row }) => (
				<div className="flex gap-4">
					<div
						tabIndex={0}
						role="button"
						onClick={() => handleEditMatrix(row.original.id)}
						className="flex items-center gap-2 uppercase cursor-pointer text-primary "
					>
						<Pencil size={16} />
						Edit
					</div>
					<div
						tabIndex={0}
						role="button"
						onClick={() => handleDeleteMatrix(row.original.id)}
						className="flex items-center gap-2 uppercase cursor-pointer text-secondary"
					>
						<Trash2 size={16} />
						Delete
					</div>
				</div>
			),
		},
	];

	return columns;
}
