import {
	ChangeEvent,
	FC,
	KeyboardEvent,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import styles from './EnhanceWrapper.module.css';
import { cn } from '@/lib/utils';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import Enhance from '@/components/ui/icons/new/Enhance/Enhance';
// import { Input } from '@/components/ui/input';
import Send from '@/components/ui/icons/Send';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { addAIResponse, addEnhanceQuery } from '@/redux/slices/enhanceSlice';
import EnhanceUserMessage from '@/components/EnhanceUserMessage/EnhanceUserMessage';
import EnhanceAIMessage from '@/components/EnhanceAIMessage/EnhanceAIMessage';
import { MagicContext } from '@/lib/context/MagicProvider';
import { MAGIC_TYPES } from '@/lib/types/apiTypes';
import useProposalId from '@/lib/hooks/useProposalId';
import { startStreaming } from '@/redux/slices/pageSlice';
import { setWriterLoader } from '@/redux/slices/proposalSlice';
import WriteModeSkeleton from '@/components/WriteModeSkeleton/WriteModeSkeleton';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cropText } from '@/lib/functions/funcUtils';
import EmptyEnhanceIllustration from '@/components/ui/icons/EmptyEnhanceIllustration';
import ExpandableTextarea from '@/components/ui/shared/ExpandableTextarea/ExpandableTextarea';

interface EnhanceWrapperProps {
	mode?: string;
}

const EnhanceWrapper: FC<EnhanceWrapperProps> = ({ mode }) => {
	const { activeSection } = useActiveSection();
	const isStreaming = useSelector((state: RootState) => state.page.isStreaming);
	const [queryMessage, setQueryMessage] = useState('');
	const dataRef = useRef('');
	const enhanceQueries = useSelector(
		(state: RootState) => state.enhance.queries
	);
	const containerRef = useRef<HTMLDivElement>(null);

	const proposalID = useProposalId();

	const handleMagic = useContext(MagicContext);

	const dispatch = useAppDispatch();

	const isWriteMode = location.pathname.endsWith('/write');

	const filteredMessages = useMemo(() => {
		// Ensure that both `enhanceQueries` and `activeSection.sectionId` exist
		if (!activeSection?.sectionId || !enhanceQueries) return [];

		// Return the messages for the active section, or an empty array if none exist
		return enhanceQueries[activeSection.sectionId as number] || [];
	}, [enhanceQueries, activeSection?.sectionId]);

	const enhanceQueryStringMessage = useMemo(() => {
		if (!activeSection) return '';

		return activeSection.sectionType === 'table'
			? (activeSection.editorHTML as string)
			: (activeSection.editorText as string);
	}, [activeSection]);

	useEffect(() => {
		if (isWriteMode) return;
		if (
			handleMagic &&
			proposalID &&
			activeSection.editorRef &&
			enhanceQueryStringMessage === ''
		) {
			dispatch(startStreaming());
			handleMagic(
				proposalID,
				String(activeSection.sectionId),
				dataRef,
				activeSection.editorRef,
				'table',
				MAGIC_TYPES.COMPLETE
			);
		}
	}, [
		proposalID,
		activeSection.editorRef,
		handleMagic,
		activeSection.sectionId,
		enhanceQueryStringMessage,
		isWriteMode,
	]);

	const scrollToBottom = () => {
		setTimeout(() => {
			if (containerRef.current) {
				const container = containerRef.current;
				container.scrollTop = container.scrollHeight;
			}
		}, 50);
	};

	useEffect(() => {
		scrollToBottom();
	}, []);

	if (!(isStreaming && isWriteMode)) {
		scrollToBottom();
	}

	if (isStreaming && isWriteMode) return <WriteModeSkeleton />;

	const EmptyEnhanceBox = () => {
		return (
			<div className="bg-[#EAF1FC] p-5 flex flex-col gap-10 justify-start items-center h-full">
				<div className="flex flex-col items-center justify-center gap-1 p-5 text-center bg-white shadow-main rounded-2xl">
					<p className="font-bold text-secondary dynamic-medium">
						Nothing to Enhance yet.
					</p>

					<p className="font-bold text-black dynamic-medium">
						Start by clicking on any section on the left to start working on
						that section
					</p>
				</div>
				<div>
					<p className="text-center text-black dynamic-medium">
						<span className="mr-1 font-bold">Tip:</span>You can use this enhance
						tool to provide instructions on how to improve this section.
					</p>
				</div>
				<Enhance />
			</div>
		);
	};

	const handleSendClick = () => {
		if (!queryMessage || !activeSection) return;
		dispatch(
			addEnhanceQuery({
				content: queryMessage,
				sectionId: activeSection.sectionId as number,
			})
		);
		dispatch(
			addAIResponse({
				content: '',
				sectionId: activeSection.sectionId as number,
			})
		);

		dispatch(setWriterLoader(true));
		dataRef.current = '';
		dispatch(startStreaming());
		const payload: {
			query: string;
			instructions: string;
		} = {
			query: enhanceQueryStringMessage,
			instructions: queryMessage,
		};

		if (handleMagic && proposalID && activeSection.editorRef) {
			handleMagic(
				proposalID,
				String(activeSection.sectionId),
				dataRef,
				activeSection.editorRef,
				activeSection.sectionType === 'table' ? 'table' : 'text',
				MAGIC_TYPES.ENHANCE_WITH_ME,
				payload
			);
		}

		setQueryMessage('');
	};

	const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setQueryMessage(event.target.value);
	};

	const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === 'Enter') {
			handleSendClick();
		}
	};

	return (
		<div className={cn(styles.wrapper, mode === 'logical-frame' && 'h-screen')}>
			{activeSection.sectionId ? (
				<div className="flex flex-col h-full px-5 pt-5">
					<div className="pb-5 border-b border-[#C6DBFB]">
						<p className="w-[300px] mx-auto text-black text-center font-semibold">
							I am here to help you enhance this section:{' '}
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger className="text-secondary">
										{cropText(activeSection.sectionName || '', 40)}
									</TooltipTrigger>
									{activeSection.sectionName?.length &&
										activeSection.sectionName.length > 40 && (
											<TooltipContent
												side="bottom"
												className="bg-white max-w-[600px] font-normal text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-xl border-none shadow-none drop-shadow-effect"
											>
												{activeSection.sectionName}
											</TooltipContent>
										)}
								</Tooltip>
							</TooltipProvider>
							. Tell me how you want me to improve it.
						</p>
					</div>

					<div
						className={cn(
							styles.list,
							'py-3 pr-2 flex flex-col  gap-4 scroll-smooth',
							mode === 'logical-frame'
								? 'h-[calc(100vh-340px)]'
								: 'h-[calc(100vh-430px)]'
						)}
						ref={containerRef}
					>
						{filteredMessages.length ? (
							<>
								{filteredMessages.map((message, index) => {
									const isLastMessage = index === filteredMessages.length - 1;
									if (message.role === 'user') {
										return (
											<EnhanceUserMessage
												key={index}
												content={message.content}
											/>
										);
									} else {
										return (
											<EnhanceAIMessage
												key={index}
												content={message.content}
												loading={isLastMessage && isStreaming}
												type={activeSection.sectionType as string}
											/>
										);
									}
								})}
							</>
						) : mode === 'logical-frame' ? (
							<div className="flex flex-col items-center gap-2 pt-4 text-center ">
								<p className="text-black dynamic-text">
									<span className="font-bold">Tip</span> You can use this
									enhance tool to provide instructions on how to improve this
									section.
								</p>
								<EmptyEnhanceIllustration className="w-full px-4" />
							</div>
						) : (
							<EmptyEnhanceIllustration className="w-full px-2" />
						)}
					</div>
					<div className="relative z-10 ">
						<ExpandableTextarea
							minRows={1}
							maxRows={9}
							disabled={isStreaming}
							value={queryMessage}
							onChange={handleInputChange}
							onKeyDown={(e) => handleKeyPress(e)}
							placeholder="Add your instructions"
							className="text-base bg-white border border-[#5d9bfd] h-[50px] rounded-xl text-black pr-12 min-h-12 py-4"
						/>
						<div className="absolute top-3 right-3" onClick={handleSendClick}>
							<Send />
						</div>
					</div>
				</div>
			) : (
				<EmptyEnhanceBox />
			)}
		</div>
	);
};

export default EnhanceWrapper;
