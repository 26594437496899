import PDFViewer from '@/components/PDFViewer/PDFViewer';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { X } from 'lucide-react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { closePanel } from '@/redux/slices/pageSlice';
import { cn } from '@/lib/utils';
import styles from './ReadDocumentPanel.module.css';

const ReadDocumentPanel = () => {
	const dispatch = useAppDispatch();
	const open = useSelector(
		(root: RootState) => root.page.panel?.type === 'readDocument'
	);
	const pdfData = useSelector((root: RootState) => root.page.panel?.pdf);

	return (
		<>
			<div className={cn(styles.overlay, open ? 'block z-10' : 'hidden')}></div>
			<div
				className={cn(
					styles.panel,
					open ? styles.open : '',
					'h-[calc(100vh-50px)] w-[calc(100vw-50px)] overflow-hidden p-5 bg-background'
				)}
			>
				<div className="flex flex-col gap-4 h-full">
					<div className="flex items-center gap-3 shrink-0">
						<X
							onClick={() => {
								dispatch(closePanel());
							}}
							className="w-8 h-8 p-2 rounded-full text-primary bg-lightBackground cursor-pointer"
						/>
						<h2 className="font-bold text-black dynamic-xl-large">
							Document Viewer
						</h2>
					</div>
					<div className="h-full overflow-hidden">
						{pdfData && <PDFViewer pdfData={pdfData as ArrayBuffer} />}
					</div>
				</div>
			</div>
		</>
	);
};

export default ReadDocumentPanel;
