import ParentNode from '@/components/DesignNodes/ParentNode/ParentNode';
import ChildNode from '@/components/DesignNodes/ChildNode/ChildNode';
import GrandChildNode from '@/components/DesignNodes/GrandChildNode/GrandChildNode';
import styles from './PlanMode.module.css';
import { cn } from '@/lib/utils';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { findParent, nodeHasChildren } from '@/lib/functions/designGraph';
import InfoCards from '@/containers/InfoCards/InfoCards';
import Idea from '@/components/widgets/Idea/Idea';
import { Card } from '@/lib/types/constants';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { useNavigate, useParams } from 'react-router';
import {
	fetchProposalById,
	processStart,
	processSuccess,
} from '@/redux/slices/proposalSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	Document as DocumentType,
	Note as NoteType,
	ProposalTemplateResponse,
	Section,
} from '@/lib/types/apiTypes';
import {
	ErrorHandle,
	calculateProposalTotalCharCount,
	calculateProposalTotalWordCount,
	combineSectionIntoArray,
	findSection,
	handleResponse,
} from '@/lib/functions/funcUtils';
import {
	deleteANoteFromASection,
	getAllSectionsOfProposalTemplate,
} from '@/lib/functions/apiCalls';
import { motion, useAnimation } from 'framer-motion';
import DocumentCard from '@/components/widgets/Document/Document';
import { openPanel, setLastSection } from '@/redux/slices/pageSlice';
import ToolBarInstruction from '@/components/ToolBarInstruction/ToolBarInstruction';
import _ from 'lodash';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
} from '@/components/ui/accordion';
import { AccordionTrigger } from '@radix-ui/react-accordion';
import TopHeader from '@/components/TopHeader/TopHeader';
import PlanModeTitle from '@/components/PlanModeTitle/PlanModeTitle';
import { useTour } from '@/lib/hooks/useTour';
import { Page } from '@/constants/Onboarding/types';
// import TemplateBuilderPanel from '@/components/Panels/TemplateBuilderPanel/TemplateBuilderPanel';
import { FileText, Pencil } from 'lucide-react';
import {
	buildTemplate,
	resetDeletedSections,
	setEditTemplateId,
	toggleProposalTemplateEdit,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import useOrganizationId from '@/lib/hooks/useOrganizationId';
import { templateQuestions } from '@/constants/templateBuilderData';
import {
	// Dropdown,
	SectionBuilder,
} from '@/lib/types/TemplateBuilder/templateTypes';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import useProposalCategory from '@/lib/hooks/useProposalCategory';
import { Separator } from '@/components/ui/separator';
import SectionHamburgerDropdown from '@/components/SectionHamburgerDropdown/SectionHamburgerDropdown';
// import SectionViewer from '@/components/DesignNodes/SectionViewer/SectionViewer';

interface PlanModeProps {
	exiting?: boolean;
}

const PlanMode: FC<PlanModeProps> = (props) => {
	const navigate = useNavigate();
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const user = useSelector((root: RootState) => root.auth.user);
	const dispatch = useAppDispatch();
	const { id, nodeId } = useParams();
	const [sectionData, setSectionData] = useState<{
		[sectionId: string]: Card[];
	}>({});
	const [showCharCount, setShowCharCount] = useState<boolean>(false);

	const sortedNodes = useSelector(
		(root: RootState) => root.proposal.sortedNodes
	);
	const templatePanelOpen = useSelector(
		(root: RootState) => root.page.panel?.type === 'template'
	);

	const [combinedSectionArray, setCombinedSectionArray] = useState<any>([]);
	const { updateActiveSection } = useActiveSection();

	const [isMacroView, setIsMacroView] = useState<boolean>(true);
	const [activeNodeId, setActiveNodeId] = useState<string | null>(null);
	const tour = useSelector((root: RootState) => root.tour);
	useTour(templatePanelOpen ? Page.TemplateBuilderEdit : Page.Plan);

	const control = useAnimation();
	const infoCardControl = useAnimation();
	const subCardsControl = useAnimation();
	const topPanelControl = useAnimation();
	const organizationIdentifier = useOrganizationId();
	const proposalCategory = useProposalCategory();

	const cardsHideAnimation = useMemo(() => {
		return {
			opacity: 0,
			scaleY: 0,
			translateY: '-50%',
			transition: {
				duration: 0.25,
				type: 'cubic-bezier',
				ease: [0.76, 0, 0.24, 1],
			},
		};
	}, []);

	const cardsShowAnimation = useMemo(() => {
		return {
			opacity: 1,
			scaleY: 1,
			translateY: 0,
			transition: {
				duration: 0.4,
				type: 'cubic-bezier',
				ease: [0.76, 0, 0.24, 1],
			},
		};
	}, []);

	const exitingAnimation = { opacity: 0, transition: { duration: 0.25 } };
	const nodeSwitchHide = useCallback(() => {
		infoCardControl.start(cardsHideAnimation);
		subCardsControl.start(cardsHideAnimation);
	}, [infoCardControl, subCardsControl, cardsHideAnimation]);

	const nodeSwitchShow = useCallback(() => {
		infoCardControl.start(cardsShowAnimation);
		subCardsControl.start(cardsShowAnimation);
	}, [infoCardControl, subCardsControl, cardsShowAnimation]);

	const handleNodeClick = (nodeId: string) => {
		setTimeout(() => {
			// setSelectedNode(nodeId);
			setActiveNodeId(nodeId);
			setIsMacroView(false);
			dispatch(
				setLastSection({ proposalId: Number(id), sectionId: Number(nodeId) })
			);
			updateActiveSection({
				editorRef: null,
				sectionId: Number(nodeId),
				prefix: null,
				sectionName: null,
			});

			navigate(`/proposal/${id}/section/${nodeId}/plan`);
			nodeSwitchShow();
		}, 250);
		nodeSwitchHide();
	};

	useEffect(() => {
		(async () => {
			if (id !== undefined) {
				dispatch(fetchProposalById(Number(id)));
			}
		})();
	}, [id, dispatch]);

	useEffect(() => {
		if (nodeId) {
			handleNodeClick(nodeId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nodeId]);

	useEffect(() => {
		const section = findSection(proposal?.sections || [], nodeId as string);
		if (section) {
			const sortedArray = combineSectionIntoArray(section);
			setCombinedSectionArray(sortedArray);
		}
		setSectionData((prev) => {
			return { ...prev, [nodeId as string]: [] };
		});
	}, [proposal, nodeId]);

	const handleDelete = async (type: string, index: number, isNew?: boolean) => {
		switch (type) {
			case 'idea':
				try {
					setSectionData((prev) => {
						const newNotes = [...prev[nodeId as string]];
						newNotes.splice(index, 1);
						return { ...prev, [nodeId as string]: newNotes };
					});
					if (!isNew) {
						dispatch(processStart());
						const response = await deleteANoteFromASection(
							Number(id),
							Number(nodeId),
							index
						);
						handleResponse(response, 'Idea has been deleted successfully.');
						dispatch(processSuccess());
						dispatch(fetchProposalById(Number(id)));
					}
				} catch (error: unknown) {
					dispatch(processSuccess());
					ErrorHandle(dispatch, error);
				}
				break;
			default:
				console.error('Unsupported type:', type);
				break;
		}
	};

	useEffect(() => {
		if (props.exiting === true) {
			infoCardControl.start(exitingAnimation);
			control.start(exitingAnimation);
			subCardsControl.start(exitingAnimation);
			topPanelControl.start(exitingAnimation);
		}
		topPanelControl.start({ opacity: 1, transition: { duration: 0.8 } });
		infoCardControl.start(cardsShowAnimation);
		subCardsControl.start(cardsShowAnimation);
		control.start(cardsShowAnimation);
	}, [props.exiting]);

	const editProposalTemplate = async () => {
		try {
			dispatch(processStart());
			const response = await getAllSectionsOfProposalTemplate(Number(id));
			if (response.statusCode === 200) {
				dispatch(processSuccess());
				const proposalTemplate = (response.data as ProposalTemplateResponse)
					.proposal;
				if (proposalTemplate) {
					const updateSection = (section: SectionBuilder): SectionBuilder => ({
						...section,
						isPristine: true,
						isNew: false,
						children: section.children.map(updateSection),
					});

					const updatedSections = proposalTemplate.sections.map(updateSection);
					dispatch(
						buildTemplate({
							name: proposalTemplate.name,
							tags: [],
							sections: updatedSections,
							organizationIdentifier: organizationIdentifier,
							description: '',
							questions: templateQuestions,
						})
					);
					dispatch(openPanel({ type: 'template' }));
					dispatch(resetDeletedSections());
					dispatch(setEditTemplateId(String(proposal?.templateId)));
					dispatch(toggleProposalTemplateEdit(false));
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className={cn(styles.wrapper, 'bg-[#f5f5f5] ')}>
			<motion.div initial={{ opacity: 0 }} animate={topPanelControl}>
				<TopHeader
					rfpLink={proposal?.rfpLink || ''}
					rfpName={proposal?.rfpTitle || ''}
					proposalName={proposal?.name || ''}
				/>
			</motion.div>
			<div className={cn('grid grid-cols-12 gap-6 px-8')}>
				<motion.div
					className={cn(
						styles.nodeView,
						'pb-4 h-[calc(100vh-160px)] hidden 2xl:block overflow-y-scroll hidden-scroll section-outline',
						proposalCategory !== 'questionnaire'
							? 'col-span-3'
							: 'col-span-6 2xl:col-span-7'
					)}
					initial={{ opacity: 0, scaleY: 0, translateY: '-50%' }}
					animate={control}
					transition={{
						duration: 0.66,
						delay: 0.12,
						type: 'cubic-bezier',
						ease: [0.76, 0, 0.24, 1],
					}}
					exit={exitingAnimation}
				>
					<>
						<div className="flex items-center justify-between px-3 mb-2">
							<h2 className="font-bold text-secondary dynamic-xl-large ">
								{proposalCategory === 'questionnaire' ? 'Question' : 'Outline'}
							</h2>
							{user?.templateBuilderAccess && (
								<div
									tabIndex={0}
									onClick={editProposalTemplate}
									className="flex items-center gap-2 font-medium uppercase cursor-pointer animated-hover-effect text-primary edit-template"
								>
									<Pencil size={16} />
									Edit Outline
								</div>
							)}
						</div>

						<Accordion
							type="single"
							defaultValue={findParent(sortedNodes, nodeId as string) as string}
						>
							{sortedNodes?.map((parentNode) => {
								if (parentNode.type === 'parent') {
									const hasChildren = nodeHasChildren(
										parentNode.id,
										sortedNodes
									);
									return (
										<AccordionItem
											value={parentNode.id}
											className="mb-2 border-none section-node"
											key={parentNode.id}
										>
											<AccordionTrigger className="w-full">
												<ParentNode
													key={parentNode.id}
													id={parentNode.id}
													data={parentNode.data}
													hasChildren={hasChildren}
													isSelected={parentNode.id === activeNodeId}
													onClick={() => handleNodeClick(parentNode.id)}
													section={
														sortedNodes.find(
															(node) => node.id === parentNode.id
														)?.data
													}
													editTemplate={user?.templateBuilderAccess}
													noScaleEffect={true}
												/>
											</AccordionTrigger>

											<AccordionContent
												className={cn(
													'w-full mt-2',
													!hasChildren ? 'hidden' : ''
												)}
											>
												{sortedNodes
													.filter(
														(node) => node.data.parentId === parentNode.id
													)
													.map((childNode) => {
														return (
															<div
																key={childNode.id}
																className="flex flex-col items-end w-full gap-2 mb-2"
															>
																<ChildNode
																	key={childNode.id}
																	hasChildren={nodeHasChildren(
																		childNode.id,
																		sortedNodes
																	)}
																	id={childNode.id}
																	data={childNode.data}
																	isSelected={childNode.id === activeNodeId}
																	onClick={() => handleNodeClick(childNode.id)}
																	section={
																		sortedNodes.find(
																			(node) => node.id === childNode.id
																		)?.data
																	}
																	editTemplate={user?.templateBuilderAccess}
																/>
																{/* Render grandchild nodes */}
																{sortedNodes
																	.filter(
																		(node) =>
																			node.data.parentId === childNode.id &&
																			node.type === 'grandchild'
																	)
																	.map((grandchildNode) => (
																		<GrandChildNode
																			hasChildren={nodeHasChildren(
																				grandchildNode.id,
																				sortedNodes
																			)}
																			key={grandchildNode.id}
																			id={grandchildNode.id}
																			data={grandchildNode.data}
																			isSelected={
																				grandchildNode.id === activeNodeId
																			}
																			onClick={() =>
																				handleNodeClick(grandchildNode.id)
																			}
																			section={
																				sortedNodes.find(
																					(node) =>
																						node.id === grandchildNode.id
																				)?.data
																			}
																			editTemplate={user?.templateBuilderAccess}
																		/>
																	))}
															</div>
														);
													})}
											</AccordionContent>
										</AccordionItem>
									);
								}
							})}
						</Accordion>
					</>
					<div className="flex items-center gap-2 px-3 py-2 text-black">
						<div className="flex-1 w-full bg-[#D3DDE2] h-[1px] relative">
							<div className="w-2 h-2 rounded-full bg-[#D3DDe3] absolute top-1/2 left-0 -translate-x-1/2 -translate-y-1/2"></div>
						</div>
						<p
							className="cursor-pointer"
							onClick={() => setShowCharCount(!showCharCount)}
						>
							<span className="font-bold">
								Total {showCharCount ? 'characters' : 'words count'}:
							</span>{' '}
							{showCharCount
								? calculateProposalTotalCharCount(
										proposal?.sections as Section[]
									)?.toLocaleString()
								: calculateProposalTotalWordCount(
										proposal?.sections as Section[]
									)?.toLocaleString()}
						</p>
					</div>
				</motion.div>
				<motion.div
					className={
						proposalCategory !== 'questionnaire'
							? 'col-span-12 2xl:col-span-9'
							: 'col-span-6 2xl:col-span-5'
					}
					initial={{ opacity: 0, scaleY: 0, translateY: '-50%' }}
					animate={control}
				>
					<div className="flex items-center gap-4 px-6 py-4 mb-4 bg-white 2xl:hidden rounded-xl shadow-main">
						{/* Outline Button */}
						<SectionHamburgerDropdown
							sections={sortedNodes}
							handleNodeClick={handleNodeClick}
						/>
						{user?.templateBuilderAccess && (
							<div
								tabIndex={0}
								onClick={editProposalTemplate}
								className="flex items-center gap-2 font-semibold uppercase cursor-pointer shrink-0 animated-hover-effect text-primary edit-template"
							>
								<Pencil size={16} />
								Edit Outline
							</div>
						)}
						<Separator className="w-[1px] h-10 mx-2 bg-gray-200" />
						<p className="text-sm text-gray-500">
							Click on the outline button to see your full outline. Click on a
							section to easily navigate to that section. You can also edit the
							outline by clicking that button
						</p>
					</div>
					<PlanModeTitle
						title={
							sortedNodes?.filter((node) => node.id === activeNodeId)[0]?.data
								.alias as string
						}
						setSectionData={setSectionData}
					/>
					<motion.div
						className="grid grid-cols-12 mt-4"
						initial={{ opacity: 0, scaleY: 0, translateY: '-50%' }}
						animate={infoCardControl}
					>
						{proposalCategory !== 'questionnaire' && (
							<div
								className={`col-span-7 h-[calc(100vh-250px)] p-3 ${!tour.run ? 'overflow-y-auto' : ''}`}
							>
								<div className="w-full rounded-xl section-approach">
									<InfoCards
										selectedNode={activeNodeId as string}
										sortedNodes={sortedNodes}
									/>
								</div>
								<div className="flex items-center justify-between gap-12 p-6 mt-4 bg-white shadow-main rounded-xl">
									<p className="text-black">
										When you are satisfied with your response to the question,
										your ideas and adding in documents, you can start using the
										AI to create a first draft of this section.
									</p>
									<SecondaryButton
										className="shrink-0"
										onClick={() =>
											navigate(`/proposal/${id}/section/${nodeId}/write`)
										}
									>
										<FileText size={18} />
										Write this section
									</SecondaryButton>
								</div>
							</div>
						)}
						<motion.div
							className={cn(
								proposalCategory !== 'questionnaire'
									? 'col-span-5'
									: 'col-span-12'
							)}
							animate={subCardsControl}
						>
							<div>
								{!isMacroView && (
									<div className="flex flex-col gap-2 h-[calc(100vh-250px)] p-3 pr-2 overflow-y-auto">
										{(_.isEmpty(combinedSectionArray) ||
											combinedSectionArray.length === 0) &&
										(_.isEmpty(sectionData[nodeId as string]) ||
											sectionData[nodeId as string].length === 0) ? (
											<ToolBarInstruction />
										) : (
											<>
												{(sectionData[nodeId as string] || []).map(
													(card, index) => {
														const commonProps = {
															// key: index,
															onDelete: () =>
																handleDelete(card.type, index, true),
														};
														switch (card.type) {
															case 'idea':
																return (
																	<Idea
																		key={index}
																		{...commonProps}
																		isNew={true}
																		newContent={card.content}
																	/>
																);

															default:
																return null;
														}
													}
												)}

												{combinedSectionArray.map((card, index) => {
													switch (card.type) {
														case 'idea':
															return (
																<Idea
																	key={index}
																	idea={card as NoteType}
																	onDelete={() => handleDelete('idea', card.id)}
																/>
															);
														case 'document':
															return (
																<DocumentCard
																	document={card as DocumentType}
																	user={card.user}
																	key={card.id}
																	onDelete={() =>
																		handleDelete('documents', card.id)
																	}
																/>
															);
													}
												})}
											</>
										)}
									</div>
								)}
							</div>
						</motion.div>
					</motion.div>
				</motion.div>
			</div>
		</div>
	);
};

export default PlanMode;
