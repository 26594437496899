import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import styles from './PlanCard.module.css';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { ErrorResponse, RFP, ToneOfVoice } from '@/lib/types/apiTypes';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import {
	openDialog,
	openPanel,
	setLastVisitPage,
} from '@/redux/slices/pageSlice';
import { handleApiError, handleGeneralError } from '@/lib/functions/funcUtils';
import { cn } from '@/lib/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import ToneOfVoiceChart from '../ToneOfVoiceChart/ToneOfVoiceChart';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import PlanCardHeader from '../PlanCardHeader/PlanCardHeader';
import {
	FileSpreadsheet,
	GanttChartSquare,
	List,
	PencilLineIcon,
	SendToBackIcon,
} from 'lucide-react';
import useLogicalFrameEnabled from '@/lib/hooks/useLogicalFrameEnabled';
import { useNavigate } from 'react-router';
import { fetchComplianceMatrix } from '@/redux/requests/compliance';
import { getDocumentById } from '@/lib/functions/apiCalls';

interface PlanCardProps {
	title: string;
	responseTo?: string;
	text: string | null;
	shortDescription: string;
	info: string;
	icon: ReactNode;
	edit?: boolean;
	comments?: boolean;
	pdfLink?: string;
	type: 'approach' | 'rfp' | 'voice';
	tourClass: string;
}

const PlanCard: FC<PlanCardProps> = ({
	title,
	text,
	shortDescription,
	info,
	icon,
	type,
	tourClass,
}) => {
	const cardRef = useRef<HTMLDivElement | null>(null);
	const textRef = useRef<HTMLParagraphElement>(null);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const matrix = useSelector(
		(root: RootState) => root.compliance.complianceMatrix
	);
	const lastSection = useSelector((state: RootState) => state.page.lastSection);
	const toneOfVoice = (proposal as any)?.settings?.toneOfVoice as ToneOfVoice;
	const isLogicalFrameEnabled = useLogicalFrameEnabled();
	const [showReadMore, setShowReadMore] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		if (textRef.current) {
			const height = textRef.current.offsetHeight;
			if (height > 150) {
				setShowReadMore(true);
			} else {
				setShowReadMore(false);
			}
		}
	}, [text, textRef]);

	const handleApproach = async () => {
		dispatch(processStart());
		try {
			dispatch(openDialog({ type: 'viewBigPicture' }));
			dispatch(processSuccess());
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			}
		}
	};

	const handleTone = async () => {
		dispatch(processStart());
		try {
			dispatch(
				openDialog({
					type: 'changeTone',
					changeToneProps: {
						type: 'proposal',
						toneOfVoice: toneOfVoice,
						proposalId: proposal?.id,
					},
				})
			);
			dispatch(processSuccess());
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			}
		}
	};

	const handleBreakDown = async () => {
		if (proposal?.documentId) {
			const response = await getDocumentById(proposal.documentId);
			if (response.statusCode >= 400 && response.statusCode <= 599) {
				throw response; // Throw the response if status is 400s or 500s
			}
			if (response.statusCode === 200) {
				dispatch(
					openDialog({
						type: 'viewSolicitationBrief',
						rfp: response.data as RFP,
						viewProposalType: 'proposal',
					})
				);
			}
		}
	};

	const renderButton = () => {
		switch (type) {
			case 'approach':
				return (
					<PrimaryButton
						type="button"
						onClick={handleApproach}
						className="revisit-approach-button"
					>
						Refine Our Approach
					</PrimaryButton>
				);
			case 'rfp':
				return (
					// <PrimaryButton onClick={handleBreakDown}>
					// 	View Solicitation Brief
					// </PrimaryButton>
					<></>
				);
			case 'voice':
				return (
					<PrimaryButton onClick={handleTone}>
						Change Proposal Settings
					</PrimaryButton>
				);
		}
	};

	const toggleText = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div
			className={cn(
				styles.card,
				'flex flex-col justify-between shadow-main',
				tourClass
			)}
			ref={cardRef}
		>
			<div className="flex flex-col">
				<PlanCardHeader
					title={title}
					shortDescription={shortDescription}
					info={info}
					icon={icon}
				/>
				{type === 'rfp' ? (
					<div className="flex flex-col items-start pt-2">
						<TextButton onClick={handleBreakDown}>
							<FileSpreadsheet />
							Read the Solicitation Summary
						</TextButton>
						<TextButton
							onClick={() => {
								if (!proposal || !lastSection) return;
								dispatch(setLastVisitPage({ page: 'plan' }));
								navigate(
									`/proposal/${proposal.id}/section/${lastSection.sectionId}/plan`
								);
							}}
						>
							<GanttChartSquare />
							Plan and Ideate your proposal
						</TextButton>
						<TextButton
							onClick={() => {
								if (!proposal || !lastSection) return;
								dispatch(setLastVisitPage({ page: 'write' }));
								navigate(
									`/proposal/${proposal.id}/section/${lastSection.sectionId}/write`
								);
							}}
						>
							<PencilLineIcon />
							Start writing your draft
						</TextButton>
						<TextButton
							className="compliance-card"
							onClick={() => {
								if (!matrix) {
									dispatch(fetchComplianceMatrix(Number(proposal?.id)));
								}
								dispatch(
									openPanel({
										type: 'compliance',
									})
								);
							}}
						>
							<List />
							View compliance requirements
						</TextButton>
						{!!isLogicalFrameEnabled && (
							<TextButton
								onClick={() => {
									navigate(`/proposal/${proposal?.id}/logical-frame`);
								}}
							>
								<SendToBackIcon />
								See the logical framework
							</TextButton>
						)}
					</div>
				) : type === 'voice' ? (
					<ToneOfVoiceChart toneOfVoice={proposal?.settings?.toneOfVoice} />
				) : (
					<>
						<div
							className={`${styles.textContainer}  ${
								isExpanded
									? styles.expanded
									: showReadMore
										? styles.collapsed
										: ''
							}`}
						>
							<p
								ref={textRef}
								className={`text-base text-[#6D7D86] font-normal mt-3`}
							>
								{text}
							</p>
						</div>
						{showReadMore && (
							<TextButton
								onClick={toggleText}
								className="h-fit 2xl:h-fit w-fit p-0 mt-2 font-bold text-start read-more-button text-primary"
							>
								{isExpanded ? (
									<span className="font-normal">Less</span>
								) : (
									'Read More'
								)}
							</TextButton>
						)}
					</>
				)}
			</div>
			{type !== 'rfp' && (
				<div className="flex items-center justify-end mt-4">
					{renderButton()}
				</div>
			)}
		</div>
	);
};

export default PlanCard;
