import { FC, useMemo } from 'react';
import styles from './PlanModeTitle.module.css';
import { cn } from '@/lib/utils';
import WidgetMenu from '../WidgetMenu/WidgetMenu';
import { Card } from '@/lib/types/constants';
import { updateSectionState } from '@/lib/functions/apiCalls';
import { useParams } from 'react-router';
import { useAppDispatch } from '@/lib/hooks/hooks';
import {
	processStart,
	processSuccess,
	setProposal,
	setSortedNodes,
} from '@/redux/slices/proposalSlice';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { CheckCircle2 } from 'lucide-react';
import {
	cropText,
	fetchProposal,
	handleResponse,
} from '@/lib/functions/funcUtils';
import { parseSectionsToNodes } from '@/lib/functions/designGraph';
import customToast from '../CustomToast/CustomToast';
import { Proposal } from '@/lib/types/apiTypes';
import useProposalCategory from '@/lib/hooks/useProposalCategory';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

interface PlanModeTitleProps {
	title: string;
	setSectionData: React.Dispatch<React.SetStateAction<Record<string, Card[]>>>;
}

const PlanModeTitle: FC<PlanModeTitleProps> = ({ title, setSectionData }) => {
	const { id, nodeId } = useParams();
	const dispatch = useAppDispatch();
	const sortedNodes = useSelector(
		(state: RootState) => state.proposal.sortedNodes
	);
	const proposalCategory = useProposalCategory();

	const isComplete = useMemo(() => {
		return sortedNodes?.find((node) => node.id === nodeId)?.data.isComplete;
	}, [sortedNodes, nodeId]);

	const commentCount = useMemo(() => {
		return sortedNodes?.find((node) => node.id === nodeId)?.data.commentCount;
	}, [sortedNodes, nodeId]);

	const handleMarkComplete = async (isComplete: boolean) => {
		try {
			if (!id || !nodeId) return;
			dispatch(processStart());
			const response = await updateSectionState(Number(id), Number(nodeId), {
				isComplete: isComplete,
			});
			handleResponse(response);
			if (response.statusCode === 200) {
				const proposal = (await fetchProposal(Number(id))) as Proposal;
				handleResponse(
					proposal,
					isComplete
						? 'Section successfully marked as completed'
						: 'Section successfully marked as incomplete'
				);
				if (proposal) {
					dispatch(setSortedNodes(parseSectionsToNodes(proposal.sections)));
					dispatch(setProposal(proposal));
				}
				dispatch(processSuccess());
			}
		} catch (error) {
			dispatch(processSuccess());
			if (isComplete) {
				customToast.error({
					title: 'Unable to mark section as complete',
				});
			} else {
				customToast.error({
					title: 'Unable to mark section as incomplete',
				});
			}
		}
	};
	return (
		<div
			className={cn(
				styles.card,
				'shadow-main flex items-center gap-4',
				proposalCategory === 'solicitation' ? 'justify-between' : 'justify-end'
			)}
		>
			{proposalCategory === 'solicitation' && (
				<div className="flex items-center gap-4">
					<CustomTooltip
						text={title}
						triggerNode={
							<h2 className="text-start font-bold dynamic-large text-secondary">
								{cropText(title, 30)}
							</h2>
						}
					/>

					<div
						onClick={() => handleMarkComplete(!isComplete as boolean)}
						className="mt-1 font-semibold cursor-pointer dynamic-small text-primary"
					>
						{isComplete ? (
							<div className="flex items-center gap-2 uppercase animated-hover-effect">
								<CheckCircle2
									size={20}
									fill="var(--light-green)"
									stroke="white"
								/>
								Completed
							</div>
						) : (
							<div className="flex items-center gap-2 uppercase animated-hover-effect">
								<CheckCircle2 size={20} fill="var(--primary)" stroke="white" />
								Mark as complete
							</div>
						)}
					</div>
				</div>
			)}
			<WidgetMenu
				setSectionData={setSectionData}
				commentCount={commentCount as number}
			/>
		</div>
	);
};

export default PlanModeTitle;
