import BrainstormDrawer from './BrainstormDrawer/BrainstormDrawer';
import CommentsDrawer from './CommentsDrawer/CommentsDrawer';
import ClipboardIdeaDrawer from './ClipboardIdeaDrawer/ClipboardIdeaDrawer';
import WriteModeDrawer from './WriteModeDrawer/WriteModeDrawer';

const Drawers = () => {
	return (
		<>
			<BrainstormDrawer />
			<CommentsDrawer />
			<ClipboardIdeaDrawer />
			<WriteModeDrawer />
		</>
	);
};

export default Drawers;
