import { DateRange } from 'react-day-picker';
import { SectionBuilder } from './TemplateBuilder/templateTypes';
import { NodeData } from './graphTypes';
import { Notification } from './constants';

export interface User {
	id: number;
	name: string;
	email: string;
	areaOfFocus: string | null;
	designation: string | null;
	password: string;
	organizationId: number;
	telephoneNumber: string | null;
	roleId: number | null;
	profilePicture: string | null;
	isAssigned: boolean;
	role: Role;
	uuid: string;
	templateBuilderAccess: boolean;
	organization: OrganizationInfo;
	hasViewedIntroVideo: boolean;
	onboarding: string[];
	isTwoFactorEnabled: boolean;
	isTelephoneNumberVerified: boolean;
}

export interface OrganizationInfo {
	uuid: string;
	name: string;
	isSsoEnabled: boolean;
	initialSetupStep?: number | null;
	isOrganizationProfileEnabled: boolean;
	isLogicalFrameEnabled: boolean;
	plan: Plan;
	daysUntilTrialEnds: number;
}

export interface Plan {
	type: 'trial' | 'customer';
	startDate: string;
	endDate: string;
}
export interface Role {
	id: number;
	name: 'super_admin' | 'admin' | 'member' | 'guest';
}

export interface LoginPayload {
	auth: string;
	email: string;
	password: string;
}

export interface LoginDomainPayload {
	domain: string;
}

export interface DomainSearchPayload {
	email: string;
}

export interface ForgotPasswordPayload {
	email: string;
}

export interface InvitedUserInfoPayload {
	name: string;
	password: string;
	designation: string | null;
}

export interface UserProfilePayload {
	name: string;
	designation: string | null;
	telephoneNumber: string | null;
	profilePicture?: string | null;
}

export interface UpdateUserPayload {
	roleId?: number;
	status?: UserStatus;
	templateBuilderAccess?: boolean;
	isTwoFactorEnabled?: boolean;
}

export interface ChangePasswordPayload {
	password: string;
	userInput: string;
}

export interface ResetPasswordPayload {
	password: string;
}

export interface OrganizationProfilePayload {
	name: string;
	description: string | null;
	expertise: string[];
	regionsOfWork: string[];
	countriesAndTerritories: string[];
	areaOfWork: string | null;
	organizationSize: string | null;
	initialSetupStep?: number | null;
	isDefault?: boolean;
}

export interface OrganizationProfile extends OrganizationProfilePayload {
	id: number;
}

export interface OrganizationPayload extends OrganizationProfilePayload {
	websiteUrl: string | null;
	address: string | null;
	country: string | null;
	city: string | null;
	state: string | null;
	zip: number | null;
	annualRevenue: string | null;
}

export interface Organization extends OrganizationPayload {
	id: number;
}

export interface GenerateSampleTextPayload {
	toneOfVoice: ToneOfVoice;
}

export interface ToneOfVoice {
	tone: {
		name: string;
		percentage: number;
	}[];
	sampleText: string | null;
	writingStyle: {
		name: string | null;
		exampleText: string | null;
	};
	citationFormat: string | null;
}

export interface ToneOfVoicePayload {
	settings: {
		toneOfVoice: ToneOfVoice;
	};
	initialSetupStep?: number | null;
}

export interface ProposalOrganizationProfilePayload {
	organizationProfileId: number;
}

export interface LoginResponse {
	accessToken: string;
	user: User;
}

export interface LoginSSOResponse {
	ssoUrl: string;
}

export interface InviteUserPayload {
	email: string;
	roleId: number;
}

export interface FindDocuments {
	content: string;
	documentId: number;
	sourceLink: string;
	summary: string;
	category: string;
	name: string;
	alias?: string;
	attachedAs?: string;
	isAdded?: boolean;
}

export interface UploadDocumentPayload {
	file: FormData | File;
	name: string;
	type: string;
	category?: string;
	additionalResources?: File[] | null;
	isSolicited?: boolean;
	hash?: string;
	decDocumentId?: string;
	folderId?: number;
}

export interface RFP {
	sourceLink?: string;
	documentId: number;
	category: string;
	// metadata
	metaData: {
		code: string;
		title: string;
		summary: string;
		agency: string;
		funding: string;
		deadline: string;
	};
	sections: DocumentSection[];
}

export interface DocumentSection {
	id: number;
	name: string;
	heading: string;
	text: string;
	documentId?: number;
	createdAt?: string;
	updatedAt?: string;
	deletedAt?: null;
	summary: string;
}

export interface Compliance {
	id: number;
	name: string;
	description: string;
	isLive: boolean;
	TemplateCompliance: {
		complianceId: number;
		templateId: number;
	};
}

export interface Feature {
	id: number;
	name: string;
	description: string;
	icon: null;
	isLive: boolean;
	TemplateFeature: {
		featureId: number;
		templateId: number;
	};
}

export interface Question {
	id: number;
	text: string;
	answer: string | null;
	transformedAnswer: string;
	heading: string;
	description: string;
	proTip: string;
	proposalId: null;
	sectionId: null;
	templateId?: number;
}

export interface Section {
	id: number;
	name: string;
	level: number;
	content: string | null;
	type: string;
	isComplete: boolean;
	parentId: number | null;
	proposalId: number;
	templateId: number | null;
	children: Section[];
	comments: Comment[];
	notes: Note[];
	alias: string;
	wordCount: number;
	requiredRfpSections: string[];
	prerequisites: string[];
	questions: Question[];
	sectionDocuments: SectionObj[];
	description: string;
	expectedOutput: 'text' | 'table';
	editorState: string | null;
	references: string[];
	commentsCount: number;
	generationHistory: GenerationType[];
}

export type SectionObj = {
	document: Document;
	attachedAs?: string;
	sectionId: number;
	user: {
		name: string;
	};
};

export type GenerationType = {
	text: string;
	timestamp: string;
	user?: {
		email: string;
		name: string;
		profilePicture: string;
	};
};

export type ProcessingStatus = 'ready' | 'processing' | 'error';

export interface Document {
	id: number;
	sourceLink: string;
	type: string;
	name: string;
	citation: string;
	summary: string;
	category: string;
	code: null;
	organizationId: number;
	uploadedBy: number;
	createdAt: string;
	updatedAt: string;
	SectionDocument: SectionDocument;
	status: ProcessingStatus;
	alias?: string;
	isAttached?: boolean;
	attachment?: {
		attachedAs: string;
	};
}

export interface SectionDocument {
	sectionId: number;
	documentId: number;
	createdAt: string;
	updatedAt: string;
}
export interface Template {
	_id: string;
	name: string;
	description: string;
	tags: string[];
	isLive: boolean;
	compliances: Compliance[];
	features: Feature[];
	sections: Section[];
}

export interface ProposalRequest {
	templateId: string;
	rfpLink?: string;
	documentId: number;
	rfpType: string;
	rfpCode: string;
	rfpTitle: string;
	rfpSummary: string;
	rfpAgency: string;
	rfpFunding: string;
	rfpDeadline: string | null;
}

export interface ProposalResponse {
	status: string;
	progress: number;
	id: number;
	templateId: number;
	rfpLink: string;
	documentId: number;
	rfpCode: string;
	rfpTitle: string;
	rfpSummary: string;
	rfpAgency: string;
	rfpFunding: string;
	organizationId: number;
	name: null;
	deadline: null;
	references: null;
	deletedAt: null;
}

export interface Proposal {
	id: number;
	name: null;
	rfpTitle: string;
	rfpFunding: string;
	rfpLink: string;
	rfpSummary: string;
	rfpCode: string;
	rfpAgency: string;
	rfpDocument: {
		category: string;
	};
	deadline: null;
	references: null;
	status: string;
	progress: number;
	templateId: number;
	organizationId: number;
	documentId: number;
	template: Template;
	questions: Question[];
	sections: Section[];
	settings?: {
		toneOfVoice: ToneOfVoice;
	};
	formativeDocuments?: Document[];
	assignedUsers: User[];
	teams: Team[];
	isComplianceEnabled: boolean;
	isJourneyComplete: boolean;
	organizationProfileId?: number;
	createdBy: number;
}

export interface QuestionResponse {
	isRephrased: boolean;
	rephrasedQuestion: string;
	orginalQuestion: string;
	rephrasedRelevantText: string;
}

export interface ErrorResponse {
	message: string;
	error: string;
	statusCode: number;
}

export interface ApiResponse {
	statusCode: number;
	message: string;
	data: unknown;
}

export interface unsolicitatedQuestion {
	question: string;
	text: string;
}

export interface ProposalResponseData {
	proposal: Proposal;
}

export interface UploadDocumentResponse {
	sourceLink: string;
	documentId: number;
	metaData: {
		title: string;
		summary: string;
		category: string;
		alias?: string;
	};
}

export interface Note {
	id: number;
	content: string;
	type: 'note' | 'idea';
	createdBy: number;
	sectionId: number;
	createdAt: string;
	updatedAt: string;
	user: {
		name: string;
	};
}

export interface Clipboard {
	id: number;
	proposalId: number;
	text: string;
	userId: number;
}

export interface Comment {
	id: number;
	content: string[];
	isResolved: boolean;
	createdAt: string;
	updatedAt: string;
	user: {
		name: string;
		profilePicture: string;
		designation: string;
	};
	sectionId: number;
	parentId: number | null;
	createdBy: number;
	replies?: Comment[];
}

export interface NoteApiPayload {
	content: string;
	type: 'note' | 'idea';
}

export interface WritePayload {
	editorState: string;
	content: string;
	sectionId: number;
}

export interface ClearSectionPayload {
	sectionId: number;
	data: string;
}

export interface Message {
	content: string;
	role: 'user' | 'assistant';
	sectionId: number;
}

export interface Query {
	query: Message[];
}

export interface SuggestionResponse {
	suggestions: Suggestion[];
}

export interface Suggestion {
	text: string;
	relevancy: string[];
	metadata: {
		sourceLink: string;
		citation: string;
		category: string;
		name: string;
	};
}

export interface ComplianceMatrix {
	id: number;
	runs: number;
	status: 'stagnant' | 'processing' | 'errored';
	proposalId: number;
	complianceRules: Compliance[];
	rules: ComplianceRule[];
}

export interface ComplianceRule {
	id: number;
	statement: string;
	explanation: string;
	solicitationReference: string;
	userId: number | null;
	complianceMatrixId: number;
	user: {
		name: string;
	};
	sections: ComplianceMatrixSection[];
}

export interface ComplianceMatrixPayload {
	statement: string;
	explanation: string;
	solicitationReference: string;
	sectionRef: {
		addIds: number[];
		removeIds: number[];
	};
}

export interface ComplianceMatrixSection {
	id: number;
	name: string;
	sectionCompliance: {
		status: ComplianceStatus;
		reference: string | null;
	};
}

export interface Compliance {
	text: string;
	status: ComplianceStatus;
}

export interface PaginationPayload {
	pageNumber: number;
	pageSize: number;
}

export interface ProposalTableFilters {
	assignedUsers?: string[];
	createdOn?: string;
	searchTerm?: string;
}

export interface ProposalTablePayload
	extends PaginationPayload,
		ProposalTableFilters {
	sortOrder?: SortingType;
}

export interface SolicitionTableFilters {
	status?: SolicitionStatus;
	isSolicited?: boolean;
	createdAt?: string;
	funder?: string;
}

export interface AuditLogsPayload extends PaginationPayload {
	date?: DateRange;
	userEmails?: string[];
	category?: string[];
	status?: string[];
	sortOrder?: SortingType;
}

export interface DocumentsPayload extends PaginationPayload {
	sortOrder?: SortingType;
	category?: string;
	search?: string;
}

export interface TemplatesPagination {
	hasNextPage: boolean;
	hasPrevPage: boolean;
	currentPage: number;
}

export type DocumentJourneyTypes =
	| 'un-solicited'
	| 'solicitation'
	| 'questionnaire';

export interface ManualSolicitationPayload {
	name: string;
	alias?: string;
	type: string;
	isSolicited?: boolean;
	metaData: {
		funding: string | null;
		agency: string | null;
		funderUrl: string;
	};
	summary?: string;
	hash?: string;
	category: DocumentJourneyTypes;
}

export interface manualSolicitation extends ManualSolicitationPayload {
	id: number;
}

export interface CreatePDFPayload {
	content: { question: string; text: string }[];
}

export interface ConfirmChange {
	title: string;
	subTitle: string;
	confirmAction: () => void;
}

export type ComplianceStatus = 'not_checked' | 'addressed' | 'not_addressed';

export type DialogTypes =
	| 'viewTemplates'
	| 'viewSolicitationBrief'
	| 'detachDocument'
	| 'addDocuments'
	| 'uploadDocument'
	| 'uploadDocuments'
	| 'viewBigPicture'
	| 'changePassword'
	| 'uploadSolicitation'
	| 'addUser'
	| 'changeTone'
	| 'confirmTemplate'
	| 'addCitation'
	| 'viewSuggestionBackground'
	| 'historyRestoreConfirm'
	| 'createTemplateSection'
	| 'showTemplate'
	| 'openVisuals'
	| 'openMagicConcise'
	| 'openMagicEnhance'
	| 'openMagicExpand'
	| 'viewOpportunity'
	| 'solicitationExists'
	| 'templateTagFilter'
	| 'deleteDocument'
	| 'changeProposalName'
	| 'notification'
	| 'viewSectionInfo'
	| 'renameSection'
	| 'changeSectionWordCount'
	| 'deleteMatrixRule'
	| 'editMatrixRule'
	| 'addMatrixRule'
	| 'editMatrixRuleWarning'
	| 'viewComplianceRuleDetail'
	| 'organizationalProfile'
	| 'changePhone'
	| 'changeDocumentName'
	| 'trialTerms'
	| 'trialEnd'
	| 'moveIdea'
	| 'multiStepUnsolicited'
	| 'nameEditor'
	| 'moveDocuments'
	| 'changeDocumentCategory'
	| 'openLogicalFrame'
	| 'viewTable';

export type AddDocumentsTypes =
	| 'attachFormativeDocument'
	| 'attachSectionDocument'
	| 'uploadOrganizationalDocument';

export type ProposalAction =
	| 'archive'
	| 'unarchive'
	| 'favourite'
	| 'unfavourite';

export type ViewProposalTypes = 'solicitation' | 'proposal';

export type ChangePhoneTypes = 'changePhone' | 'authenticationSetup';

export type SortByTypes = 'latest' | 'lastUpdated' | 'alphabetically';

export type UserStatus = 'active' | 'inactive';

export type SortingType = 'ASC' | 'DESC';

export interface ChartsRecommendResponse {
	recommendations: ChartsRecommendation[];
}

export type ChartType =
	| 'bar'
	| 'line'
	| 'bubble'
	| 'pie'
	| 'area'
	| 'scatter'
	| 'doughnut'
	| 'histogram';

export interface ChartsRecommendation {
	chart: ChartType;
	plotSuggestions: string[];
	reason: string;
}

export interface ChartsResponse {
	chart: Chart;
}

export interface Chart {
	title: string;
	description: string;
	xTitle: string;
	yTitle: string;
	type: ChartType;
	metaData: ChartData;
}

export interface ChartData {
	// labels:string[]
	datasets: Dataset[];
}

export interface Dataset {
	label: string;
	data: number[];
	backgroundColor: string[];
	borderColor: string[];
}

export enum MAGIC_TYPES {
	COMPLETE = 'magic_complete',
	SUGGESTION = 'magic_suggestion_add',
	ENHANCE = 'magic_enhance',
	EXPAND = 'magic_expand',
	CONCISE = 'magic_concise',
	ENHANCE_WITH_ME = 'magic_enhance_with_me',
}

export interface MagicSuggestionPayload {
	magicType: string;
	query: string;
	suggestion: Suggestion;
}

export interface MagicEnhancePayload {
	magicType: string;
	query: string;
	instructions: string;
}

export interface MagicExpandPayload {
	magicType: string;
	query: string;
}

export interface MagicConcisePayload {
	magicType: string;
	query: string;
}

export interface TemplatesPayload {
	pageNumber: number;
	pageSize: number;
	filter: TemplateFilter;
	organizationIdentifier?: string;
	search?: string;
	agency?: string;
}

export type DiscoverOpportunity = {
	isPDF: boolean;
	_id: string;
	category: string[];
	title: string;
	type: string;
	slug: string;
	funder: string;
	countriesList: string[];
	currency: string;
	dueDate: string;
	fundingAmountHigh: number;
	fundingAmountLow: number;
	fundingPeriod: number;
	status: string;
	for: string;
	smallBusinessSetAside: boolean;
	rfpLink: string;
	summary: string;
	eligibility: string;
	isPublished: boolean;
	sourceURL: string;
	sourceWebsite: string;
	keywords: string[];
	contactInformation: {
		name: string;
		email: string;
		phone: string;
		address: string;
		_id: string;
	};
	createdAt: string;
	updatedAt: string;
	__v: number;
	fundingAmount: number | null;
	isFavourite: boolean;
	additionalLink: string[];
	solicitationLinks: string[];
	isMatched?: boolean;
};

export type DiscoverOpportunityResponse = {
	docs: DiscoverOpportunity[];
	total: number;
	page: number;
	limit: number;
	currentPage: number;
	currentCount: number;
	nextPage: string | null;
	prevPage: string | null;
	totalPages: number;
};

export type DiscoverPaginateResponse<T> = {
	docs: T;
	total: number;
	page: number;
	limit: number;
	currentPage: number;
	currentCount: number;
	nextPage: string | null;
	prevPage: string | null;
	totalPages: number;
};

export interface FocusAreaCategoryFilter {
	[key: string]: string[];
}

export type Country = {
	name: string;
	alpha3Code: string;
};

export type RegionResponse = {
	regions: {
		name: string;
		countries: Country[];
	}[];
};

export type CategoriesResponse = {
	docs: { name: string; categories: string[] }[];
};

export type AgencyResponse = {
	docs: string[];
};

export type SearchQuery = {
	_id: string;
	query: string;
	label: string;
	userId: string;
	createdAt: string;
	updatedAt: string;
	id: string;
};

export type TypeResponse = {
	docs: string[];
};

export type SortFilter = 'latest' | 'oldest' | '';

export type TemplateFilter = 'all' | 'pre-built' | 'organization';

export type DocumentType = 'pdf' | 'docx';

export type PopoverAlignment = 'center' | 'end' | 'start';

export type SolicitionStatus =
	| 'being-developed'
	| 'ready'
	| 'processing'
	| 'errored';

export interface SectionCardProps {
	title: string;
	headingPrefix: string;
	description: string;
	wordCount: string;
	id: number;
	expectedOutput: string;
	editorState: string;
	resolved: boolean;
	headingLevel: 1 | 2 | 3;
	scrollParentRef?: any;
	parentRef?: any;
	content: string;
	user?:
		| {
				email: string;
				name: string;
				profilePicture: string;
		  }
		| null
		| undefined;
	type: string;
}

export interface ManageTeamPayload {
	name: string;
	userIds: number[];
}

export interface TeamPayload extends ManageTeamPayload {
	id: number;
}

export interface TeamMember {
	id: number;
	name: string;
	email: string;
	profilePicture: string;
}

export interface Team {
	id: number;
	name: string;
	organizationId: number;
	teamMembers: TeamMember[];
}

export interface RecommendationMetrics {
	templateSectionCount: number;
	matchedSections: string[];
	unMatchedSections: string[];
	extraSections: string[];
	percentage: string;
}

export interface FindDocumentsPayload {
	proposalId?: number;
	sectionId?: number;
}

export interface ProposalTemplate {
	id: number;
	name: string;
	sections: SectionBuilder[];
}

export interface ProposalTemplateResponse {
	proposal: ProposalTemplate;
}

export interface ToneOfVoiceFormData {
	analytical: number;
	authoritative: number;
	concise: number;
	emotive: number;
	sampleText: string;
	writingStyle: {
		name: string;
		exampleText: string;
	};
	citationFormat: string;
}

export type ToneOfVoiceEditType =
	| 'organization'
	| 'proposal'
	| 'organizationSetup';

export interface ToneOfVoiceViewProps {
	type: ToneOfVoiceEditType;
	toneOfVoice?: ToneOfVoice;
	onEditTone?: () => void;
}

export interface ToneOfVoiceEditProps {
	type: ToneOfVoiceEditType;
	setIsLoading?: (isLoading: boolean) => void;
	onCancel?: () => void;
	onSubmit?: (toneOfVoiceData: ToneOfVoiceFormData) => void;
	toneOfVoice?: ToneOfVoice;
	uploadedDocumentTone?: ToneOfVoice;
	initialSetup?: boolean;
}

export interface ChangeToneProps {
	type: ToneOfVoiceEditType;
	toneOfVoice: ToneOfVoice;
	onSave?: (toneOfVoiceData: ToneOfVoice) => void;
	organizationId?: number;
	proposalId?: number;
	uploadedDocumentTone?: ToneOfVoice;
	setDocumentToneEnable?: boolean;
}

export interface NameEditor {
	title: string;
	label?: string;
	description?: string;
	placeholder?: string;
	existingName: string;
	maxLength?: number;
	onConfirm?: (updatedName: string) => void;
	confirmText?: string;
}

export interface MoveDocuments {
	documentIds?: number[];
	folderIds?: number[];
	foldersPath: Folder[];
}

export type Dialog = {
	type: DialogTypes;
	rfp?: RFP;
	addDocumentType?: AddDocumentsTypes;
	uploadDocumentType?: AddDocumentsTypes;
	viewProposalType?: ViewProposalTypes;
	changePhoneType?: ChangePhoneTypes;
	changeToneProps?: ChangeToneProps;
	ideaId?: number;
	documentId?: number;
	documentName?: string;
	documentCategory?: string;
	selectedTemplate?: Template;
	complianceRuleId?: number;
	complianceQuery?: string;
	suggestion?: Suggestion;
	historyIndex?: number;
	triggerToolbarMagic?: boolean;
	editSectionID?: string | null;
	selectedTemplateId?: string;
	duplicateSection?: boolean;
	privateTemplate?: boolean;
	sectionCreatedByUser?: boolean;
	sectionDialogStep?: string;
	templateTagType?: string;
	data?: any;
	solicitationID?: number;
	notificationObj?: Notification;
	section?: NodeData;
	complianceMode?: 'section' | 'proposal';
	loginResponse?: LoginResponse;
	nameEditorObj?: NameEditor;
	moveDocuments?: MoveDocuments;
	folder?: Folder;
	tableCode?: string;
};

export type Status = 'active' | 'inactive';

export interface Folder {
	id: number;
	name: string;
	status: Status;
}

export interface DecDocument {
	_id: string;
	title: string;
	summary: string;
	publicationDate: string;
	tags: string[];
	geographicLocations: string[];
	documentType: string;
	documentId: string;
	primarySubject: string;
	documentLink: string;
	contentType: string;
	isImported: boolean;
}

export type QuestionBuilderItem = {
	id: string;
	text: string;
	wordCount: number;
	status: ProcessingStatus;
	section?: SectionBuilder;
};
