import ComplianceMatrixPanel from './ComplianceMatrixPanel/ComplianceMatrixPanel';
import ExternalDocumentsPanel from './ExternalDocumentsPanel/ExternalDocumentsPanel';
import ReadDocumentPanel from './ReadDocumentPanel/ReadDocumentPanel';
import TemplateBuilderPanel from './TemplateBuilderPanel/TemplateBuilderPanel';

const Panels = () => {
	return (
		<>
			<ComplianceMatrixPanel />
			<ExternalDocumentsPanel />
			<ReadDocumentPanel />
			<TemplateBuilderPanel />
		</>
	);
};

export default Panels;
