import { MutableRefObject } from 'react';
import {
	DocumentJourneyTypes,
	Folder,
	Question,
	Team,
	User,
	UserStatus,
} from './apiTypes';

export const ItemTypes = {
	NOTE: 'NOTE',
	CHAT: 'CHAT',
	IDEA: 'IDEA',
	DOCUMENT: 'DOCUMENT',
};
export type NoteCard = {
	type: 'note';
	content: string;
};

export type ChatCard = {
	type: 'comments';
	content: string[];
};
export type IdeaCard = {
	type: 'idea';
	content: string;
};
export type DocumentCard = {
	type: 'document';
	content: string;
};
export type Card = NoteCard | IdeaCard | DocumentCard | ChatCard;
export type IDragItem = {
	type: string;
	index: number;
};

export const docName = 'USAID TEST';

export const docType = 'rfpDocument';
export interface EventType {
	userId: number;
	eventType: string;
	data: {
		sectionId: number;
		token: null;
	};
}
export interface CKEditorsProps {
	onWordCountChange: (wordCount: number) => void;
	onCharacterCountChange?: (charCount: number) => void;
	placeholder: string;
	editorState: string;
	setIsEditorFocused: (isFocused: boolean) => void;
	editorRef: MutableRefObject<any>;
	isStreaming: boolean;
	proposalId: number;
	sectionId: number;
	setIsSaving: (isSaving: boolean) => void;
	onReady?: () => void;
	stopAutoSave?: boolean;
}
export interface EditorAction {
	type: 'INIT' | 'EDIT' | 'SAVE' | 'CANCEL' | 'UPDATE_TEXT' | 'APPENED_TEXT';
	index: number;
	questions?: Question[];
	newText?: string;
}
export interface EditorState {
	originalQuestionText: string;
	editedQuestionText: string;
	isEditing: boolean;
	isWordCountValid: boolean;
	limitExceeded: boolean;
}
export interface QuestionEditorProps {
	placeholder: string;
	value?: string;
	isEditable?: boolean;
	isStreaming?: boolean;
	onChange: (e: React.FormEvent<HTMLDivElement>) => void;
	onCancel?: () => void;
	onSave?: () => void;
	onPaste: (e: React.ClipboardEvent<HTMLDivElement>) => void;
	onEditEditor?: () => void;
	handleSuggestResponse?: () => void;
	generatingResponse?: boolean;
	canSuggest?: boolean;
	className: string;
	isSaveDisabled: boolean;
}
export interface SolicitationProps {
	id: number;
	name: string;
	status: string;
	metaData: {
		agency: string;
	};
	isSolicited?: boolean;
	createdAt: string;
	proposalExists?: boolean;
	category?: DocumentJourneyTypes;
}
export interface ProposalTableProps {
	id: number;
	name: string;
	rfpTitle: string;
	deadline: string;
	progress: number;
	rfpCode: string;
	createdAt: string;
	isFavourite: boolean;
	isArchived: boolean;
	allUsers: User[];
	createdBy: number;
	teams: Team[];
	rfpDocument: {
		category: DocumentJourneyTypes;
	};
}
export interface UsersTableProps {
	id: number;
	name: string;
	profilePicture?: string;
	email: string;
	designation: string;
	isTwoFactorEnabled: boolean;
	templateBuilderAccess: boolean;
	status: UserStatus;
	roleId: number;
}
export interface DocumentTableProps {
	documentId: number;
	name: string;
	category: string;
}

export interface UploadedDocumentsTableProps {
	path: string;
	name: string;
}

export interface UseAuthType {
	isAdmin: boolean;
	isMember: boolean;
	isGuest: boolean;
	isAuthenticated: boolean;
	hasTemplateBuilderAccess: boolean;
}
export interface AuditLogsTableProps {
	id: number;
	userId: number;
	organizationId?: number;
	email: string;
	event: string;
	ipAddress: string;
	status: string;
	userAgent: string;
	createdAt: string;
	category: string;
}
export interface PendingRequestsTableProps {
	id: number;
	email: string;
	roleId: number;
	expiresAt: string;
	createdAt: string;
	updatedAt: string;
}

export interface IntermediatePageProps {
	mode: 'editTemplate';
	scope: string[];
	state?: any;
	onCancel?: (state?: any) => void;
	onCompletion?: (state?: any) => void;
}
export interface SolicitationsFiltersProps {
	type: SolicitationsFiltersType;
}
export type SolicitationsFiltersType = 'solicitation' | 'proposal';
export const colorClasses = [
	'bg-slate-400',
	'bg-red-400',
	'bg-green-400',
	'bg-blue-400',
	'bg-yellow-400',
	'bg-purple-400',
	'bg-pink-400',
	'bg-gray-400',
	'bg-teal-400',
	'bg-amber-400',
	'bg-lime-400',
];
export const IMAGE_BLOCK = 'imageBlock';

export type PanelTypes =
	| 'readDocument'
	| 'template'
	| 'compliance'
	| 'externalDocuments';

export type Panel = {
	type: PanelTypes;
	pdf?: ArrayBuffer;
	folder?: Folder;
};

export interface Notification {
	title: string;
	description: string;
	onConfirm?: () => void;
	onCancel?: () => void;
	cancelText?: string;
	confirmText?: string;
	hideCancel?: boolean;
	hideConfirm?: boolean;
}
export type LottieMode = {
	type: 'write' | 'suggestion' | 'addThisIn';
};
export type Page = {
	page: 'dashboard' | 'plan' | 'write';
};

export const ideaCharLimit = 500;

export const externalNodeType = 'sections';

export type DrawerType =
	| 'viewBrainstorm'
	| 'viewComments'
	| 'clipboardIdea'
	| 'assistantMode';

export type Drawer = {
	type: DrawerType;
};

export enum UnsolicitedQuestions {
	one = 'Please provide details about this funder and their priorities',
	two = 'Please provide a short description of the problem you wish to solve for this funder',
	three = 'Who will this program impact?',
	forth = 'How many months or years is this program for?',
}
