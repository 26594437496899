import { FC } from 'react';
import styles from './TopPanel.module.css';
import { cn } from '@/lib/utils';
import { exportProposal } from '@/lib/functions/apiCalls';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { Section } from '@/lib/types/apiTypes';
import Ai from '@/components/ui/icons/new/AI';
import TableContent from '../TableContent/TableContent';
import { scrollToSection } from '@/lib/functions/funcUtils';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '../ui/tooltip';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import ExportDownloadButtons from '../ExportDownloadButtons/ExportDownloadButtons';
import SecondaryButton from '../ui/shared/Button/SecondaryButton/SecondaryButton';
import ButtonPulse from '../ButtonPulse/ButtonPulse';
import { openDrawer } from '@/redux/slices/pageSlice';

interface TopPanelProps {
	sections: Section[];
	scrollParentRef?: React.RefObject<HTMLDivElement>;
	mode?: 'logicalframe';
}

const TopPanel: FC<TopPanelProps> = ({ sections, scrollParentRef, mode }) => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const isStreaming = useSelector((root: RootState) => root.page.isStreaming);
	const proposal = useSelector((root: RootState) => root.proposal.proposal);

	const navigate = useNavigate();

	const handleExport = async (type?: 'pdf' | 'docx' | 'print') => {
		dispatch(processStart());
		const response = await exportProposal(
			Number(id),
			type,
			mode === 'logicalframe' ? true : false
		);
		const blob = new Blob([response as ArrayBuffer], {
			type:
				type === 'docx'
					? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
					: 'application/pdf',
		});
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download =
			type === 'docx' ? `${proposal?.name}.docx` : `${proposal?.name}.pdf`;
		link.click();

		dispatch(processSuccess());
	};

	const handleSeeCitations = () => {
		if (isStreaming) {
			return;
		}
		scrollToSection('citations', scrollParentRef);
	};

	return (
		<div
			className={cn(styles.top_panel, 'grid grid-cols-2 gap-2 relative z-10')}
		>
			<div className="flex items-center ">
				{mode !== 'logicalframe' ? (
					<>
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger
									onClick={(e) => e.stopPropagation()}
									className="section-outline"
								>
									<TableContent
										sections={sections}
										scrollParentRef={scrollParentRef && scrollParentRef}
									/>
								</TooltipTrigger>
								<TooltipContent className="text-black bg-white rounded-2xl dynamic-small">
									<p>Outline</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>

						<div className="mx-8 w-[1px] h-8 bg-[#E3EEFF]"> </div>
					</>
				) : null}
				<ul className="flex items-center justify-start gap-6 export">
					<ExportDownloadButtons
						handlePdfExport={() => handleExport('pdf')}
						handleWordExport={() => handleExport('docx')}
					/>
				</ul>
			</div>
			{mode !== 'logicalframe' ? (
				<div className="flex items-center justify-end ">
					<TextButton onClick={handleSeeCitations} className="citations">
						see citations
					</TextButton>
					<div className="flex pl-4 2xl:hidden">
						<SecondaryButton
							onClick={() => dispatch(openDrawer({ type: 'assistantMode' }))}
							className="relative px-4 group "
						>
							<Ai />
							Assistant
							<ButtonPulse type="secondary" />
						</SecondaryButton>
					</div>
				</div>
			) : null}
			{mode === 'logicalframe' && (
				<div className="flex items-center justify-end ">
					<PrimaryButton onClick={() => navigate(`/proposal/${id}`)}>
						Save and Close
					</PrimaryButton>
				</div>
			)}
		</div>
	);
};

export default TopPanel;
