import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { editProposalTemplate } from '@/lib/functions/apiCalls';
import { parseSectionsToNodes } from '@/lib/functions/designGraph';
import { ErrorHandle, fetchProposal } from '@/lib/functions/funcUtils';
import { updateTemplateBuilderLevels } from '@/lib/functions/TemplateBuilder/templateUtils';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import useProposalCategory from '@/lib/hooks/useProposalCategory';
import useProposalId from '@/lib/hooks/useProposalId';
import { Proposal } from '@/lib/types/apiTypes';
import {
	SectionBuilder,
	TemplateLibrary,
} from '@/lib/types/TemplateBuilder/templateTypes';
import {
	closePanel,
	openDialog,
	setLastSection,
} from '@/redux/slices/pageSlice';
import {
	processStart,
	processSuccess,
	setProposal,
	setSortedNodes,
} from '@/redux/slices/proposalSlice';

import {
	buildTemplate,
	resetDeletedSections,
	toggleIsTemplateSetup,
	toggleProposalTemplateEdit,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import { RootState } from '@/redux/store/store';
import { Dispatch, FC, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';

interface TemplateDesignerButtonsProps {
	treeData: any[];
	changeNodeDesign: (node: any, cancel?: boolean) => void;
	setSelectedSection: Dispatch<
		SetStateAction<SectionBuilder | null | undefined>
	>;
	toggleSection: Dispatch<SetStateAction<boolean>>;
	form: UseFormReturn<TemplateLibrary, any>;
	onSubmit: (data: TemplateLibrary) => Promise<void>;
	selectedSection: SectionBuilder | null | undefined;
}

const TemplateDesignerButtons: FC<TemplateDesignerButtonsProps> = ({
	selectedSection,
	treeData,
	changeNodeDesign,
	setSelectedSection,
	toggleSection,
	form,
	onSubmit,
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const templateBuilderData = useAppSelector(
		(root: RootState) => root.templates.templateBuilder
	);

	const proposalId = useProposalId();
	const proposalCategory = useProposalCategory();

	const builder = useAppSelector((state: RootState) => state.templates);

	const location = useLocation();

	const isTemplateMode = location.pathname.includes('template');

	const isProposalTemplateEdit = useAppSelector(
		(state: RootState) => state.templates.isProposalTemplateEdit
	);

	const handleUpdateProposalTemplate = async () => {
		try {
			dispatch(processStart());
			const updatedTreeData: any = updateTemplateBuilderLevels(
				templateBuilderData?.sections as SectionBuilder[],
				isTemplateMode
			);
			if (proposalCategory === 'questionnaire') {
				for (let i = 1; i < updatedTreeData.length; i++) {
					updatedTreeData[i].prerequisites = updatedTreeData
						.slice(0, i)
						.map((section) => section.systemName);
					updatedTreeData[i].isPristine = updatedTreeData[i].isNew
						? true
						: false;
				}
			}
			const response = await editProposalTemplate(
				updatedTreeData,
				builder.deleteSections,
				proposalId as number
			);

			if (response.statusCode === 201) {
				dispatch(processSuccess());
				const proposal = (await fetchProposal(Number(proposalId))) as Proposal;
				const proposalWithoutLogicalFramework = {
					...proposal,
					sections: proposal.sections.filter(
						(section) => section.type !== 'logical-framework'
					),
				};
				if (proposalWithoutLogicalFramework) {
					dispatch(
						setSortedNodes(
							parseSectionsToNodes(proposalWithoutLogicalFramework.sections)
						)
					);
					dispatch(
						setLastSection({
							proposalId: proposalWithoutLogicalFramework.id,
							sectionId: proposalWithoutLogicalFramework.sections[0].id,
						})
					);
					navigate(
						`/proposal/${proposalId}/section/${proposalWithoutLogicalFramework.sections[0].id}/design`
					);
					dispatch(setProposal(proposalWithoutLogicalFramework));
				}

				dispatch(resetDeletedSections());
				dispatch(closePanel());
				dispatch(toggleProposalTemplateEdit(false));
			}
		} catch (error) {
			ErrorHandle(dispatch, error);
			console.log('error', error);
		}
	};

	return (
		<div className={`flex gap-5 items-center`}>
			<div
				role="button"
				className="font-medium uppercase text-primary animated-hover-effect"
				onClick={() => {
					if (!isTemplateMode) {
						if (isProposalTemplateEdit) {
							dispatch(
								openDialog({
									type: 'notification',
									notificationObj: {
										title: 'Go Back',
										description:
											'You have unsaved changes to this template. Do you want to leave without saving?',
										onConfirm: () => {
											changeNodeDesign(selectedSection, true);
											setSelectedSection(undefined);
											toggleSection(false);
											dispatch(resetDeletedSections());
											dispatch(buildTemplate(null));
											dispatch(toggleIsTemplateSetup(false));
											dispatch(closePanel());
										},
									},
								})
							);
						} else {
							changeNodeDesign(selectedSection, true);
							setSelectedSection(undefined);
							toggleSection(false);
							dispatch(resetDeletedSections());
							dispatch(buildTemplate(null));
							dispatch(toggleIsTemplateSetup(false));
							dispatch(closePanel());
						}
					} else {
						dispatch(
							openDialog({
								type: 'notification',
								notificationObj: {
									title: 'Go Back',
									description: 'Are you sure you want to go back ?',
									onConfirm: () => {
										changeNodeDesign(selectedSection, true);
										setSelectedSection(undefined);
										toggleSection(false);
										navigate(`/template/edit/${templateBuilderData?._id}`);
									},
								},
							})
						);
					}
				}}
			>
				Cancel
			</div>
			{!isTemplateMode ? (
				<div className="flex gap-3">
					<PrimaryButton
						type="button"
						onClick={() => {
							dispatch(
								openDialog({
									type: 'notification',
									notificationObj: {
										title: 'Save Template',
										description:
											proposalCategory !== 'questionnaire'
												? 'Would you like to save this template to your library for future use?'
												: 'Are you sure you want to save these changes?',
										onConfirm() {
											dispatch(toggleIsTemplateSetup(true));
										},
										onCancel() {
											handleUpdateProposalTemplate();
										},
										cancelText:
											proposalCategory !== 'questionnaire'
												? 'Save for this proposal only'
												: 'Save Changes',
										...(proposalCategory !== 'questionnaire' && {
											confirmText: 'Save this template to library',
										}),
										hideConfirm: proposalCategory === 'questionnaire',
									},
								})
							);
						}}
						disabled={
							treeData && Object.keys(treeData).length === 0
								? true
								: false || !isProposalTemplateEdit
						}
					>
						Save Template
					</PrimaryButton>
				</div>
			) : (
				<PrimaryButton
					type="button"
					disabled={
						treeData && Object.keys(treeData).length === 0 ? true : false
					}
					onClick={() => {
						if (treeData && Object.keys(treeData).length === 0) return;

						dispatch(
							openDialog({
								type: 'notification',
								notificationObj: {
									title: 'Save Template',
									description: 'Are you sure you want to save this template ?',
									onConfirm: () => {
										form.handleSubmit(onSubmit)();
									},
								},
							})
						);
					}}
				>
					Save this template
				</PrimaryButton>
			)}
		</div>
	);
};

export default TemplateDesignerButtons;
