import styles from './DateRangePicker.module.css';
import { cn } from '@/lib/utils';
import { FC, useCallback, useMemo, useState } from 'react';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { format } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import { CalendarDays as CalendarIcon } from 'lucide-react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';

interface DateRangePickerProps {
	date?: DateRange;
	setDate: SelectRangeEventHandler;
	fromDate?: Date;
	toDate?: Date;
	label?: string;
	className?: string;
}

const DateRangePicker: FC<DateRangePickerProps> = ({
	date,
	setDate,
	fromDate,
	toDate,
	label,
	className,
}) => {
	const [year, setYear] = useState(new Date().getFullYear());
	const [month, setMonth] = useState(new Date().getMonth());

	const years = useMemo(() => {
		const currentYear = new Date().getFullYear();
		return Array.from({ length: 21 }, (_, i) => currentYear - 10 + i);
	}, []);

	const handleYearChange = useCallback((selectedYear: string) => {
		setYear(parseInt(selectedYear, 10));
	}, []);

	return (
		<Popover>
			<PopoverTrigger
				className={cn(
					'flex items-center justify-between border border-[#D3DDE2] px-4 rounded-xl h-12',
					!date && 'text-muted-foreground',
					className
				)}
			>
				{date?.from ? (
					date.to ? (
						<>
							{format(date.from, 'dd LLL y')} - {format(date.to, 'dd LLL y')}
						</>
					) : (
						format(date.from, 'dd LLL y')
					)
				) : (
					<span>{label || 'Pick a date'}</span>
				)}
				<CalendarIcon className="ml-4 h-6 w-6 text-primary" />
			</PopoverTrigger>
			<PopoverContent
				className="w-auto p-0 shadow-main bg-white rounded-xl"
				align="end"
			>
				<div className="flex justify-start items-center gap-2 p-3 border-b">
					<p className="text-black dynamic-text font-medium pt-1">
						Select Year:
					</p>
					<Select onValueChange={handleYearChange} value={year.toString()}>
						<SelectTrigger className="border rounded-lg border-blackSecondary bg-white text-black w-32">
							<SelectValue className="Select year">{year}</SelectValue>
						</SelectTrigger>
						<SelectContent className="rounded-xl bg-white text-black">
							{years.map((y) => (
								<SelectItem
									className="cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
									key={y}
									value={y.toString()}
								>
									{y}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				<Calendar
					className={cn(styles.calendar, 'text-black')}
					initialFocus
					mode="range"
					defaultMonth={date?.from}
					month={new Date(year, month)}
					onMonthChange={(date) => {
						setMonth(date.getMonth());
					}}
					selected={date}
					onSelect={setDate}
					numberOfMonths={2}
					fromDate={fromDate}
					toDate={toDate}
				/>
			</PopoverContent>
		</Popover>
	);
};

export default DateRangePicker;
