import CustomThemeNodeContentRenderer from '@/components/TemplateBuilder/TemplateDnd/NodeRenderer';
import { Badge } from '@/components/ui/badge';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import TemplateDialogIcon from '@/components/ui/icons/new/TemplateBuilder/TemplateDialogIcon';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import {
	SectionBuilder,
	TemplateLibrary,
} from '@/lib/types/TemplateBuilder/templateTypes';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import {
	SortableTreeWithExternalManager,
	toggleExpandedForAll,
} from '@nosferatu500/react-sortable-tree';
import { Pencil } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import {
	buildTemplate,
	setEditTemplateId,
	setTemplateMode,
} from '@/redux/slices/TemplateBuilder/templateSlice';
import { useNavigate } from 'react-router';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import { calculateTotalWordCount } from '@/lib/functions/TemplateBuilder/templateUtils';
import styles from './ShowTemplateDialog.module.css';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { ChevronRight } from 'lucide-react';
import { cropText } from '@/lib/functions/funcUtils';
import { cn } from '@/lib/utils';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import { DndContext } from 'react-dnd';
const ShowTemplateDialog = () => {
	const isOpen = useAppSelector(
		(root: RootState) => root.page.dialog?.type === 'showTemplate'
	);
	const editTemplateId = useAppSelector(
		(root: RootState) => root.page.dialog?.selectedTemplateId
	);
	const dispatch = useAppDispatch();
	const isTemplatePrivate = useAppSelector(
		(root: RootState) => root.page.dialog?.privateTemplate
	);
	const templatesData = useAppSelector(
		(root: RootState) => root.templates.templates
	);
	const privateTemplatesData = useAppSelector(
		(root: RootState) => root.templates.privateTemplates
	);

	const [editTemplate, setEditTemplate] = useState<TemplateLibrary | null>(
		null
	);
	const [treeData, setTreeData] = useState<SectionBuilder[]>([]);

	useEffect(() => {
		if (editTemplateId && !isTemplatePrivate && templatesData) {
			const editTemplateData = templatesData.find(
				(value) => value._id === editTemplateId
			);
			if (editTemplateData) {
				setEditTemplate(editTemplateData);
				const expandedTree = toggleExpandedForAll({
					treeData: editTemplateData.sections,
				});
				setTreeData(expandedTree as SectionBuilder[]);
			}
		} else {
			const editTemplateData = privateTemplatesData?.find(
				(value) => value._id === editTemplateId
			);
			if (editTemplateData) {
				setEditTemplate(editTemplateData);
				const expandedTree = toggleExpandedForAll({
					treeData: editTemplateData.sections,
				});
				setTreeData(expandedTree as SectionBuilder[]);
			}
		}
	}, [editTemplateId, isTemplatePrivate, privateTemplatesData, templatesData]);

	const DialogClosed = () => {
		dispatch(closeDialog());
	};
	const navigate = useNavigate();
	const textRef = useRef<HTMLParagraphElement | null>(null);
	const cardRef = useRef<HTMLDivElement | null>(null);
	const [showFullText, setShowFullText] = useState(false);
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
				setShowFullText(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<Dialog open={isOpen}>
			<DialogContent
				hideCloseBtn={false}
				className="flex flex-col h-[650px] 2xl:h-[750px] overflow-hidden max-w-[750px] bg-white p-6"
			>
				<div className="grid grid-cols-12 gap-4">
					<div className="flex justify-start col-span-1">
						<TemplateDialogIcon />
					</div>
					<div className="flex flex-col col-span-10 gap-2">
						<h2 className="px-2 font-bold text-black dynamic-xl-large">
							{editTemplate && editTemplate.name}
						</h2>

						<div className="flex items-center gap-1">
							{editTemplate &&
								editTemplate.tags.slice(0, 3).map((item) => (
									<TooltipProvider key={item}>
										<Tooltip>
											<TooltipTrigger tabIndex={-1}>
												<Badge
													key={item}
													className="items-center justify-center block px-3 bg-white border max-w-40 text-secondary border-secondary"
												>
													<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-small text-nowrap text-ellipsis">
														{cropText(item, 16)}
													</span>
												</Badge>
											</TooltipTrigger>
											<TooltipContent className="bg-white text-[#5D6F79] dynamic-small rounded-2xl font-normal">
												{item}
											</TooltipContent>
										</Tooltip>
									</TooltipProvider>
								))}
							{editTemplate && editTemplate.tags.length > 3 && (
								<Popover>
									<PopoverTrigger onClick={(e) => e.stopPropagation()}>
										<TooltipProvider>
											<Tooltip>
												<TooltipTrigger className="flex items-center justify-center">
													<ChevronRight
														className={cn('text-[#5D6F79]')}
														size={18}
													/>
												</TooltipTrigger>
												<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
													View More Tags
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>
									</PopoverTrigger>
									<PopoverContent
										className="gap-2 py-4 bg-white rounded-md "
										align="start"
									>
										<h2 className="text-[#5D6F79] font-bold mb-3">
											Template Tags
										</h2>
										<div className="flex flex-wrap gap-1">
											{editTemplate &&
												editTemplate.tags.map((item) => (
													<Badge
														key={item}
														className="items-center justify-center block px-3 bg-white border text-secondary border-secondary"
													>
														{item.length > 35 ? (
															<TooltipProvider>
																<Tooltip>
																	<TooltipTrigger
																		tabIndex={-1}
																		className="flex text-left"
																	>
																		<span className="font-medium uppercase text-secondary dynamic-badge">
																			{cropText(item, 35)}
																		</span>
																	</TooltipTrigger>
																	<TooltipContent className="bg-white text-[#5D6F79] font-normal dynamic-small rounded-2xl">
																		{item}
																	</TooltipContent>
																</Tooltip>
															</TooltipProvider>
														) : (
															<span className="flex items-center w-full overflow-hidden font-medium uppercase text-secondary dynamic-badge">
																{item}
															</span>
														)}
													</Badge>
												))}
										</div>
									</PopoverContent>
								</Popover>
							)}
						</div>
					</div>
				</div>
				<div className="bg-[#E9E9E9] h-[1px] border-none mb-3"></div>
				<div className="flex flex-col gap-2 mb-4" ref={cardRef}>
					<h2 className="font-bold text-black dynamic-xl-large">Description</h2>
					<p
						ref={textRef}
						className={`text-sm xl:text-base text-black font-normal ${
							!showFullText ? styles.limitedText : ''
						}`}
					>
						{editTemplate && editTemplate.description}
					</p>
					{editTemplate && editTemplate.description?.length < 300 ? (
						<></>
					) : (
						<>
							{!showFullText ? (
								<TextButton
									className="p-0 text-sm font-bold h-fit 2xl:h-fit w-fit xl:text-sm 2xl:text-base"
									onClick={() => setShowFullText(true)}
								>
									Read More
								</TextButton>
							) : (
								<TextButton
									className="p-0 text-sm font-normal h-fit 2xl:h-fit w-fit xl:text-sm 2xl:text-base"
									onClick={() => setShowFullText(false)}
								>
									Less
								</TextButton>
							)}
						</>
					)}
				</div>
				<div className="h-full bg-[#F5F5F5] flex flex-col rounded-xl px-4 py-4 overflow-hidden">
					<div className="flex flex-row items-center justify-between">
						<h2 className="font-bold text-black dynamic-large">
							Template Sections
						</h2>
						<p className={`dynamic-text text-secondary font-bold`}>
							Total Words{' '}
							{calculateTotalWordCount(
								editTemplate?.sections
							)?.toLocaleString()}
						</p>
					</div>
					<div className="h-full mt-6">
						<DndContext.Consumer>
							{({ dragDropManager }) =>
								!dragDropManager ? undefined : (
									<SortableTreeWithExternalManager
										dragDropManager={dragDropManager}
										treeData={treeData}
										onChange={setTreeData}
										canDrag={() => false}
										dndType={'show-sections-tree'}
										style={{ height: '100%' }}
										maxDepth={0}
										className="font-bold dynamic-text text-primary"
										nodeContentRenderer={(nodeInfo) => (
											<CustomThemeNodeContentRenderer
												{...nodeInfo}
												{...nodeInfo.nodeProps} // Access nodeProps within the callback
												showWordCount={false}
												showTemplate={true}
											/>
										)}
									/>
								)
							}
						</DndContext.Consumer>
					</div>
				</div>
				{isTemplatePrivate && (
					<div className="flex flex-row items-center justify-end gap-2">
						<PrimaryButton
							className="uppercase"
							onClick={() => {
								dispatch(setTemplateMode('edit'));
								dispatch(setEditTemplateId(editTemplate?._id));
								dispatch(buildTemplate(editTemplate));
								DialogClosed();
								navigate(`/template/edit/${editTemplate?._id}`);
							}}
						>
							<Pencil size={18} /> Edit Template
						</PrimaryButton>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default ShowTemplateDialog;
