import { FC, ReactNode } from 'react';
// import { HelpCircleIcon } from 'lucide-react';
// import {
// 	Tooltip,
// 	TooltipContent,
// 	TooltipProvider,
// 	TooltipTrigger,
// } from '@/components/ui/tooltip';

interface PlanCardHeaderProps {
	title: string;
	shortDescription: string;
	info: string;
	icon: ReactNode;
}

const PlanCardHeader: FC<PlanCardHeaderProps> = ({
	title,
	shortDescription,
	// info,
	icon,
}) => {
	return (
		<div className="flex gap-4">
			{icon}
			<div className="flex flex-col">
				<div className="flex gap-2">
					<h2 className="dynamic-large font-bold text-[#6D7D86]">{title}</h2>
					{/* <TooltipProvider delayDuration={50}>
						<Tooltip>
							<TooltipTrigger className="self-start pt-1">
								<HelpCircleIcon className="text-primary" size={16} />
							</TooltipTrigger>
							<TooltipContent className="bg-white text-[#6D7D86] text-xs 2xl:text-sm px-3 2xl:px-5 2xl:py-1 rounded-full border-none shadow-none drop-shadow-effect">
								<p>{info}</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider> */}
				</div>
				<p className="text-black">{shortDescription}</p>
			</div>
		</div>
	);
};

export default PlanCardHeader;
