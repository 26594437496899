import * as React from 'react';
import { Check, ChevronDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from '@/components/ui/command';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import styles from './Multiselect.module.css';
import { X } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import customToast from '@/components/CustomToast/CustomToast';

interface MultiselectProps {
	title?: string;
	options: SelectOptionProp[];
	value?: SelectOptionProp[];
	className?: string;
	optionClassName?: string;
	disabled?: boolean;
	hasChildren?: boolean;
	canAddOption?: boolean;
	isModal?: boolean;
	onSelectedValuesChange: (selectedValues: any[]) => void;
	tbuilderParentSystemName?: string;
}

export interface SelectItemProp {
	value: string;
	label: string;
}

export interface SelectOptionProp {
	value: string;
	label: string;
	children?: SelectItemProp[];
}
const Multiselect: FC<MultiselectProps> = React.forwardRef<
	HTMLButtonElement,
	MultiselectProps
>(
	(
		{
			title = '',
			options,
			className = '',
			optionClassName = '',
			disabled = false,
			hasChildren = false,
			canAddOption = true,
			value,
			isModal,
			onSelectedValuesChange,
			tbuilderParentSystemName = '',
		}: MultiselectProps,
		ref
	) => {
		const [optionsList, setOptionList] = useState(cloneDeep(options));
		const [open, setOpen] = useState(false);
		const [inputText, setInputText] = useState('');
		const [selectedValues, setSelectedValues] = useState<SelectOptionProp[]>(
			value || []
		);

		const handleSelect = (value: any) => {
			//check if tbuilder parent node System Name availabe then can't dselect in case of prerequisites dropdown
			if (
				tbuilderParentSystemName &&
				value.value.trim() === tbuilderParentSystemName
			) {
				return;
			}
			// check if value is already selected
			if (isSelected(value)) {
				setSelectedValues((selectedValues) => {
					const newSelectedValues = selectedValues.filter(
						(option) => option.value !== value.value
					);
					onSelectedValuesChange(newSelectedValues);
					return newSelectedValues;
				});
				return;
			}
			setSelectedValues((selectedValues) => {
				const newSelectedValues = [...selectedValues, value];
				onSelectedValuesChange(newSelectedValues); // call the callback with the new selected values
				return newSelectedValues;
			});
		};
		const isSelected = (value: any) => {
			// check if value is already selected
			return (
				selectedValues.filter((option) => option.value === value.value).length >
				0
			);
		};
		const clearFilters = () => {
			setSelectedValues([]);
			onSelectedValuesChange([]);
		};
		const removeSelectItem = (value) => {
			//check if tbuilder parent node system name availabe then can't remove in case of prerequisites dropdown
			if (
				tbuilderParentSystemName &&
				value.value.trim() === tbuilderParentSystemName
			) {
				return;
			}
			const newSelectedValues = selectedValues.filter(
				(option) => option.value !== value.value
			);
			setSelectedValues(newSelectedValues);
			onSelectedValuesChange(newSelectedValues);
		};

		const onTextValueChange = (value) => {
			setInputText(value);
		};

		const checkIfAlreadyInData = (text) => {
			let dataExist = false;
			optionsList.forEach((optionItem) => {
				if (!hasChildren && optionItem.value === text) {
					dataExist = true;
				}
				optionItem.children?.forEach((subOptionItem) => {
					if (subOptionItem.value === text) {
						dataExist = true;
					}
				});
			});
			return dataExist;
		};

		const addOption = (optionValue: string) => {
			if (checkIfAlreadyInData(optionValue)) {
				customToast.warning({
					title: 'Added input is already part of Options',
				});
				return;
			}
			const selectItem: SelectItemProp = {
				value: optionValue,
				label: optionValue,
			};
			if (hasChildren) {
				const insertOptionItem = (
					optionItem: SelectOptionProp,
					selectItem: SelectItemProp
				) => {
					optionItem.children = optionItem.children
						? optionItem.children.concat(selectItem)
						: [selectItem];
				};
				const parentOptionItem = optionsList.find(
					(optionItem) => optionItem.value === 'Others'
				);
				if (parentOptionItem) {
					insertOptionItem(parentOptionItem, selectItem);
				} else {
					const parentOptionItem: SelectOptionProp = {
						value: 'Others',
						label: 'Others',
					};
					optionsList.push(parentOptionItem);
					insertOptionItem(parentOptionItem, selectItem);
				}
				setOptionList(optionsList);
			} else {
				optionsList.push(selectItem);
				setOptionList(optionsList);
			}
			return selectItem;
		};

		const addOptionAndSelect = (optionValue: string) => {
			const addedOption = addOption(optionValue);
			if (addedOption) {
				handleSelect(addedOption);
			}
		};

		const getLabelByValue = (optionValue: string): string => {
			return options.reduce((acc, option) => {
				if (acc) return acc;
				if (option.value === optionValue) return option.label;
				if (option.children) {
					const childLabel = option.children.reduce((childAcc, childOption) => {
						if (childAcc) return childAcc;
						if (childOption.value === optionValue) return childOption.label;
						return '';
					}, '');
					if (childLabel) return childLabel;
				}
				return '';
			}, '');
		};

		useEffect(() => {
			if (options) {
				setOptionList(cloneDeep(options));
			}
		}, [options]);

		useEffect(() => {
			const selectedValue = value || [];
			setSelectedValues(selectedValue);
			const newOptions = selectedValue.filter(
				(optionItem) => !checkIfAlreadyInData(optionItem.value)
			);
			newOptions.forEach((newOption) => {
				addOption(newOption.value);
			});
		}, [value]);

		return (
			<Popover open={open} onOpenChange={setOpen} modal={isModal}>
				<div>
					<PopoverTrigger
						asChild
						disabled={disabled}
						onClick={() => {
							setInputText('');
							setTimeout(() => (document.body.style.pointerEvents = ''), 0);
						}}
					>
						<Button
							variant={'outline'}
							className={cn(
								styles.multiselect,
								'pb-2 border-dashed text-base hover:bg-[#EAF1FC] disabled:cursor-ga disabled:bg-designBackground ',
								disabled ? 'inactive !pointer-events-auto' : '',
								className
							)}
							ref={ref}
						>
							{selectedValues?.length > 0 ? (
								<>
									<Badge className="font-normal lg:hidden">
										{selectedValues.length}
									</Badge>
									<div className="hidden gap-1 space-x-1 lg:flex">
										{selectedValues.length > 999 ? (
											<Badge
												className={cn(
													'rounded-full border border-primary bg-[#F2F5FF] text-primary font-normal gap-1 px-2 py-1',
													optionClassName
												)}
												onClick={clearFilters}
											>
												{selectedValues.length} selected
												<X className="w-4 h-4" />
											</Badge>
										) : (
											selectedValues
												.filter((option) => selectedValues.includes(option))
												.reverse()
												.map((option) => (
													<Badge
														onClick={() => removeSelectItem(option)}
														key={option.value}
														className={cn(
															'rounded-full border border-primary bg-[#F2F5FF] text-primary font-normal gap-1 px-2 py-1',
															optionClassName,
															!!tbuilderParentSystemName &&
																option.value.trim() ===
																	tbuilderParentSystemName &&
																'opacity-50'
														)}
													>
														{option.label}
														<X className="w-4 h-4" />
													</Badge>
												))
										)}
									</div>
								</>
							) : (
								<p className="text-base text-black leading-[30px] font-normal opacity-80">
									{title}
								</p>
							)}
							<ChevronDown className="min-w-6 self-center" />
						</Button>
					</PopoverTrigger>
				</div>
				<PopoverContent
					className={cn('w-full py-2 px-0 bg-white rounded-2xl')}
					align="start"
				>
					<Command
						className={cn(styles.content, '')}
						filter={(value, search) => {
							const label = getLabelByValue(value);
							return value.toLowerCase().includes(search.toLowerCase()) ||
								label.toLowerCase().includes(search.toLowerCase())
								? 1
								: 0;
						}}
					>
						<CommandInput
							onValueChange={(text) => onTextValueChange(text)}
							className="text-base text-black"
							placeholder={
								canAddOption ? 'Search or add option...' : 'Search...'
							}
						/>
						<CommandEmpty className="px-8 pt-2 text-base text-black">
							No option found.
						</CommandEmpty>
						<div className="overflow-auto max-h-48">
							{canAddOption && !!inputText && (
								<div className="p-1">
									<div
										className="px-3 py-1.5 text-base font-normal text-black cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
										onClick={() => addOptionAndSelect(inputText)}
									>
										Add "{inputText}"
									</div>
								</div>
							)}
							{hasChildren ? (
								<>
									{optionsList.map((option) => {
										return (
											<CommandGroup
												key={option.value}
												className={cn(styles.group, 'text-black')}
												heading={option.label}
											>
												{option?.children?.map((optionItem) => (
													<CommandItem
														className="text-base font-normal text-black cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary"
														key={optionItem.value}
														value={optionItem.value}
														title={optionItem.value}
														onSelect={() => handleSelect(optionItem)}
													>
														<Check
															className={cn(
																'mr-2 h-4 w-4',
																isSelected(optionItem)
																	? 'opacity-100'
																	: 'opacity-0'
															)}
														/>
														{optionItem.label}
													</CommandItem>
												))}
											</CommandGroup>
										);
									})}
								</>
							) : (
								<CommandGroup>
									{optionsList.map((optionItem) => (
										<CommandItem
											className="text-black text-sm xl:text-base cursor-pointer rounded-lg hover:bg-[#E3EEFF] hover:text-primary"
											key={optionItem.value}
											value={optionItem.value}
											title={optionItem.value}
											onSelect={() => handleSelect(optionItem)}
											disabled={
												!!tbuilderParentSystemName &&
												optionItem.value.trim() === tbuilderParentSystemName
											}
										>
											<Check
												className={cn(
													'mr-2 h-4 w-4',
													isSelected(optionItem) ? 'opacity-100' : 'opacity-0'
												)}
											/>
											{optionItem.label}
										</CommandItem>
									))}
								</CommandGroup>
							)}
						</div>
					</Command>
				</PopoverContent>
			</Popover>
		);
	}
);
Multiselect.displayName = 'Multiselect';

export { Multiselect };
