import { useEffect, useState } from 'react';
import styles from './DashboardStatsCard.module.css';
import { cn } from '@/lib/utils';
import { getProposalsStats } from '@/lib/functions/apiCalls';
import { Variants } from 'framer-motion';

interface DashboardStats {
	totalProposals: number;
	totalActiveProposals: number;
	animationStart?: (startTime) => Variants;
}

const DashboardStatsCard = () => {
	const [stats, setStats] = useState<DashboardStats>();

	useEffect(() => {
		const fetchStats = async () => {
			const response = await getProposalsStats();
			if (response.statusCode === 200) {
				setStats(response.data as DashboardStats);
			}
		};
		fetchStats();
	}, []);
	return (
		<div
			className={cn(
				styles.card,
				'flex flex-col xl:flex-row xl:items-center justify-between mb-3 shadow-main proposals-overview'
			)}
		>
			<h1 className="font-bold text-black dynamic-xl-large">Overview</h1>
			<div className="grid grid-cols-2 gap-4">
				<div
					className={cn(
						'flex items-center justify-between gap-4',
						styles.stat_card
					)}
				>
					<p className="font-bold text-secondary">
						{(stats?.totalActiveProposals || 0).toLocaleString()}
					</p>
					<p className="text-black dynamic-text">Proposal</p>
				</div>
				<div
					className={cn(
						'flex items-center justify-between gap-4',
						styles.stat_card
					)}
				>
					<p className="font-bold text-secondary">
						{(stats?.totalProposals || 0).toLocaleString()}
					</p>
					<p className="text-black dynamic-text">Total Proposal</p>
				</div>
			</div>
		</div>
	);
};

export default DashboardStatsCard;
