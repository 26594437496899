import { FC } from 'react';
import SideBarMenuItem from '../SideBarMenuItem/SideBarMenuItem';
import ProfileImage from '../Logo/ProfileImage/ProfileImage';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import useAuth from '@/lib/hooks/useAuth';
import { cn } from '@/lib/utils';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { startTour } from '@/redux/slices/tourSlice';
import { useSearchParams } from 'react-router-dom';

interface SettingsMenuProps {}

const SettingsMenu: FC<SettingsMenuProps> = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { isAdmin } = useAuth();
	const isStreaming = useSelector((root: RootState) => root.page.isStreaming);
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const isQuestionnaire =
		useSelector((root: RootState) => root.solicitation?.rfp?.category) ===
			'questionnaire' && location.pathname.includes('/create');
	const isAccountPage = location.pathname === '/account';
	const isToneOfVoicePage = location.pathname.endsWith('/manage/toneofvoice');
	const isAuditLogsPage = location.pathname.endsWith('/manage/auditlogs');
	const isTemplateBuilderNew = location.pathname.endsWith('/template/new');
	const isTemplateBuilderEditPage =
		location.pathname.includes('/template/edit');
	const isArchives =
		location.pathname === '/solicitations' &&
		searchParams.get('tab') === 'archives';
	const isFavorites =
		location.pathname === '/solicitations' &&
		searchParams.get('tab') === 'favorites';

	const trailBanner = useSelector((root: RootState) => root.page.trialBanner);

	return (
		<nav className="flex flex-col justify-end bottom-left-menu">
			<ul className="flex flex-col items-center justify-center gap-2 ">
				<div
					hidden={
						isAccountPage ||
						isToneOfVoicePage ||
						isAuditLogsPage ||
						isTemplateBuilderNew ||
						isTemplateBuilderEditPage ||
						isArchives ||
						isFavorites ||
						isQuestionnaire
					}
				>
					<TooltipProvider delayDuration={50}>
						<Tooltip>
							<TooltipTrigger
								className={isStreaming ? 'cursor-lock' : 'help-button'}
								disabled={isStreaming}
							>
								<SideBarMenuItem
									link={'#'}
									onClick={() => {
										if (trailBanner) {
											setTimeout(() => {
												dispatch(startTour());
											}, 1000);
										} else {
											dispatch(startTour());
										}
									}}
									name={'help'}
									className={`${isStreaming ? 'pointer-events-none ' : ''}`}
								/>
							</TooltipTrigger>
							<TooltipContent
								side="right"
								hidden={isStreaming}
								className="bg-white  text-[#6D7D86] dynamic-small py-1 px-2 rounded-full border-none shadow-none drop-shadow-effect"
							>
								<p>Help</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
				<div hidden={!isAdmin}>
					<TooltipProvider delayDuration={50}>
						<Tooltip>
							<TooltipTrigger
								className={isStreaming ? 'cursor-lock' : ''}
								disabled={isStreaming}
							>
								<SideBarMenuItem
									link={'/manage/organization'}
									name={'setting'}
									className={`${isStreaming ? 'pointer-events-none' : ''}`}
								/>
							</TooltipTrigger>
							<TooltipContent
								side="right"
								hidden={isStreaming}
								className="bg-white  text-[#6D7D86] dynamic-small py-1 px-2 rounded-full border-none shadow-none drop-shadow-effect"
							>
								<p>Settings</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</ul>
			<div className="flex flex-col pt-4 mt-4 border border-t-2 border-b-0 border-l-0 border-r-0">
				<TooltipProvider delayDuration={50}>
					<Tooltip>
						<TooltipTrigger
							onClick={() => navigate('/account')}
							className={cn(
								'place-self-center ',
								isStreaming ? 'cursor-lock' : ''
							)}
							disabled={isStreaming}
						>
							<ProfileImage
								className={`${
									isStreaming
										? 'cursor-lock pointer-events-none'
										: 'cursor-pointer'
								}`}
							/>
						</TooltipTrigger>
						<TooltipContent
							side="right"
							hidden={isStreaming}
							className="bg-white  text-[#6D7D86] dynamic-small py-1 px-2 rounded-full border-none shadow-none drop-shadow-effect"
						>
							<p>Account</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
		</nav>
	);
};

export default SettingsMenu;
