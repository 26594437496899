import { FC } from 'react';
import styles from './DashboardProposalListItem.module.css';
import { cn } from '@/lib/utils';
import { ProposalTableProps } from '@/lib/types/constants';

import UserAvatarList from '@/components/UserAvatarList/UserAvatarList';
import { clearProposal } from '@/redux/slices/proposalSlice';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { useNavigate } from 'react-router';
import { resetChartData } from '@/redux/slices/chartSlice';
import { setLastSection, setLastVisitPage } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import ProposalAssignmentDrawer from '@/components/Drawers/ProposalAssignmentDrawer/ProposalAssignmentDrawer';
import { Team } from '@/lib/types/apiTypes';
import { resetTemplateBuilder } from '@/redux/slices/TemplateBuilder/templateSlice';
import { useActiveSection } from '@/lib/hooks/useActiveSection';
import { clearEnhanceSlice } from '@/redux/slices/enhanceSlice';
import ProposalCategory from '@/components/ProposalCategory/ProposalCategory';
// import { fetchComplianceMatrix } from '@/redux/slices/complianceMatrixSlice';

interface DashboardProposalListItemProps {
	card: ProposalTableProps;
	teams: Team[];
}

const DashboardProposalListItem: FC<DashboardProposalListItemProps> = ({
	card,
	teams,
}) => {
	const navigate = useNavigate();
	const activeProposal = useAppSelector(
		(root: RootState) => root.proposal.proposal
	);

	const { resetActiveSection } = useActiveSection();
	const dispatch = useAppDispatch();
	const handleProposal = async () => {
		dispatch(clearProposal());
		dispatch(resetTemplateBuilder());
		dispatch(resetChartData());
		resetActiveSection();
		dispatch(clearEnhanceSlice());
		dispatch(setLastSection({ proposalId: null, sectionId: null }));
		if (!activeProposal || activeProposal?.id !== card.id) {
			navigate(`/proposal/${card.id}`);
			dispatch(setLastVisitPage({ page: 'dashboard' }));
		}
	};

	const assignedUsers = card.allUsers
		.filter((user) => user.isAssigned)
		?.slice(0, 4);
	const assignedTeams = card.teams || [];

	return (
		<div
			className={cn(
				styles.card,
				'flex flex-col gap-2 cursor-pointer hover:bg-[#EAF1FC]'
			)}
			onClick={handleProposal}
		>
			<div className="flex items-center justify-between gap-3">
				<h3 className="font-bold text-primary ">
					{card?.name ? card.name : 'Untitled Proposal'}
				</h3>
				{/* User Avatar */}
				<div onClick={(event) => event.stopPropagation()}>
					<ProposalAssignmentDrawer
						triggerButton={
							<UserAvatarList
								proposalId={card.id}
								users={assignedUsers.slice(
									0,
									4 - assignedTeams.length > 0 ? 4 - assignedTeams.length : 0
								)}
								allUsers={card.allUsers}
								teams={assignedTeams.slice(0, 4)}
								additionalCount={
									assignedUsers.length + assignedTeams.length - 4 > 0
										? assignedUsers.length + assignedTeams.length - 4
										: 0
								}
								createdBy={card.createdBy}
								showAssignPopover={false}
							/>
						}
						proposalId={card.id}
						users={card.allUsers}
						teams={teams}
						assignedUsers={assignedUsers.map((user) => user.id)}
						assignedTeams={assignedTeams.map((team) => team.id)}
						createdBy={card.createdBy}
					/>
				</div>
			</div>
			<div className="flex items-center justify-between gap-3">
				<p className="text-black uppercase dynamic-small">{card.rfpTitle}</p>
				<div className="text-black">
					<ProposalCategory category={card.rfpDocument.category} />
				</div>
			</div>
		</div>
	);
};

export default DashboardProposalListItem;
