import { FC, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import styles from './SectionHamburgerDropdown.module.css';
import Menu from '../ui/icons/new/Menu';
import { useParams } from 'react-router-dom';
import { Node } from '@/lib/types/graphTypes';

interface SectionHamburgerDropdownProps {
	sections: Node[] | undefined;
	handleNodeClick: (nodeId: string) => void;
}

const SectionHamburgerDropdown: FC<SectionHamburgerDropdownProps> = ({
	sections,
	handleNodeClick,
}) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { nodeId } = useParams();

	const setPopoverOpen = (event?: React.MouseEvent) => {
		event?.stopPropagation();
		setIsPopoverOpen(true);
	};

	const renderSection = (sec: Node, prefix: string) => {
		return (
			<div key={sec.id}>
				<div
					className="flex items-center p-2 mt-2 mr-2 cursor-pointer hover:bg-lightBackground rounded-xl"
					// key={prefix}
					onClick={() => handleNodeClick(sec.data.id.toString())}
				>
					<h2 className=" text-[#5D9BFD] font-bold flex items-center gap-3 flex-1 ml-2 animated-container-transition text-lg">
						<span
							className={
								'absolute pr-6 border-r-4 border-[#0000] mr-2 min-w-[50px]  ' +
								(nodeId && parseInt(nodeId) === sec.data.id
									? 'border-separater-pink'
									: 'border-separater-grey')
							}
						>
							{prefix}
						</span>{' '}
						{sec.data.label}
					</h2>
				</div>
			</div>
		);
	};

	return (
		<Popover open={isPopoverOpen}>
			<PopoverTrigger
				asChild
				className="flex justify-center shrink-0 w-36 rounded-xl py-1 animated-hover-effect  text-xs font-bold uppercase cursor-pointer bg-[#E3EEFF] text-primary"
			>
				<div>
					<div
						className="flex items-center "
						onClick={(e) => setPopoverOpen(e)}
					>
						<Menu /> Open Outline
					</div>
				</div>
			</PopoverTrigger>
			<PopoverContent
				className={
					styles.popoverCard +
					' relative bg-white w-[400px]  top-4 p-6 rounded-3xl'
				}
				align="start"
				alignOffset={-10}
				sideOffset={10}
				side="bottom"
				onPointerDownOutside={(e) => {
					e.stopPropagation(); // Prevent outside clicks from conflicting with the close button
					setIsPopoverOpen(false);
				}}
			>
				<div className="block max-h-[350px] overflow-y-auto">
					{sections &&
						sections.map((section, index) =>
							renderSection(section, String(index + 1))
						)}
				</div>
			</PopoverContent>
		</Popover>
	);
};

export default SectionHamburgerDropdown;
