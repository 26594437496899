import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './TopHeader.module.css';
import UserAvatarList from '../UserAvatarList/UserAvatarList';
import { ErrorHandle } from '@/lib/functions/funcUtils';
import { cn } from '@/lib/utils';
import {
	// fetchProposalById,
	processStart,
	processSuccess,
} from '@/redux/slices/proposalSlice';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { openDialog, openPanel } from '@/redux/slices/pageSlice';
import { getDocumentFromSource, getTeams } from '@/lib/functions/apiCalls';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { useQuery } from 'react-query';
import { getToken } from '@/lib/functions/funcUtils';
import { getUsers } from '@/lib/functions/apiCalls';
import useProposalId from '@/lib/hooks/useProposalId';
import { cloneDeep } from 'lodash';
import { FileSpreadsheet, PenBox } from 'lucide-react';
import ProposalAssignmentDrawer from '../Drawers/ProposalAssignmentDrawer/ProposalAssignmentDrawer';
import { Team } from '@/lib/types/apiTypes';
import TextButton from '../ui/shared/Button/TextButton/TextButton';

interface TopHeaderProps {
	rfpName: string;
	proposalName: string;
	rfpLink: string;
	containerRef?: React.RefObject<HTMLDivElement>;
}

const TopHeader: FC<TopHeaderProps> = ({ proposalName, containerRef }) => {
	const dispatch = useAppDispatch();
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const headerRef = useRef<HTMLDivElement>(null);

	const proposalId = useProposalId() as number;

	const token = getToken();

	const [hasScrolled, setHasScrolled] = useState(false);

	const { data: usersResponse } = useQuery({
		queryKey: 'users',
		queryFn: () => getUsers(token, 'active'),
		staleTime: 300000,
	});

	const { data: teamsData } = useQuery({
		queryKey: 'teams',
		queryFn: () => getTeams(),
		staleTime: 300000,
		refetchOnWindowFocus: false,
	});

	const usersData = usersResponse?.data as any;
	const allUsers = usersData?.map((user) => {
		return proposal?.assignedUsers.find(
			(assignedUser) => user.id === assignedUser.id
		)
			? { ...user, isAssigned: true }
			: { ...user, isAssigned: false };
	});

	const handleScroll = useCallback(() => {
		const offset = containerRef?.current?.scrollTop || window.scrollY;
		setHasScrolled(offset > 0);
	}, [containerRef]);

	useEffect(() => {
		const scrollContainer = containerRef?.current || window;
		scrollContainer.addEventListener('scroll', handleScroll);
		return () => scrollContainer.removeEventListener('scroll', handleScroll);
	}, [containerRef, handleScroll]);

	const handleClick = async (event) => {
		event.preventDefault();
		dispatch(processStart());
		try {
			if (proposal?.rfpLink) {
				const response = await getDocumentFromSource(proposal?.rfpLink);
				if (response) {
					// setPdfData(response as ArrayBuffer);
					dispatch(
						openPanel({ type: 'readDocument', pdf: response as ArrayBuffer })
					);
					dispatch(processSuccess());
				}
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};

	return (
		<div
			ref={headerRef}
			className={cn(
				styles.header,
				'flex items-center justify-between',
				hasScrolled ? styles.scrolled : null
			)}
		>
			<div className="flex items-center gap-2">
				{proposalName ? (
					<>
						<p className="flex items-center gap-4 font-bold text-black">
							{proposalName}
							<TextButton
								className="flex items-center gap-2 font-bold uppercase cursor-pointer text-primary"
								onClick={() =>
									dispatch(openDialog({ type: 'changeProposalName' }))
								}
							>
								<PenBox /> Edit Proposal Name
							</TextButton>
						</p>

						<div className="border-r border-[#D3DDE2] rounded-lg w-1 h-7"></div>
					</>
				) : (
					<>
						<p className="flex items-center gap-4 font-bold text-black dynamic-medium ">
							{proposal?.rfpTitle}
							<PenBox
								className="cursor-pointer text-primary hover:text-secondary"
								size={16}
								onClick={() =>
									dispatch(openDialog({ type: 'changeProposalName' }))
								}
							/>
						</p>

						<div className="border-r border-[#D3DDE2] rounded-lg w-1 h-7"></div>
					</>
				)}

				<div className="flex justify-between gap-4">
					<TextButton
						onClick={(e) => handleClick(e)}
						tabIndex={0}
						className="flex items-center gap-2 font-bold uppercase cursor-pointer text-primary solicitation-button"
					>
						<FileSpreadsheet />
						Read Solicitation
					</TextButton>
					{/* <TextButton
							tabIndex={0}
							className="flex items-center gap-2 font-bold uppercase cursor-pointer text-primary"
						>
							<FileInput />
							Update Solicitation
						</TextButton> */}
				</div>
			</div>
			<div className="flex items-center gap-4 cursor-pointer">
				<ProposalAssignmentDrawer
					triggerButton={
						<UserAvatarList
							proposalId={proposalId}
							users={
								cloneDeep(
									proposal?.assignedUsers.slice(
										0,
										4 - proposal?.teams.length > 0
											? 4 - proposal?.teams.length
											: 0
									)
								) || []
							}
							teams={proposal?.teams.slice(0, 4)}
							allUsers={allUsers || []}
							additionalCount={
								(proposal && proposal?.assignedUsers?.length - 4) || 0
							}
							createdBy={proposal?.createdBy}
							showAssignPopover={false}
						/>
					}
					proposalId={proposalId}
					users={usersData || []}
					teams={((teamsData?.data as any)?.teams as Team[]) || []}
					assignedUsers={proposal?.assignedUsers.map((user) => user.id)}
					assignedTeams={proposal?.teams.map((team) => team.id)}
					fetchProposalOnAssignment={true}
					createdBy={proposal?.createdBy}
				/>
			</div>
		</div>
	);
};

export default TopHeader;
