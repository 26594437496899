import { FC } from 'react';

interface ButtonPulseProps {
	type: 'primary' | 'secondary';
}

const ButtonPulse: FC<ButtonPulseProps> = ({ type }) => {
	return (
		<span className="absolute flex w-3 h-3 -top-1 -right-1">
			<span
				className={`absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping ${type === 'primary' ? 'bg-primary' : 'bg-secondary'}`}
			></span>
			<span
				className={`relative inline-flex w-3 h-3 rounded-full ${type === 'primary' ? 'bg-primary' : 'bg-secondary'}`}
			></span>
		</span>
	);
};

export default ButtonPulse;
