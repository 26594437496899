import { FC } from 'react';
import styles from './DashboardResourcesCard.module.css';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import Video from '@/components/ui/icons/new/Dashboard/Video';
import { FileDown } from 'lucide-react';
import { cn } from '@/lib/utils';

interface DashboardResourcesCardProps {
	setIsOpen: (val: boolean) => void;
}

const DashboardResourcesCard: FC<DashboardResourcesCardProps> = ({
	setIsOpen,
}) => {
	return (
		<div className={cn(styles.card, ' shadow-main proposals-resources')}>
			<h2 className="font-bold text-black dynamic-large">Resources</h2>
			<p className="text-black">
				Click on the link below for a detailed E-Learning course on how to
				master grant assistant. You can also download a helpful job aid to guide
				you through Grant Assistant.
			</p>
			<div className="flex flex-col gap-3 mt-3 xl:flex-row">
				<SecondaryButton
					onClick={() =>
						window.open(
							'https://ga-nonessential.s3.amazonaws.com/public-docs/job-aid-utilizing-ga.pdf'
						)
					}
				>
					<FileDown />
					Download Job Aid
				</SecondaryButton>
				<PrimaryButton
					onClick={() => setIsOpen(true)}
					className="video-container"
				>
					<Video />
					Getting Started Video
				</PrimaryButton>
			</div>
		</div>
	);
};

export default DashboardResourcesCard;
