import { FC } from 'react';
import styles from './DashboardProposalCard.module.css';
import { Link } from 'react-router-dom';
import DashboardProposalsList from '../DashboardProposalsList/DashboardProposalsList';
import { ProposalTableProps } from '@/lib/types/constants';
import { Variants, motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { Team } from '@/lib/types/apiTypes';

interface DashboardProposalCardProps {
	cards: ProposalTableProps[];
	teams: Team[];
	animationStart?: (startTime) => Variants;
}

const DashboardProposalCard: FC<DashboardProposalCardProps> = ({
	cards,
	teams,
	animationStart,
}) => {
	return (
		<motion.div
			className={cn(styles.card, ' shadow-main proposals-wrapper')}
			variants={animationStart && animationStart(0.8)}
			initial="initial"
			animate="animate"
			exit="exit"
		>
			<div className="flex flex-col justify-between xl:gap-3 xl:items-center xl:flex-row">
				<h1 className="font-bold text-black dynamic-xl-large">
					Jump back into your latest proposals
				</h1>
				<Link
					className="font-bold text-primary animated-hover-effect"
					to="/solicitations"
				>
					See All Proposals
				</Link>
			</div>
			<p className="mt-1 text-black">
				Here are all the proposals you were assigned to:
			</p>
			<DashboardProposalsList cards={cards} teams={teams} />
		</motion.div>
	);
};

export default DashboardProposalCard;
