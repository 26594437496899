import { Suggestion, ConfirmChange, Dialog } from '@/lib/types/apiTypes';
import {
	IntermediatePageProps,
	LottieMode,
	Page,
	Panel,
	Drawer,
} from '@/lib/types/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PageState {
	dialog: Dialog | null;
	panel: Panel | null;
	drawer: Drawer | null;
	isStreaming: boolean;
	wordCount: number;
	writeMode: LottieMode | null;
	lastSection?: {
		sectionId: number | null;
		proposalId: number | null;
	};
	lastPageVisit: Page | null;
	sectionSuggestionsMap: Record<number, Suggestion[]>;
	confirmChange: ConfirmChange | null;
	intermediatePage: IntermediatePageProps | null;
	matrixPanelOpen: boolean;
	trialBanner: boolean;
}

const initialState: PageState = {
	dialog: null,
	panel: null,
	drawer: null,
	isStreaming: false,
	wordCount: 0,
	writeMode: null,
	lastSection: {
		sectionId: null,
		proposalId: null,
	},
	lastPageVisit: null,
	sectionSuggestionsMap: {},
	confirmChange: null,
	intermediatePage: null,
	matrixPanelOpen: false,
	trialBanner: false,
};

const pageSlice = createSlice({
	name: 'page',
	initialState,
	reducers: {
		clearPageState: () => initialState,
		triggerTrialBanner(state, action: PayloadAction<boolean>) {
			state.trialBanner = action.payload;
		},
		setLastVisitPage(state, action: PayloadAction<Page | null>) {
			state.lastPageVisit = action.payload;
		},
		openPanel(state, action: PayloadAction<Panel>) {
			state.panel = action.payload;
			if (action.payload.folder) {
				state.panel.folder = action.payload.folder;
			}
			if (action.payload.pdf) {
				state.panel.pdf = action.payload.pdf;
			}
		},
		openDialog(state, action: PayloadAction<Dialog>) {
			state.dialog = action.payload;
			if (action.payload.rfp) {
				state.dialog.rfp = action.payload.rfp;
			}
			if (action.payload.documentId) {
				state.dialog.documentId = action.payload.documentId;
			}
			if (action.payload.documentName) {
				state.dialog.documentName = action.payload.documentName;
			}
			if (action.payload.documentCategory) {
				state.dialog.documentCategory = action.payload.documentCategory;
			}
			if (action.payload.selectedTemplate) {
				state.dialog.selectedTemplate = action.payload.selectedTemplate;
			}
			if (action.payload.addDocumentType) {
				state.dialog.addDocumentType = action.payload.addDocumentType;
			}
			if (action.payload.complianceRuleId) {
				state.dialog.complianceRuleId = action.payload.complianceRuleId;
			}
			if (action.payload.complianceQuery) {
				state.dialog.complianceQuery = action.payload.complianceQuery;
			}
			if (action.payload.historyIndex) {
				state.dialog.historyIndex = action.payload.historyIndex;
			}
			if (action.payload.changeToneProps) {
				state.dialog.changeToneProps = action.payload.changeToneProps;
			}
			if (action.payload.triggerToolbarMagic) {
				state.dialog.triggerToolbarMagic = action.payload.triggerToolbarMagic;
			}
			if (action.payload.editSectionID) {
				state.dialog.editSectionID = action.payload.editSectionID;
			}
			if (action.payload.sectionDialogStep) {
				state.dialog.sectionDialogStep = action.payload.sectionDialogStep;
			}
			state.dialog.duplicateSection = action.payload.duplicateSection || false;
			state.dialog.sectionCreatedByUser =
				action.payload.sectionCreatedByUser || false;
			if (action.payload.templateTagType) {
				state.dialog.templateTagType = action.payload.templateTagType;
			}
			if (action.payload.notificationObj) {
				state.dialog.notificationObj = action.payload.notificationObj;
			}
			if (action.payload.nameEditorObj) {
				state.dialog.nameEditorObj = action.payload.nameEditorObj;
			}
			if (action.payload.section) {
				state.dialog.section = action.payload.section;
			}
			if (action.payload.complianceMode) {
				state.dialog.complianceMode = action.payload.complianceMode;
			}
			if (action.payload.loginResponse) {
				state.dialog.loginResponse = action.payload.loginResponse;
			}
			if (action.payload.moveDocuments) {
				state.dialog.moveDocuments = action.payload.moveDocuments;
			}
			if (action.payload.folder) {
				state.dialog.folder = action.payload.folder;
			}
			if (action.payload.tableCode) {
				state.dialog.tableCode = action.payload.tableCode;
			}
		},
		closeDialog(state) {
			state.dialog = null;
		},
		closePanel(state) {
			state.panel = null;
		},
		openDrawer(state, action: PayloadAction<Drawer>) {
			state.drawer = action.payload;
		},
		closeDrawer(state) {
			state.drawer = null;
		},
		startStreaming: (state) => {
			state.isStreaming = true;
			state.writeMode = { type: 'write' };
		},
		stopStreaming: (state) => {
			state.isStreaming = false;
			state.writeMode = null;
		},
		startAddInWriteStreaming: (state) => {
			state.isStreaming = true;
			state.writeMode = { type: 'addThisIn' };
		},
		startGeneratingSuggestions: (state) => {
			state.writeMode = { type: 'suggestion' };
		},
		stopGeneratingSuggestions: (state) => {
			state.writeMode = null;
		},
		updateWordCount(state, action: PayloadAction<number>) {
			state.wordCount = action.payload;
		},
		setLastSection(
			state,
			action: PayloadAction<{
				sectionId: number | null;
				proposalId: number | null;
			}>
		) {
			state.lastSection = action.payload;
		},
		setSectionSuggestions(
			state,
			action: PayloadAction<{ sectionId: number; suggestions: Suggestion[] }>
		) {
			const { sectionId, suggestions } = action.payload;
			// Ensure the map is initialized
			if (!state.sectionSuggestionsMap) {
				state.sectionSuggestionsMap = {};
			}

			state.sectionSuggestionsMap[sectionId] = suggestions;
		},
		setConfirmChange(state, action: PayloadAction<ConfirmChange>) {
			state.confirmChange = action.payload;
		},
		clearConfirmChange(state) {
			state.confirmChange = null;
		},
		setIntermediatePage(state, action: PayloadAction<IntermediatePageProps>) {
			state.intermediatePage = action.payload;
		},
		clearIntermediatePage(state) {
			state.intermediatePage = null;
		},
	},
});

export const {
	openDialog,
	closeDialog,
	openPanel,
	closePanel,
	openDrawer,
	closeDrawer,
	startStreaming,
	stopStreaming,
	startAddInWriteStreaming,
	updateWordCount,
	startGeneratingSuggestions,
	stopGeneratingSuggestions,
	setLastSection,
	setSectionSuggestions,
	setLastVisitPage,
	clearPageState,
	setConfirmChange,
	clearConfirmChange,
	setIntermediatePage,
	clearIntermediatePage,
	triggerTrialBanner,
} = pageSlice.actions;
export default pageSlice.reducer;
