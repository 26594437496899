import { FC, useEffect, useRef, useState } from 'react';
import styles from './DocumentCard.module.css';
import { Document, ErrorResponse, Folder } from '@/lib/types/apiTypes';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { processStart, processSuccess } from '@/redux/slices/proposalSlice';
import { getDocumentFromSource } from '@/lib/functions/apiCalls';
import { openDialog, openPanel } from '@/redux/slices/pageSlice';
import {
	handleApiError,
	handleGeneralError,
	showToast,
} from '@/lib/functions/funcUtils';
// import PrimaryButton from '../ui/shared/Button/PrimaryButton/PrimaryButton';
import { cn } from '@/lib/utils';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import {
	BookOpenIcon,
	FileOutputIcon,
	PencilIcon,
	Trash2Icon,
} from 'lucide-react';
import TextButton from '../ui/shared/Button/TextButton/TextButton';
// import {
// 	DropdownMenu,
// 	DropdownMenuCheckboxItem,
// 	DropdownMenuContent,
// 	DropdownMenuTrigger,
// } from '@/components/ui/dropdown-menu';
// import { LucideMoreVertical } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import useAuth from '@/lib/hooks/useAuth';

interface DocumentCardProps {
	documentCard: Document;
	foldersPath: Folder[];
	isSelected: boolean;
	toggleDocumentSelection: (documentId: number) => void;
}

const DocumentCard: FC<DocumentCardProps> = ({
	documentCard,
	foldersPath,
	isSelected,
	toggleDocumentSelection,
}) => {
	const [showFullText, setShowFullText] = useState(false);
	// const [openDropdown, setOpenDropdown] = useState(false);
	const cardRef = useRef<HTMLDivElement | null>(null);
	const textRef = useRef<HTMLParagraphElement | null>(null);
	const dispatch = useAppDispatch();
	const { isGuest } = useAuth();

	const documentName = documentCard?.alias || documentCard?.name;

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
				setShowFullText(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handlePDF = async () => {
		if (!documentCard?.sourceLink) {
			showToast(
				'error',
				'Something went wrong, please reload the page or try again later'
			);
			return;
		}
		dispatch(processStart());
		try {
			const response = await getDocumentFromSource(documentCard?.sourceLink);
			if (response) {
				// setPdfData(response as ArrayBuffer);
				dispatch(
					openPanel({ type: 'readDocument', pdf: response as ArrayBuffer })
				);
				dispatch(processSuccess());
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				handleGeneralError(error);
				dispatch(processSuccess());
			} else if (
				typeof error === 'object' &&
				error !== null &&
				'message' in error
			) {
				const apiError = error as ErrorResponse;
				handleApiError(apiError);
				dispatch(processSuccess());
			}
		}
	};

	const handleDocDelete = () => {
		dispatch(
			openDialog({
				type: 'deleteDocument',
				documentId: documentCard?.id,
				documentName: documentName,
			})
		);
	};

	const getDocumentFileName = (doc: Document) => {
		const fullFileName = doc?.sourceLink?.split('/')?.at(-1) || '';
		const hyphenIndex = fullFileName.indexOf('-');
		return hyphenIndex !== -1
			? fullFileName.substring(hyphenIndex + 1)
			: fullFileName;
	};

	return (
		<div
			className={cn(
				styles.card,
				' flex flex-col rounded-xl p-6 cursor-pointer border h-fit ',
				isSelected ? 'border-primary' : 'border-transparent'
				// !showFullText && 'max-h-fit',
				// !showFullText && 'h-[265px] xl:h-[275px] 2xl:h-[305px]'
			)}
			ref={cardRef}
			onClick={() => toggleDocumentSelection(documentCard.id)}
		>
			<div
				className={cn(
					'flex flex-col',
					!showFullText && 'h-[220px] xl:h-[235px] 2xl:h-[255px]'
				)}
			>
				<div className="flex items-center justify-between w-full gap-6">
					<div className="flex items-center gap-4">
						{documentName.length > 80 ? (
							<TooltipProvider delayDuration={50}>
								<Tooltip>
									<TooltipTrigger>
										<h4 className="text-start text-black font-bold dynamic-medium line-clamp-2">
											{documentName}
										</h4>
									</TooltipTrigger>
									<TooltipContent
										className="bg-white text-black dynamic-small max-w-[450px] px-3 py-2 rounded-lg border-none shadow-none drop-shadow-effect"
										align="start"
										side="bottom"
									>
										{documentName}
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						) : (
							<h4 className="text-start text-black font-bold dynamic-medium line-clamp-2">
								{documentName}
							</h4>
						)}
						{/* {!isGuest && (
						<TextButton
							onClick={(event) => {
								dispatch(
									openDialog({
										type: 'changeDocumentName',
										documentId: documentCard.id,
										documentName: documentName,
									})
								);
								event.stopPropagation();
							}}
							className={cn(styles.options, 'dynamic-small h-5 2xl:h-6')}
						>
							<Pencil size={12} />
							Edit
						</TextButton>
					)} */}
					</div>
					{/* {!isGuest && (
					<div
						className={cn(
							styles.options,
							'text-black',
							openDropdown && '!opacity-100'
						)}
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<DropdownMenu
							open={openDropdown}
							onOpenChange={(state) => setOpenDropdown(state)}
						>
							<DropdownMenuTrigger>
								<LucideMoreVertical
									className="text-primary animated-hover-effect"
									size={18}
								/>
							</DropdownMenuTrigger>
							<DropdownMenuContent
								className={cn(
									'bg-white rounded-2xl p-4 w-60',
									!openDropdown && '!opacity-100'
								)}
								sideOffset={10}
								alignOffset={-5}
								align="end"
							>
								<DropdownMenuCheckboxItem className="text-black px-4 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary capitalize">
								See attached solicitations
							</DropdownMenuCheckboxItem>
								<DropdownMenuCheckboxItem
									className="text-black px-4 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary capitalize"
									onClick={() =>
										dispatch(
											openDialog({
												type: 'changeDocumentName',
												documentId: documentCard.id,
												documentName: documentName,
											})
										)
									}
								>
									Rename Document
								</DropdownMenuCheckboxItem>
								<DropdownMenuCheckboxItem
									className="text-black px-4 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary capitalize"
									onClick={() =>
										dispatch(
											openDialog({
												type: 'moveDocuments',
												moveDocuments: {
													documentIds: [documentCard.id],
													foldersPath: foldersPath,
												},
											})
										)
									}
								>
									Move Document
								</DropdownMenuCheckboxItem>
								<DropdownMenuCheckboxItem className="text-black px-4 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#E3EEFF] hover:text-primary capitalize">
								Change Category
							</DropdownMenuCheckboxItem>
								<div className="my-2 mx-3 border-blackSecondary border-t"></div>
								<DropdownMenuCheckboxItem
									onClick={() => handleDocDelete()}
									className="text-black pl-4 text-sm xl:text-base cursor-pointer rounded-xl hover:bg-[#ff388c] hover:text-white capitalize"
								>
									Delete Document
								</DropdownMenuCheckboxItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				)} */}
				</div>
				<p className="dynamic-small text-lightBlack mb-3 2xl:mb-4">
					{getDocumentFileName(documentCard)}
				</p>
				<p className="flex font-bold text-black items-center gap-2">
					Category:
					<Badge className="dynamic-small font-normal bg-white border-secondary text-secondary p-1 2xl:p-2 leading-none">
						{documentCard?.category}
					</Badge>
					<TextButton
						className={cn(
							styles.options,
							'dynamic-small gap-1 p-0 h-auto 2xl:h-auto'
						)}
						onClick={(event) => {
							dispatch(
								openDialog({
									type: 'changeDocumentCategory',
									documentId: documentCard.id,
									documentCategory: documentCard.category,
								})
							);
							event.stopPropagation();
						}}
					>
						<PencilIcon size={16} />
						Change Category
					</TextButton>
				</p>
				<div className="bg-[#E9E9E9] h-[1px] border-none my-3 xl:my-4"></div>
				<p
					ref={textRef}
					className={`h-fit text-sm xl:text-base text-black font-normal ${
						!showFullText ? styles.limitedText : ''
					}`}
				>
					{documentCard?.summary}
				</p>

				{documentCard?.summary?.length <
				(textRef?.current?.offsetWidth || 300) / 2.5 ? (
					<></>
				) : (
					<div
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						{!showFullText ? (
							<TextButton
								className="h-fit 2xl:h-fit w-fit p-0 text-sm xl:text-sm 2xl:text-base mt-1 font-bold"
								onClick={() => setShowFullText(true)}
							>
								Read More
							</TextButton>
						) : (
							<TextButton
								className="h-fit 2xl:h-fit w-fit p-0 text-sm xl:text-sm 2xl:text-base mt-1 font-normal"
								onClick={() => setShowFullText(false)}
							>
								Less
							</TextButton>
						)}
					</div>
				)}
				{/* <div
				className="flex items-center justify-between pt-4 mt-auto"
				onClick={(event) => {
					event.stopPropagation();
				}}
			>
				<PrimaryButton onClick={handlePDF}>Read Document</PrimaryButton>
			</div> */}
			</div>
			{!isGuest && (
				<div className={cn(styles.btnContainer)}>
					<div
						className="flex items-center gap-2 right-6 border-t pt-3 mt-3 border-blackSecondary hover-element"
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<button
							className="flex font-medium items-center justify-center w-1/4 text-primary gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
							onClick={handlePDF}
						>
							<BookOpenIcon className="w-4 2xl:w-5" color={'var(--primary)'} />
							Read
						</button>
						<button
							className="flex font-medium items-center justify-center w-1/4 text-primary gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
							onClick={() =>
								dispatch(
									openDialog({
										type: 'changeDocumentName',
										documentId: documentCard.id,
										documentName: documentName,
									})
								)
							}
						>
							<PencilIcon className="w-4 2xl:w-5" color={'var(--primary)'} />
							Rename
						</button>
						<button
							className="flex font-medium items-center justify-center w-1/4 text-primary gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
							onClick={() =>
								dispatch(
									openDialog({
										type: 'moveDocuments',
										moveDocuments: {
											documentIds: [documentCard.id],
											foldersPath: foldersPath,
										},
									})
								)
							}
						>
							<FileOutputIcon
								className="w-4 2xl:w-5"
								color={'var(--primary)'}
							/>
							Move
						</button>
						<button
							className="flex font-medium items-center justify-center w-1/4 text-lightRed gap-1 2xl:gap-2 rounded-md py-1 hover:bg-lightBackground cursor-pointer"
							onClick={handleDocDelete}
						>
							<Trash2Icon className="w-4 2xl:w-5" color={'var(--light-red)'} />
							Delete
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default DocumentCard;
