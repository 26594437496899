import { FC, useEffect, useRef, useState } from 'react';
// import { Badge } from '../ui/badge';
import { FileX2 } from 'lucide-react';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
	fetchProposalById,
	processStart,
	processSuccess,
} from '@/redux/slices/proposalSlice';
import { detachFormativeDocument } from '@/lib/functions/apiCalls';
import { cropText, ErrorHandle } from '@/lib/functions/funcUtils';
import { Document } from '@/lib/types/apiTypes';
import styles from './EssentialDocumentListItem.module.css';
import TextButton from '../ui/shared/Button/TextButton/TextButton';

interface EssentialDocumentListitemProps {
	document: Document;
}

const EssentialDocumentListitem: FC<EssentialDocumentListitemProps> = ({
	document,
}) => {
	const dispatch = useAppDispatch();
	const proposal = useSelector((state: RootState) => state.proposal.proposal);
	const textRef = useRef<HTMLParagraphElement>(null);
	const [showReadMore, setShowReadMore] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		if (document.summary?.length > 300) {
			setShowReadMore(true);
		} else {
			setShowReadMore(false);
		}
	}, [document.summary, textRef]);

	const toggleText = () => {
		setIsExpanded(!isExpanded);
	};
	const handleDetachDocument = async (documentId: number) => {
		try {
			if (proposal?.id) {
				dispatch(processStart());
				const response = await detachFormativeDocument(proposal.id, documentId);
				if (response.statusCode >= 400 && response.statusCode <= 599) {
					throw response; // Throw the response if status is 400s or 500s
				}
				if (response.statusCode === 201) {
					dispatch(processSuccess());
					dispatch(fetchProposalById(proposal.id));
				}
			}
		} catch (error: unknown) {
			ErrorHandle(dispatch, error);
		}
	};
	return (
		<li className="flex items-center gap-6 first:pt-0 first:pb-5 py-5 border-b border-[#C6DBFB] group relative">
			<div>
				<h5 className="font-bold text-primary dynamic-medium ">
					{document.name}
				</h5>
				<div
					className={`${styles.textContainer}  ${
						isExpanded ? styles.expanded : showReadMore ? styles.collapsed : ''
					}`}
				>
					<p className="py-2 text-black" ref={textRef}>
						{isExpanded ? document.summary : cropText(document.summary, 300)}
						{showReadMore && (
							<a
								onClick={toggleText}
								className="font-bold cursor-pointer text-start read-more-button text-primary"
							>
								{isExpanded ? (
									<span className="font-normal"> Less</span>
								) : (
									' Read More'
								)}
							</a>
						)}
					</p>
				</div>
				<p className="px-2 py-1 mt-2 text-black rounded-full bg-background w-fit dynamic-small">
					{document?.attachment?.attachedAs || document.category}
				</p>
			</div>
			<div className="flex items-center flex-shrink-0 h-full pl-4">
				<TextButton
					className="flex transition duration-300 ease-in-out opacity-0 text-lightRed group-hover:opacity-100 group-hover:duration-500"
					onClick={() => handleDetachDocument(document.id)}
				>
					<FileX2 size={18} />
					Detach document
				</TextButton>
			</div>
		</li>
	);
};

export default EssentialDocumentListitem;
