import styles from './ProposalDashboard.module.css';
import { cn } from '@/lib/utils';
import { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { motion, useAnimation } from 'framer-motion';
import PlanCard from '@/components/PlanCard/PlanCard';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { useNavigate, useParams } from 'react-router';
import {
	fetchProposalById,
	pageTransitionEnd,
	pageTransitionStart,
	setProposal,
	updateStep,
} from '@/redux/slices/proposalSlice';
import { openDialog, setLastSection } from '@/redux/slices/pageSlice';
import TopHeader from '@/components/TopHeader/TopHeader';
import { useTour } from '@/lib/hooks/useTour';
import { Page } from '@/constants/Onboarding/types';
import EssentialDocumentsCard from '@/components/EssentialDocumentsCard/EssentialDocumentsCard';
import { fetchComplianceMatrix } from '@/redux/requests/compliance';
import {
	FullscreenIcon,
	LayoutDashboardIcon,
	LucideMessageSquareText,
} from 'lucide-react';
import useAuth from '@/lib/hooks/useAuth';
import {
	nextNofo,
	setCurrentStepQuestion,
	setQuestions,
} from '@/redux/slices/questionSlice';

interface ProposalDashboardProps {
	exiting?: boolean;
}
const ProposalDashboard: FC<ProposalDashboardProps> = ({ exiting }) => {
	const proposal = useSelector((root: RootState) => root.proposal.proposal);
	const dispatch = useAppDispatch();
	const lastSectionId = useSelector(
		(root: RootState) => root.page.lastSection?.sectionId
	);

	const { isGuest } = useAuth();

	const navigate = useNavigate();

	const tour = useSelector((root: RootState) => root.tour);

	useTour(Page.ProposalDashboard);

	const control = useAnimation();

	const { id } = useParams();

	const containerRef = useRef<HTMLDivElement>(null);

	const handleProposalCreation = () => {
		if (!proposal) return;
		navigate('/create');
		dispatch(pageTransitionStart());
		dispatch(updateStep(3));
		dispatch(setProposal(proposal));
		dispatch(setQuestions(proposal.questions));
		dispatch(setCurrentStepQuestion(proposal.questions[0]));
		dispatch(nextNofo());
		dispatch(pageTransitionEnd());
	};

	useEffect(() => {
		if (lastSectionId === null && proposal) {
			const firstSectionId = proposal?.sections[0]?.id;
			dispatch(
				setLastSection({
					proposalId: proposal?.id as number,
					sectionId: firstSectionId as number,
				})
			);
			if (proposal?.isJourneyComplete === false && !isGuest) {
				dispatch(
					openDialog({
						type: 'notification',
						notificationObj: {
							title: 'Complete Proposal Setup',
							description:
								'Do you want to continue working on this proposal or complete the setup process now?',
							onConfirm: () => handleProposalCreation(),
							confirmText: 'Complete Setup',
							cancelText: 'Continue',
						},
					})
				);
			}
		}
	}, [lastSectionId, proposal, dispatch]);

	useEffect(() => {
		if (id) {
			dispatch(fetchProposalById(Number(id)));
			dispatch(fetchComplianceMatrix(Number(id)));
		}
	}, [id]);

	const slideInVariant = (delay) => ({
		initial: { opacity: 0, y: '50%' },
		animate: { opacity: 1, y: 0, transition: { duration: 0.5, delay: delay } },
	});

	useEffect(() => {
		if (exiting === true) {
			control.start({ opacity: 0, transition: { duration: 0.25 } });
		}
	}, [exiting, control]);

	return (
		<div className={cn(styles.wrapper)}>
			<motion.div
				ref={containerRef}
				className={`h-full ${!tour.run ? 'overflow-y-auto' : ''} hidden-scroll bg-[#f5f5f5]`}
				animate={control}
			>
				{proposal && (
					<>
						<TopHeader
							rfpLink={proposal.rfpLink}
							rfpName={proposal.rfpTitle}
							proposalName={proposal?.name || ''}
							containerRef={containerRef}
						/>
						<motion.div
							className="relative bg-[#f5f5f5] px-8"
							initial="initial"
							animate="animate"
							exit="exit"
						>
							<div className={cn('grid grid-cols-12 gap-4 pb-4')}>
								<motion.div
									className="col-span-6 xl:col-span-4 2xl:col-span-3"
									variants={slideInVariant(0)}
									initial="initial"
									animate="animate"
									exit="exit"
								>
									<PlanCard
										title={'Welcome to your proposal dashboard.'}
										shortDescription={
											'This gives you a high level overview of your proposal.'
										}
										info={'Info'}
										icon={
											<LayoutDashboardIcon
												size={32}
												strokeWidth={1}
												className="min-w-8 text-secondary"
											/>
										}
										text={proposal?.rfpSummary || ''}
										type={'rfp'}
										tourClass="summary"
									/>
								</motion.div>
								<motion.div
									className="col-span-6 xl:col-span-4 2xl:col-span-5"
									variants={slideInVariant(0.2)}
									initial="initial"
									animate="animate"
									exit="exit"
								>
									<PlanCard
										title={'The Big Picture'}
										shortDescription={
											'This screen gives you a high level overview of your proposal.'
										}
										info={'Info'}
										icon={
											<FullscreenIcon
												strokeWidth={1}
												className="min-w-8 min-h-8 text-secondary"
											/>
										}
										text={proposal?.questions[0].transformedAnswer || ''}
										type={'approach'}
										tourClass="big-picture"
									/>
								</motion.div>
								<motion.div
									className="col-span-6 xl:col-span-4 2xl:col-span-4"
									variants={slideInVariant(0.4)}
									initial="initial"
									animate="animate"
									exit="exit"
								>
									<PlanCard
										title={'Writing style and tone of voice'}
										shortDescription={
											'You can refine the writing style for your proposal.'
										}
										info={'Info'}
										icon={
											<LucideMessageSquareText
												strokeWidth={1}
												className="min-w-8 min-h-8 text-secondary"
											/>
										}
										text={null}
										type={'voice'}
										tourClass="writing-style"
									/>
								</motion.div>
								<motion.div
									className="block col-span-6 xl:hidden"
									variants={slideInVariant(0.6)}
									initial="initial"
									animate="animate"
									exit="exit"
								>
									<EssentialDocumentsCard />
								</motion.div>
							</div>
							<div className={cn('hidden xl:grid grid-cols-12 gap-4')}>
								<motion.div
									className="col-span-12 mb-10"
									variants={slideInVariant(0.6)}
									initial="initial"
									animate="animate"
									exit="exit"
								>
									<EssentialDocumentsCard />
								</motion.div>
							</div>
						</motion.div>
					</>
				)}
			</motion.div>
		</div>
	);
};

export default ProposalDashboard;
