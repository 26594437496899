import { FC, useCallback, useState } from 'react';
import QuestionBuilderListItem from '../QuestionBuilderListItem/QuestionBuilderListItem';
import { QuestionBuilderItem } from '@/lib/types/apiTypes';

interface QuestionBuilderListProps {
	questions: QuestionBuilderItem[];
	editingQuestion: string | null;
	onEdit: (id: string) => void;
	onSave: (questionText: string, wordCount: number) => void;
	onRetry: (questionText: string, wordCount: number, id: string) => void;
	onDelete: (id: string) => void;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
}

const QuestionBuilderList: FC<QuestionBuilderListProps> = ({
	questions,
	editingQuestion,
	onEdit,
	onSave,
	onRetry,
	onDelete,
	moveCard,
}) => {
	const [draggingItemId, setDraggingItemId] = useState<string | null>(null);

	const renderCard = useCallback(
		(question: QuestionBuilderItem, index: number) => {
			return (
				<QuestionBuilderListItem
					key={index}
					question={question}
					index={index}
					isEditing={editingQuestion === question.id}
					editingQuestion={editingQuestion}
					draggingItemId={draggingItemId}
					setDraggingItemId={setDraggingItemId}
					onEdit={onEdit}
					onSave={onSave}
					onRetry={onRetry}
					onDelete={onDelete}
					moveCard={moveCard}
				/>
			);
		},
		[
			questions,
			editingQuestion,
			onEdit,
			onSave,
			onDelete,
			moveCard,
			draggingItemId,
		]
	);

	return (
		<div className="mt-4 max-w-[1400px] mx-auto">
			<ul className="flex flex-col py-2 gap-4">
				{questions.map((question, index) => renderCard(question, index))}
			</ul>
		</div>
	);
};

export default QuestionBuilderList;
