import FileUpload from '@/components/FileUpload/FileUpload';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import PrimaryButton from '@/components/ui/shared/Button/PrimaryButton/PrimaryButton';
import SecondaryButton from '@/components/ui/shared/Button/SecondaryButton/SecondaryButton';
import { handleFileRejections, showToast } from '@/lib/functions/funcUtils';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/hooks';
import { closeDialog } from '@/redux/slices/pageSlice';
import { RootState } from '@/redux/store/store';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import DialogLoader from '../../Loader/DialogLoader/DailogLoader';
import AddDocument from '@/components/ui/icons/new/AddDocument';
import { Doughnut } from 'react-chartjs-2';
import TextButton from '@/components/ui/shared/Button/TextButton/TextButton';
import UploadDocumentsList from '@/components/UploadDocumentsList/UploadDocumentsList';

const doughnutOptions = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: { display: false },
		tooltip: { enabled: false },
	},
};

const MultiDocumentsUploadDialog = () => {
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector(
		(state: RootState) => state.page.dialog?.type === 'uploadDocuments'
	);
	const folder = useAppSelector(
		(state: RootState) => state.page.dialog?.folder
	);
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [uploadedDocuments, setUploadedDocuments] = useState<File[]>([]);
	const [setupStep, setSetupStep] = useState(1);

	const stepChartData = {
		datasets: [
			{
				label: 'Step',
				data: [setupStep, 2 - setupStep],
				backgroundColor: ['#E861C9', '#D3DDE2'],
				borderColor: ['#E861C9', '#D3DDE2'],
				borderWidth: 5,
				cutout: 100,
			},
		],
	};

	const getUniqueDocuments = (docs: File[]) => {
		const uniqueDocumentsMap = new Map();
		docs.forEach((doc) => {
			if (!uniqueDocumentsMap.has(doc.name)) {
				uniqueDocumentsMap.set(doc.name, doc);
			}
		});
		return Array.from(uniqueDocumentsMap.values());
	};

	const handleLoadDocuments = async (newDocuments: File[]) => {
		if (!newDocuments.length) return;
		const updatedDocuments = uploadedDocuments.concat(newDocuments);
		const uniqueDocuments = getUniqueDocuments(updatedDocuments);
		if (updatedDocuments.length > uniqueDocuments.length) {
			showToast(
				'warning',
				'Duplicate document(s) detected. The duplicate document(s) have been removed from the upload. Please ensure each document is unique.'
			);
		} else if (uniqueDocuments.length > 10) {
			showToast(
				'warning',
				'You have exceeded the document upload limit. Please select up to 10 documents for upload.'
			);
		} else {
			setUploadedDocuments(uniqueDocuments);
		}
	};

	const clearDocumentDialog = () => {
		setShowPreview(false);
		setIsLoading(false);
		setSetupStep(1);
		setUploadedDocuments([]);
	};

	useEffect(() => {
		if (!isOpen) {
			clearDocumentDialog();
		}
	}, [isOpen]);

	const renderUploadContainer = () => {
		return (
			<div className="h-64 2xl:h-80 flex flex-col gap-8 justify-center rounded-xl text-black bg-background p-6 items-center border border-primary border-dashed cursor-pointer">
				<AddDocument className="h-24 2xl:h-28" />
				<div className="flex flex-col items-center">
					<h4 className="font-bold">Click or drag documents to upload them</h4>
					<p>You can upload up to 10 documents at the same time</p>
				</div>
			</div>
		);
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent
				className={cn(
					'flex flex-col overflow-hidden bg-white p-6 h-auto 2xl:h-auto gap-6',
					showPreview
						? 'max-w-[900px] 2xl:max-w-[1000px]'
						: 'max-w-[750px] 2xl:max-w-[850px]'
				)}
				hideCloseBtn={true}
			>
				<div className="flex justify-between items-center">
					<h2 className="font-bold text-black dynamic-large">
						Let’s Get Your Documents Loaded Up
					</h2>
					<div className="flex h-8 w-fit gap-4 items-center">
						<h2 className="text-black font-bold dynamic-text">
							Step <span className="text-secondary">{setupStep}</span>/2
						</h2>
						<div className="w-8 h-8">
							<Doughnut
								className="w-8 h-8 pointer-events-none"
								data={stepChartData}
								options={doughnutOptions}
							/>
						</div>
					</div>
				</div>
				{showPreview ? (
					<UploadDocumentsList
						documentsList={uploadedDocuments}
						folder={folder}
					/>
				) : (
					<>
						<div className="flex flex-col gap-2 xl:gap-3">
							<FileUpload
								type="custom"
								onDropAccepted={handleLoadDocuments}
								onDropRejected={(fileRejections) =>
									handleFileRejections({
										fileRejections: fileRejections,
									})
								}
								acceptedExtensions={['.pdf', '.docx']}
								maxSize={20000000}
								customLayout={renderUploadContainer()}
							/>
						</div>
						{!!uploadedDocuments.length && (
							<div className="flex gap-2 text-black font-bold items-center justify-center">
								<span className="dynamic-text">
									{uploadedDocuments.length} Document(s) Added{' '}
								</span>
								<TextButton
									className="h-auto 2xl:h-auto py-0 text-lightRed"
									onClick={() => setUploadedDocuments([])}
								>
									Remove All
								</TextButton>
							</div>
						)}
						<div className="flex gap-4 justify-end">
							<SecondaryButton
								onClick={() => {
									dispatch(closeDialog());
								}}
							>
								Cancel
							</SecondaryButton>
							{showPreview ? (
								<PrimaryButton disabled={!uploadedDocuments.length}>
									ADD these DOCUMENTS to grant assistant
								</PrimaryButton>
							) : (
								<PrimaryButton
									disabled={!uploadedDocuments.length}
									onClick={() => {
										setShowPreview(true);
										setSetupStep(2);
									}}
								>
									Next
								</PrimaryButton>
							)}
						</div>
					</>
				)}
				<DialogLoader isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

export default MultiDocumentsUploadDialog;
