import { FC, useEffect } from 'react';
import styles from './WriteMode.module.css';
import WriteModeWrapper from '@/containers/WriteModeWrapper/WriteModeWrapper';
import WriteModeSidebar from '@/components/WriteModeSidebar/WriteModeSidebar';
import { cn } from '@/lib/utils';
import { useTour } from '@/lib/hooks/useTour';
import { Page } from '@/constants/Onboarding/types';
import { useAppDispatch } from '@/lib/hooks/hooks';
import useProposalId from '@/lib/hooks/useProposalId';
import { fetchProposalById } from '@/redux/slices/proposalSlice';
import { fetchComplianceMatrix } from '@/redux/requests/compliance';

interface WriteModeProps {
	exiting?: boolean;
}

const WriteMode: FC<WriteModeProps> = () => {
	const dispatch = useAppDispatch();
	const proposalId = useProposalId();
	useTour(Page.Write);
	useEffect(() => {
		if (!proposalId) return;
		dispatch(fetchComplianceMatrix(Number(proposalId)));
		dispatch(fetchProposalById(Number(proposalId)));
	}, [proposalId, dispatch]);
	return (
		<div className={cn(styles.wrapper, 'grid grid-cols-12 gap-6')}>
			<WriteModeWrapper />
			<WriteModeSidebar />
		</div>
	);
};

export default WriteMode;
