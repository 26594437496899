import { getSSOUserInfo } from '@/lib/functions/apiCalls';
import { useAppDispatch } from '@/lib/hooks/hooks';
import { User } from '@/lib/types/apiTypes';
import {
	loginFailure,
	loginRequest,
	loginSuccess,
} from '@/redux/slices/authSlice';
import {
	closeDialog,
	closeDrawer,
	closePanel,
	stopStreaming,
} from '@/redux/slices/pageSlice';
import { FC, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import LoaderProcess from '@/components/Loader/LoaderProcess/LoaderProcess';
import LoaderTransparent from '@/components/Loader/LoaderTransparent/LoaderTransparent';
import TransparentAnimatedLoader from '@/components/Loader/TransparentAnimatedLoader/TransparentAnimatedLoader';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { resetChartData } from '@/redux/slices/chartSlice';
import { useIntercom } from 'react-use-intercom';
import TourContainer from '../TourContainer/TourContainer';
import { setTour, stopTour } from '@/redux/slices/tourSlice';
import { useCurrentPage } from '@/lib/hooks/useCurrentPage';
import { processSuccess } from '@/redux/slices/proposalSlice';
import Dialogs from '@/components/Dialogs/Dialogs';
import Drawers from '@/components/Drawers/Drawers';
import Panels from '@/components/Panels/Panels';

interface AppWrapperProps {
	children: ReactNode;
}

const AppWrapper: FC<AppWrapperProps> = ({ children }) => {
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const token = searchParams.get('token');
	const isLoading = useSelector((state: RootState) => state.proposal.isLoading);
	const isTransparentLoading = useSelector(
		(state: RootState) => state.proposal.isTransparentLoading
	);
	const isTransparentAnimatedLoading = useSelector(
		(state: RootState) => state.proposal.isTransparentAnimatedLoading
	);
	const transparentAnimatedType = useSelector(
		(state: RootState) => state.proposal.transparentAnimatedType
	);
	const navigate = useNavigate();
	const user = useSelector((state: RootState) => state.auth.user);
	const { boot, show } = useIntercom();

	const tour = useSelector((state: RootState) => state.tour);

	const page = useCurrentPage();

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(setTour([]));
	}, [page, dispatch]);

	useEffect(() => {
		if (user) {
			boot({
				userId: String(user.id),
				email: user.email,
				avatar: {
					imageUrl: user.profilePicture || '/images/avatar-gray.png',
					type: 'avatar',
				},
				company: {
					name: user.organization?.name,
					companyId: user.organization?.uuid,
				},
				actionColor: '#E861C9',
				alignment: 'top',
			});
		} else {
			boot();
		}
	}, [user, boot, show]);

	useEffect(() => {
		dispatch(closeDialog());
		dispatch(resetChartData());
		dispatch(processSuccess());
		dispatch(closeDrawer());
		dispatch(closePanel());
		dispatch(stopStreaming());
	}, []);

	useEffect(() => {
		if (
			token &&
			location.pathname !== '/signup' &&
			location.pathname !== '/reset-password'
		) {
			dispatch(loginRequest());
			getSSOUserInfo(token)
				.then((response) => {
					const user = response.data as User;
					dispatch(
						loginSuccess({
							token: token,
							user: user,
						})
					);
					navigate('/');
				})
				.catch((error) => {
					console.log('error', error);
					dispatch(loginFailure(error.message));
				});
		}
	}, [token]);

	return (
		<>
			{children}
			{isLoading && <LoaderProcess />}
			{isTransparentLoading && <LoaderTransparent />}
			{isTransparentAnimatedLoading &&
				transparentAnimatedType !== 'magicWrite' &&
				transparentAnimatedType !== 'suggest' && (
					<TransparentAnimatedLoader type={transparentAnimatedType} />
				)}
			<TourContainer
				enabled={tour.run}
				steps={tour.steps}
				initialStep={0}
				onExit={() => dispatch(stopTour())}
			/>
			<Dialogs />
			<Drawers />
			<Panels />
		</>
	);
};

export default AppWrapper;
