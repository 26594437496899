import { Sheet, SheetContent } from '@/components/ui/sheet';
import { useState } from 'react';

import { useAppDispatch } from '@/lib/hooks/hooks';
import useProposalId from '@/lib/hooks/useProposalId';
import AppTabs from '@/components/ui/shared/Tabs/AppTabs/AppTabs';
import AssistanceTab from '@/components/AssistanceTab/AssistanceTab';
import ComplianceTab from '@/components/ComplianceTab/ComplianceTab';
import VisualsTab from '@/components/VisualsTab/VisualsTab';
import { fetchAllProposalCharts } from '@/redux/slices/chartSlice';
import { fetchComplianceMatrix } from '@/redux/requests/compliance';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';

const WriteModeDrawer = () => {
	const [selectedTab, setSelectedTab] = useState('assistant');
	const dispatch = useAppDispatch();
	const proposalId = useProposalId() as number;
	const isOpen = useSelector(
		(root: RootState) => root.page.drawer?.type === 'assistantMode'
	);

	const isLogicalFrame = location.pathname.includes('/logical-frame');

	const handleVisuals = () => {
		dispatch(fetchAllProposalCharts(proposalId));
	};
	return (
		<Sheet open={isOpen}>
			<SheetContent className="!max-w-[450px] p-0 bg-[#f1f3f5]">
				<AppTabs
					defaultTab="assistant"
					type="drawer"
					handleTabChange={(val: string) => {
						setSelectedTab(val);
					}}
					selectedTab={selectedTab}
					tabs={[
						{
							label: 'Assistance',
							value: 'assistant',
							content: (
								<AssistanceTab mode={isLogicalFrame ? 'logical-frame' : ''} />
							),
						},
						{
							label: 'Compliance',
							value: 'compliance',
							content: <ComplianceTab />,
							onClick: () => dispatch(fetchComplianceMatrix(proposalId)),
						},
						{
							label: 'Visuals',
							value: 'visuals',
							content: <VisualsTab />,
							onClick: handleVisuals,
						},
					]}
				/>
			</SheetContent>
		</Sheet>
	);
};

export default WriteModeDrawer;
